import { ModelCabinetBaseT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getVector3FromCoordinates } from "../../../utils/three/general/getFunctionsTHREE";
import { getBoundingBoxEvalNode, getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { getСompletedModelsNullNames } from "./../getNodesCabinets";
import { getSizeModelBoxFromAssetCabinetBase } from "./size";

export type DistanceFromCabinetBaseBoxT = {
  [key in ModelCabinetBaseT]: number;
}
export const getDistanceFromCabinetBaseBoxToCabinetsBase = (nullNameModel: ModelCabinetBaseT): DistanceFromCabinetBaseBoxT => {
  const boundingBoxCurrentModel = getBoundingBoxEvalNode(nullNameModel);
  const allСompletedNullNameCabinetsBase = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_BASE);

  let objDistanceFronModelBoxToModelsNull: DistanceFromCabinetBaseBoxT = {};

  allСompletedNullNameCabinetsBase.forEach((nullName) => {
    if (nullName !== nullNameModel) {
      const modelSize = getSizeModelBoxFromAssetCabinetBase(nullName);
      const position = getTranslationThreekit({name: nullName})
      const positionVector = getVector3FromCoordinates(position)
      const distance = boundingBoxCurrentModel.distanceToPoint(positionVector);

      objDistanceFronModelBoxToModelsNull[nullName as ModelCabinetBaseT] = distance - modelSize["x"]/2;

    }
  })

  return objDistanceFronModelBoxToModelsNull;
}

export const getDistanceFromCornerEmtyBaseBoxToCabinetsBase = (nullNameModel: ModelCabinetBaseT): ModelCabinetBaseT[] => {
  const arrDistanceFromCabinetBaseBoxToCabinetsBase = getDistanceFromCabinetBaseBoxToCabinetsBase(nullNameModel);
  const distanceKeys = Object.keys(
    arrDistanceFromCabinetBaseBoxToCabinetsBase
  ) as Array<keyof typeof arrDistanceFromCabinetBaseBoxToCabinetsBase>;
  let arrModelsNamesWithShortDistances: ModelCabinetBaseT[] = [];
  distanceKeys.forEach((nullName) => {
    if (arrDistanceFromCabinetBaseBoxToCabinetsBase[nullName] < 0.1)
      arrModelsNamesWithShortDistances.push(nullName);
  })
  return arrModelsNamesWithShortDistances;
}