export const FlipVertical = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.42913 1.00194V13.4287C8.42913 13.5073 8.49342 13.5716 8.57199 13.5716H9.64342C9.72199 13.5716 9.78627 13.5073 9.78627 13.4287V2.62158L13.0541 5.19837C13.147 5.27158 13.2863 5.20551 13.2863 5.08587V3.79122C13.2863 3.70372 13.247 3.62158 13.1773 3.56622L9.35413 0.551938C8.97913 0.257295 8.42913 0.523366 8.42913 1.00194ZM4.21484 0.57158L4.21484 11.3787L0.946987 8.80194C0.854129 8.72872 0.714844 8.7948 0.714844 8.91444L0.714844 10.2091C0.714844 10.2966 0.75413 10.3787 0.823772 10.4341L4.64699 13.4484C5.02199 13.743 5.57199 13.4769 5.57199 13.0002L5.57199 0.57158C5.57199 0.493009 5.5077 0.428723 5.42913 0.428723H4.3577C4.27913 0.428723 4.21484 0.493009 4.21484 0.57158Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
};
