import axios from "axios";
import { THREEKIT_ID_DATA_TABLE_PRICE, THREEKIT_ORG_ID, THREEKIT_PUBLIC_TOKEN, THREEKIT_URL_API } from "../../utils/threekit/threekitConfig/threekitConfig";

const THREEKIT_API_ENDPOINT = THREEKIT_URL_API;
const PUBLIC_TOKEN = THREEKIT_PUBLIC_TOKEN;
const ORG_ID = THREEKIT_ORG_ID;
const ID_DATA_TABLE_PRICE = THREEKIT_ID_DATA_TABLE_PRICE;

export const allDataTabels = () => {
  return axios({
    method: "get",
    url: `${THREEKIT_API_ENDPOINT}/datatables/?bearer_token=${PUBLIC_TOKEN}&all=true`,
  });
};

export const getPriceDataTableFromThreekit = async (): Promise<any> => {
  return axios({
    method: "get",
    url: `${THREEKIT_API_ENDPOINT}/datatables/${ID_DATA_TABLE_PRICE}/rows?bearer_token=${PUBLIC_TOKEN}&all=true`,
  })
    .then((response: any) => response.data)
    .then((data: any) => {
      return data;
    });
};

// export const getSettings = list => {
//   let arrayFubct = list.map(l => {
//     return axios({
//       method: 'get',
//       url: `${THREEKIT_API_ENDPOINT}/datatables/${l}/rows?bearer_token=${PUBLIC_TOKEN}&all=true`
//     });
//   });

//   return axios
//     .all(arrayFubct)
//     .then(res => res.map(settings => settings['data'].rows));
// };
