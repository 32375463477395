import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { Dispatch } from "redux";
import { AppDispatch } from "../../../store/store";

let lastAnnotations: any;
let lastParentEl: any;
let annotationElements: any = {};
let opened: any = {};

export function onAnnotationChange(annotations: any, parentEl: any, dispatch: AppDispatch) {
  // console.log('onAnnotationChange --- ==== ',);

  lastAnnotations = annotations;
  lastParentEl = parentEl;

  if (!parentEl) {
      return;
  }

  for (var i = 0; i < annotations.length; i++) {
      var annotation = annotations[i];

      var element = annotationElements[annotation.id];
      var appendToBody = !element;

      element = setAnnotation(annotation, element, dispatch);

      if (!appendToBody) {
          continue;
      }

      parentEl.appendChild(element);
      annotationElements[annotation.id] = element;
  }

}

export function setAnnotation(
  annotation: any,
  el = document.createElement("span"),
  dispatch: Dispatch
) {
  el.className = "point";

  el.id = annotation.id;

  el.setAttribute(
    "style",
    "left: " +
      annotation.left +
      "px; top: " +
      annotation.top +
      "px;" +
      " display: " +
      (annotation.visible ? "block;" : "none;") +
      " animation-duration: 0.8s !important; border-radius: 50%;"
  );

  el.onclick = () => {
    opened[annotation.id] = !opened[annotation.id];
    // onAnnotationChange(lastAnnotations, lastParentEl, dispatch);
    // dispatch(addActivePoint({ id: annotation.text }));
    console.log('annotation --- ==== ',annotation);

    // dispatch(setActiveTab('create'))
    // dispatch(setActiveCategory('accordionAdditionalSections'))
    // dispatch(addActivePoint({ id: node.name }));

    el.className = "point click";
    // dispatch(changeActiveStep("build"));
  };

  return el;
}

export const addNewNodePoint = (
  namePoint: string,
  translation: ICoordinates
): string => {

  //@ts-ignore
  const id = window.threekit.player.scene.addNode(
    {
      id: `${namePoint}`,
      type: "Annotation",
      name: namePoint,
      plugs: {
        Transform: [
          {
            type: "Transform",
            active: true,
            translation,
          },
        ],
      },
    },
    window.threekit.player.instanceId
  );

  return id;
};
