import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { addModels } from "../../../store/actions/player.action";
import { getFilteringProductsValues, getStatusDependencySettings } from "../../../store/selectors/settingsUISelectors";
import { updateSettings } from "../../../store/slices/settingsUI/settingsUISlice";
import { OptionsT } from "../../../types/UI/dynamic-rendering.interfaces";
import { ProductI } from "../../../utils/constants/cabinets";
import { Option } from "./Option/Option";

// scss
import s from "./OptionsGroup.module.scss";

interface OptionsGroupPropsT extends OptionsT {
  parentKeySection: string;
}
export const OptionsGroup = ({...props}: OptionsGroupPropsT) => {
  const { id, parentKeySection, defaultValue, values, label, dependencySettings } = props;

  const filteringValues = useAppSelector(getFilteringProductsValues(id));
  const dispatch = useAppDispatch();

  const handleClickOption = (idProduct: string) => {
    dispatch(addModels([{ optionId: id, value: [idProduct] }]));
  }

  const isVisibleGroup = useAppSelector(getStatusDependencySettings(dependencySettings));

  if (!isVisibleGroup) return <></>;

  return (
    <div className={s.categoryGroup}>
      {label && <p className={s.categoryGroupLabel}>{label}</p>}
      <div className={s.optionsList}>
        {filteringValues.map((product: ProductI) => (
          <Option key={product['id'] + product["label"]} { ...product } onClick={(idProduct) => handleClickOption(idProduct)} />
        ))}
      </div>
    </div>
  );
};
