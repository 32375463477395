import { InputNumberSize } from "../InputNumberSize/InputNumberSize";
import s from "./TwoLineParams.module.scss";

export const TwoLineParams = ({...props}) => {
  const { children } = props;
  return (
    <div className={s.twoLineParams}>
      {children}
    </div>
  );
};
