export const ArrowCloseSubMenuSvg = () => {
  return (
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79053 1.00248L0.353027 7.41654C0.140137 7.60013 0.140137 7.95365 0.353027 8.13919L7.79053 14.5533C8.06787 14.7915 8.47412 14.5767 8.47412 14.1919V1.36381C8.47412 0.97904 8.06787 0.764196 7.79053 1.00248Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
};
