export const ArrowBottomSelectColorSvg = () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L8 8L15 1"
        stroke="black"
        strokeOpacity="0.45"
        strokeWidth="2"
      />
    </svg>
  );
};
