import { LoadBtn } from "../../../../../assets/images/svg/LoaderBtn";
import s from "./Design.module.scss";

export const Design = ({ ...props }) => {
  const { snapshotsObj } = props;
  return (
    <div className={s.contentProducts_design}>
      <div className={s.blockTitle}>Your Design</div>
      <div className={s.imgs}>
        <div className={`${s.snapshot} ${s.snapshotFront}`}>
          {snapshotsObj !== null ? (
            <img
              className={s.img}
              src={snapshotsObj["snapshotSide"]}
              alt="snapshotSide"
            />
          ) : (
            <span className={s.loading}>
              <LoadBtn />
            </span>
          )}
        </div>
        <div className={`${s.snapshot} ${s.snapshotTop}`}>
          {snapshotsObj !== null ? (
            <img
              className={s.img}
              src={snapshotsObj["snapshotTop"]}
              alt="snapshotTop"
            />
          ) : (
            <span className={s.loading}>
              <LoadBtn />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
