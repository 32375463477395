import { getModalInfo } from "../../../store/selectors/modalsSelectors";
import { ModalsWrap } from "../ModalsWrap/ModalsWrap";
import s from "./ModalHelp3D.module.scss";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { Accordion } from "../../Accordion/Accordion";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import { helpData3D } from "../../../utils/constants/helpData3D";

export const ModalHelp3D = ({ ...props }) => {
  const modalInfo = useAppSelector((state) => getModalInfo(state)(MODAL_KEYS.HELP_3D));
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.HELP_3D, value: false}));
  }

  if (!modalInfo["isVisible"]) return null;

  return (
    <ModalsWrap modalKey={MODAL_KEYS.HELP_3D}>
      <div className={s.modalHelp3D}>
        <div className={s.content}>
          <h3 className={s.title}>Design your perfect room</h3>
          <Accordion accordionData={helpData3D} />
          <p className={s.footerDescr}>When you are ready click <u>Shopping List</u> to get a summary of all the items and where you can buy them.</p>
          <div className={s.btnsWrap}>
            <ButtonMain text="Start Designing" onClick={handleCloseModal} color="blueDark" size="bigSuper" bold={true} />
          </div>
        </div>
      </div>
    </ModalsWrap>
  )
}