import { NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getNodeIdFromName } from "../../../utils/threekit/general/getFunctions";
import { ASSET_ID_OPACITY_MATERIAL, WIDTH_BOUNDING_PLANES } from "../buildWallFromData";

export const addPlanesBoundingForWall = (objParams: any) => {

  const {
    indexWall,
    indexPlane,
    wallHeight,
    wallDepth,
    coordsPosition,
    wallAngle
  } = objParams;

  const planeBoundingCeilingName = `${NODES_THREEKIT.PLANE_BOUNDING_WALL}${indexWall}_${indexPlane}`;
  const wallsLayoutContainerId = getNodeIdFromName(
    NODES_THREEKIT.LAYOUT_CONTAINER_WALLS_WRAP
  );

  const paramsPlaneBoundingForWall = {
    name: planeBoundingCeilingName,
    type: "PolyMesh",
    plugs: {
      PolyMesh: [
        {
          type: "Plane",
          height: wallHeight,
          width: wallDepth + WIDTH_BOUNDING_PLANES,
        },
      ],
      Transform: [
        {
          type: "Transform",
          translation: {
            ...coordsPosition,
            y: wallHeight / 2,
          },
          rotation: { x: 90, y: wallAngle - 90, z: 0 },
        },
      ],
      Material: [
        {
          type: "Reference",
          asset: { assetId: ASSET_ID_OPACITY_MATERIAL },
        },
      ],
      Physics: [
        {
          type: "Collider",
          response: 2,
          shape: 0,
        },
      ],
    },
  };

  //@ts-ignore
  return window.threekit.player.scene.addNode(
    paramsPlaneBoundingForWall,
    wallsLayoutContainerId
  );

}