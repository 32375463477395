import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { getVector3FromCoordinates } from "./general/getFunctionsTHREE";

export const getPointSpacing = (
  pointFirst: ICoordinates,
  pointLast: ICoordinates
) => {
  return getVector3FromCoordinates({ ...pointFirst }).distanceTo(
    getVector3FromCoordinates({ ...pointLast })
  );
};
