import React, { useEffect } from "react";
// import { updateConfigurationSink } from "../../../functionsConfigurator/cabinets/configuration/appliancesSink";
import { getCabinetSinkIdFromSinkSize, isProductGroupAppliances, SinkSizeT } from "../../../functionsUI/appliances";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { addModels } from "../../../store/actions/player.action";
// import { isSinkCabinetFilled } from "../../../store/selectors/appliancesSelectors";
import { getFilteringValuesForGroup } from "../../../store/selectors/settingsUISelectors";
import { updateSettings } from "../../../store/slices/settingsUI/settingsUISlice";
import { CategoryOption, PRODUCT_GROUP_APPLIANCES } from "../../../utils/constants/cabinets";

// scss
import s from "./CategoryGroup.module.scss";

import { OptionCategory } from "./OptionCategory/OptionCategory";

export const CategoryGroup = ({...props}) => {
  const { id, label, values, parentKeySection } = props;
  const dispatch = useAppDispatch();
  const { filteringValues, isVisibleGroup } = useAppSelector(getFilteringValuesForGroup(values, id));
  // const isSinkCabinet = useAppSelector(isSinkCabinetFilled);

  const handleClickCategoryOption = (value: string, categoryId: string) => {

    if (isProductGroupAppliances(id)) {
      // if (value === PRODUCT_GROUP_APPLIANCES.SINK) {
      //   if (!!isSinkCabinet) {
      //     updateConfigurationSink(isSinkCabinet, categoryId as SinkSizeT).then(() => {
      //       dispatch(updateSettings([{optionId: parentKeySection, value: [categoryId]}]));
      //     });
      //   } else {
      //     dispatch(addModels([{ optionId: "Cabinets Base", value: [getCabinetSinkIdFromSinkSize(categoryId as SinkSizeT)], parentSectionId: "Cabinets" }]));
      //     dispatch(updateSettings([{optionId: parentKeySection, value: [categoryId],}]));
      //   }
      // } else {
        dispatch(addModels([{ optionId: parentKeySection, value: [categoryId] }]));
      // }
      return;
    }

    dispatch(updateSettings([{optionId: parentKeySection, value: value}]))
  }

  if (filteringValues.length < 1 || !isVisibleGroup) return <></>

  return (
    <div className={s.categoryGroup}>
      {label && <p className={s.categoryGroupLabel}>{label}</p>}
      <div className={s.optionsList}>
        {filteringValues.map((category: CategoryOption) => (
          <OptionCategory key={category['id']} { ...category } parentId={id} onClick={(value: string) => handleClickCategoryOption(value, String(category['id']))} />
        ))}
      </div>
    </div>
  );
};

export const MemoizedCategoryGroup = React.memo(CategoryGroup);
