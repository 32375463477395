import { ICoordinates, IDisplayAttributeArrayValue } from "@threekit-tools/treble/dist/types";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { ProductArrT } from "../../../utils/constants/cabinets";
import {
  WallItemT,
} from "../../../utils/constants/nodesNamesThreekit";
import { getAllWallsNode } from "../../wallsAndFloor/buildWallFromData";
import { checkWallVisibility, getNextWall } from "../../wallsAndFloor/getGeneralWallInfo";
import { getWallInFrontCamera } from "../../wallsAndFloor/getWallInFrontCamera";
import { getValuesAttributeFromProducts } from "../getObjActiveAndNewValuesAttributeThreekit";
import { checkEmptyIntervalInWallForCabinetsWall } from "../../intervals/checkEmptyIntervalInWall";

const getTargetWallNameForAddedCabinetsWall = (
  wallNameInFrontCamera: WallItemT,
  wallNumberInFrontCamera: number = 0,
  allWallsLength: number,
  valuesAttributeForAdded: IDisplayAttributeArrayValue[]
): WallItemT | undefined => {
  const currentWallName = wallNameInFrontCamera;

  if (wallNumberInFrontCamera >= allWallsLength) {
    return undefined;
  }

  const isVisibleWall = checkWallVisibility(currentWallName);
  const isIntervalInWall = checkEmptyIntervalInWallForCabinetsWall(
    currentWallName,
    valuesAttributeForAdded
  )

  if (isVisibleWall && isIntervalInWall) {
    return currentWallName;
  } else {
    // Функція для пошуку вільної видимої стіни
    const nextWallName = getNextWall(currentWallName);
    return getTargetWallNameForAddedCabinetsWall(
      nextWallName,
      wallNumberInFrontCamera + 1,
      allWallsLength,
      valuesAttributeForAdded
    );
  }
};

/**
 * Шукає стіну, яка є видимою 
 * та на якій є місце для розташування настінних шкафів (тобто стіна не заповнена повністю настінними шкафами)
 *
 * @param {ProductArrT} arrProductsInfo Масив продуктів зі стейту, які мають додатися на стіну
 * @return {WallItemT | undefined} Name стіни, яка пройшла перевірку,
 * або undefined - якщо в кімнаті жодна стіна не пройшла перевірку на видимість та заповненість іншими шкафами
 */
export const getWallNameForAddedCabinetsWall = (
  arrProductsInfo: ProductArrT
): WallItemT | undefined => {
  const wallNameInFrontCamera = getWallInFrontCamera();
  const allWallsLength = Object.keys(getAllWallsNode()).length;

  const valuesAttributeForAdded = getValuesAttributeFromProducts(
    arrProductsInfo,
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL
  );

  const targetWallName = getTargetWallNameForAddedCabinetsWall(
    wallNameInFrontCamera,
    0,
    allWallsLength,
    valuesAttributeForAdded
  );

  return targetWallName;
};
