import axios from "axios";
import { API_URL } from "../utils/threekit/threekitConfig/threekitConfig";

export const getIdGeneratedProject = async (): Promise<any> => {
  return axios({
    method: "get",
    url: `${API_URL}/getLastPdfId`,
  })
    .then((response: any) => response.data)
    .then((data: any) => {
      return data;
    });
};