export const EditModeSvg = () => {
  return (
    <svg
      width="51"
      height="13"
      viewBox="0 0 51 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.153809" width="50.7518" height="12" rx="2" fill="#C3D5DD" />
      <path
        d="M6.97391 9.04946H3V3.24946H6.97391V4.14511H4.01739V5.64946H6.91304V6.54511H4.01739V8.15381H6.97391V9.04946Z"
        fill="#31344B"
      />
      <path
        d="M10.1752 9.04946H8.01868V3.24946H10.1752C11.0738 3.24946 11.8042 3.51903 12.3665 4.05816C12.9288 4.59729 13.21 5.29584 13.21 6.15381C13.21 7.00598 12.9288 7.70163 12.3665 8.24077C11.81 8.7799 11.0796 9.04946 10.1752 9.04946ZM10.1752 8.15381C10.7839 8.15381 11.268 7.9625 11.6274 7.5799C11.9868 7.19729 12.1665 6.72192 12.1665 6.15381C12.1665 5.5683 11.9897 5.08714 11.6361 4.71033C11.2882 4.33352 10.8013 4.14511 10.1752 4.14511H9.03607V8.15381H10.1752Z"
        fill="#31344B"
      />
      <path
        d="M15.2096 9.04946H14.1923V3.24946H15.2096V9.04946Z"
        fill="#31344B"
      />
      <path
        d="M18.8426 9.04946H17.8252V4.14511H16.0687V3.24946H20.5991V4.14511H18.8426V9.04946Z"
        fill="#31344B"
      />
      <path
        d="M29.7207 9.04946H28.7033V4.74511L26.9207 9.04946H26.4859L24.712 4.74511V9.04946H23.6946V3.24946H25.1294L26.7033 7.06685L28.2859 3.24946H29.7207V9.04946Z"
        fill="#31344B"
      />
      <path
        d="M35.7899 8.31033C35.2334 8.87265 34.5233 9.15381 33.6595 9.15381C32.7957 9.15381 32.0856 8.87265 31.5291 8.31033C30.9726 7.74221 30.6943 7.02337 30.6943 6.15381C30.6943 5.28424 30.9726 4.5683 31.5291 4.00598C32.0856 3.43787 32.7957 3.15381 33.6595 3.15381C34.5233 3.15381 35.2334 3.43787 35.7899 4.00598C36.3465 4.5683 36.6247 5.28424 36.6247 6.15381C36.6247 7.02337 36.3465 7.74221 35.7899 8.31033ZM33.6595 8.24946C34.2334 8.24946 34.6972 8.05236 35.0508 7.65816C35.4044 7.25816 35.5812 6.75671 35.5812 6.15381C35.5812 5.54511 35.4044 5.04366 35.0508 4.64946C34.6972 4.25526 34.2334 4.05816 33.6595 4.05816C33.0798 4.05816 32.6131 4.25526 32.2595 4.64946C31.9117 5.04366 31.7378 5.54511 31.7378 6.15381C31.7378 6.7625 31.9117 7.26395 32.2595 7.65816C32.6131 8.05236 33.0798 8.24946 33.6595 8.24946Z"
        fill="#31344B"
      />
      <path
        d="M39.7608 9.04946H37.6043V3.24946H39.7608C40.6593 3.24946 41.3898 3.51903 41.9521 4.05816C42.5144 4.59729 42.7956 5.29584 42.7956 6.15381C42.7956 7.00598 42.5144 7.70163 41.9521 8.24077C41.3956 8.7799 40.6651 9.04946 39.7608 9.04946ZM39.7608 8.15381C40.3695 8.15381 40.8536 7.9625 41.213 7.5799C41.5724 7.19729 41.7521 6.72192 41.7521 6.15381C41.7521 5.5683 41.5753 5.08714 41.2217 4.71033C40.8738 4.33352 40.3869 4.14511 39.7608 4.14511H38.6217V8.15381H39.7608Z"
        fill="#31344B"
      />
      <path
        d="M47.7518 9.04946H43.7779V3.24946H47.7518V4.14511H44.7952V5.64946H47.6909V6.54511H44.7952V8.15381H47.7518V9.04946Z"
        fill="#31344B"
      />
    </svg>
  );
};
