import { IntervalsWallsT } from "./wallsGeneral";

const arePointsEqual = (point1: THREE.Vector2, point2: THREE.Vector2) =>
  point1.x === point2.x && point1.y === point2.y;

export const isClosedContour = (segments: IntervalsWallsT): boolean => {

  const visited = new Set();
  let hasClosedContour = false;

  function dfs(currentSegment: any, prevSegment: any) {

    const currentKey = `${currentSegment.start.x}-${currentSegment.start.y}-${currentSegment.end.x}-${currentSegment.end.y}`;
    visited.add(currentKey);

    for (const nextSegment of segments) {
      const nextSegmentsKey = `${nextSegment.start.x}-${nextSegment.start.y}-${nextSegment.end.x}-${nextSegment.end.y}`;
      if (nextSegment === prevSegment || currentKey === nextSegmentsKey) {
        continue; // Пропускаємо попередній сегмент, щоб уникнути повернення
      }

      if (
        arePointsEqual(currentSegment.end, nextSegment.start) ||
        arePointsEqual(currentSegment.end, nextSegment.end) ||
        arePointsEqual(currentSegment.start, nextSegment.start) ||
        arePointsEqual(currentSegment.start, nextSegment.end)
      ) {
        if (visited.has(nextSegmentsKey)) {
          hasClosedContour = true;
        } else {
          dfs(nextSegment, currentSegment);
        }
      }
    }
  }

  for (const segment of segments) {
    if (!visited.has(`${segment.start.x}-${segment.start.y}-${segment.end.x}-${segment.end.y}`)) {
      dfs(segment, null);
      if (hasClosedContour) {
        return true;
      }
    }
  }

  return false;
};
