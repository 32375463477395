import { SECTIONS_KEYS } from "../../types/UI/dynamic-rendering.interfaces";
import { ModelsName_NodesT } from "../../utils/constants/nodesNamesThreekit";

export const getSectionIdFromNullName = (
  nullName: ModelsName_NodesT
): SECTIONS_KEYS => {
  if (nullName.includes("Base") || nullName.includes("Wall")) return SECTIONS_KEYS.CABINETS;
  if (nullName.includes("Island")) return SECTIONS_KEYS.ISLAND;
  if (nullName.includes("Appliances")) return SECTIONS_KEYS.APPLIANCES;
  return SECTIONS_KEYS.CABINETS;
};