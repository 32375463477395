import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { CabinetsAndFeatures_NodesT } from "../../utils/constants/nodesNamesThreekit";
import { isNullNameModelApliancesT } from "./appliances/checkTypeApliances";
import { isNullNameModelCabinetIslandT } from "./cabinetsIsland/checkTypeCabinetsIsland";
import { isNullNameModelCabinetWallT } from "./cabinetsWall/checkCabinetsWall";

export const getAttributeNameFromNullName = (
  nullNameModel: CabinetsAndFeatures_NodesT
): ATTRIBUTES_NAMES_THREEKIT => {

  if (isNullNameModelCabinetWallT(nullNameModel)) {
    return ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL
  };

  if (isNullNameModelCabinetIslandT(nullNameModel)) {
    return ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND
  }

  if (isNullNameModelApliancesT(nullNameModel)) {
    return ATTRIBUTES_NAMES_THREEKIT.APPLIANCES
  };

  return ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE

}