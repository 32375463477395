import React, { useEffect, useState } from "react";
import s from "./AccordionItem.module.scss";
import { Input, Select } from "antd";
import { ArrowAccordionSvg } from "../../../assets/images/svg/ArrowAccordion";

export const AccordionItem = ({ ...props }) => {
  const {
    id,
    label,
    content,
    handleShowHideAccordion,
    isActive,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleChangeStateAccordion = () => {
    setIsOpen(!isOpen);
  }

  return (
    <div className={`${s.acordionItem} ${isOpen ? s.active : ""}`}>
      <div
        className={`${s.acordionItem_head}`}
        onClick={/*() => handleShowHideAccordion(id)*/handleChangeStateAccordion}
      >
        <div className={s.acordionItem_title}>
          <span>{label}</span>
        </div>
        <div className={s.acordionItem_arrow}>
          <ArrowAccordionSvg />
        </div>
      </div>
      {isOpen && <div className={s.acordionItem_content}>
        <div className={s.acordionItem_contentInternal}>
          {content}
        </div>
      </div>}
      
    </div>
  );
};
