import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getActiveValuesForSelectStandart, getValueOption } from "../../../store/selectors/settingsUISelectors";
import { updateSettings } from "../../../store/slices/settingsUI/settingsUISlice";
import { SelectBase } from "../../BaseComponent/SelectBase/SelectBase";
//@ts-ignore
import s from "./SelectStandart.module.scss";

type valueOptionSelectStandart = {
  selectValue: string;
  isError: boolean;
};

export const SelectStandart = ({ ...props }) => {
  const { parentKeySection, id, label, values, defaultValue } = props;
  const dispatch = useAppDispatch();
  const selectValue = useAppSelector(getValueOption({idOption: id,}));
  const activeValuesArr = useAppSelector(getActiveValuesForSelectStandart(id, values))

  const onChange = () => (value: string) => {
    dispatch(updateSettings([{optionId: id, value: value}]))
    if (id.includes("FilterGroup_") && id.includes("_Width")) {
      dispatch(updateSettings([{optionId: "WidthProduts", value: value}]))
    }
  };

  useEffect(() => {
    if (id === 'WidthProduts') {
      dispatch(updateSettings([{optionId: "WidthProduts", value: "All"}]))
    }
  }, [])

  let classInput = `${s.selectStandart}`;

  return (
    <div className={s.boxSelect}>
      {label && (
        <div className={s.wrapLabel}>
          <div className={s.label}>{label}</div>
        </div>
      )}

      <SelectBase
        className={classInput}
        values={activeValuesArr}
        onChange={onChange()}
        value={selectValue}
        errorComponent={false}
        isDisabled={activeValuesArr.length <= 2}
      />
    </div>
  );
};
