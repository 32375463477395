import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CabinetsBuilder3D } from "../../pages/CabinetsBuilder3D/CabinetsBuilder3D";
import { RoomBuilder2D } from "../../pages/RoomBuilder2D/RoomBuilder2D";
import { RoomsList } from "../../pages/RoomsList/RoomList";
import { TestPage } from "../../pages/TestPage/TestPage";

export const ROUTES = {
  home: '/',
  buildRoom: "/build_room",
  buildCabinets: "/build_cabinets",
  test: "/test",
}

const router = createBrowserRouter([
  {
    path: ROUTES.home,
    element: <RoomsList />,
  },
  {
    path: ROUTES.buildRoom,
    element: <RoomBuilder2D />,
  },
  {
    path: ROUTES.buildCabinets,
    element: <CabinetsBuilder3D />,
  },
  {
    path: ROUTES.test,
    element: <TestPage />,
  },
]);

export const MyRouterProvider = () => {
  return (
    <RouterProvider router={router} />
  );
};
