export const IdeaSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3036_5477)">
        <path
          d="M15.4842 20.1264V21.0312C15.4842 21.7502 14.951 22.3469 14.2663 22.4398L14.0462 23.2517C13.919 23.6919 13.5131 24 13.0778 24H10.8866C10.4171 24 10.0112 23.687 9.91823 23.2517L9.69813 22.4398C9.00851 22.3469 8.48028 21.7502 8.48028 21.0312V20.1264C8.48028 19.6568 8.85689 19.2509 9.35576 19.2509H14.6429C15.1124 19.2802 15.4842 19.6568 15.4842 20.1264ZM16.1787 16.4141C16.0222 17.4021 15.1565 18.1406 14.1587 18.1406H9.80573C8.78352 18.1406 7.93249 17.4119 7.78576 16.4141C7.60969 15.2745 7.07168 14.1985 6.27934 13.3768C4.87563 11.9046 4.09308 9.99715 4.05884 7.99674C4.02461 3.61443 7.53632 0.0342368 11.8893 0H11.948C16.3352 0 19.9056 3.54106 19.9056 7.89403C19.9056 9.93356 19.0937 11.934 17.6851 13.3768C16.8781 14.2132 16.3547 15.2647 16.1787 16.4141ZM16.4379 12.1687C17.5384 11.0438 18.1693 9.48849 18.1693 7.89403C18.1693 4.49969 15.3814 1.7363 11.9529 1.7363H11.904C8.50963 1.76564 5.77068 4.56328 5.79514 7.97718C5.81959 9.53251 6.43585 11.0291 7.53143 12.1736C8.56832 13.2448 9.27262 14.6582 9.50249 16.15C9.52695 16.3261 9.67368 16.4043 9.80573 16.4043H14.1587C14.3201 16.4043 14.4424 16.2723 14.4619 16.15C14.6918 14.6436 15.3766 13.2643 16.4379 12.1687ZM11.9822 11.8655C11.4687 11.8655 11.0578 12.2812 11.0578 12.7899C11.0578 13.3034 11.4736 13.7143 11.9822 13.7143C12.4958 13.7143 12.9066 13.2986 12.9066 12.7899C12.9066 12.2812 12.4958 11.8655 11.9822 11.8655ZM11.992 11.2052C12.3979 11.2052 12.7256 10.8824 12.7403 10.4814L12.887 5.34094C12.9017 4.83717 12.4958 4.42633 11.9969 4.42633C11.4931 4.42633 11.0921 4.84206 11.1067 5.34094L11.2486 10.4814C11.2535 10.8824 11.586 11.2052 11.992 11.2052Z"
          fill="#31344B"
        />
      </g>
      <defs>
        <clipPath id="clip0_3036_5477">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
