import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getModalInfo } from "../../../store/selectors/modalsSelectors";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { ModalsWrap } from "../ModalsWrap/ModalsWrap";
import s from "./ModalSendEmailError.module.scss";

export const ModalSendEmailError = ({ ...props }) => {
  const modalInfo = useAppSelector((state) => getModalInfo(state)(MODAL_KEYS.EMAIL_ERROR));
  const dispatch = useAppDispatch();

  const handleContinue = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.EMAIL_ERROR, value: false}))
  }

  if (!modalInfo["isVisible"]) return null;

  return (
    <ModalsWrap modalKey={MODAL_KEYS.EMAIL_ERROR} >
      <div className={s.modalSendEmailError}>
        <p className={s.title}>Oops, we're not there yet.</p>
        <p className={s.noteText}>An error occurred while sending an email.</p>
        <div className={s.btnsWrap}>
          <ButtonMain text="Continue" onClick={handleContinue} color="blueDark" size="bigSuper" fullWidth={true} bold={true} />
        </div>
      </div>
    </ModalsWrap>
  )
}