export const ClockwiseRotateSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5598 11.5199C16.5593 10.2231 16.1388 8.96139 15.3611 7.92368C14.5834 6.88597 13.4905 6.12815 12.2459 5.76372C11.0014 5.39929 9.67233 5.44787 8.45774 5.90218C7.24315 6.35649 6.20843 7.19207 5.50858 8.28379C4.80872 9.3755 4.4814 10.6646 4.57563 11.9579C4.66986 13.2513 5.18057 14.4793 6.03128 15.458C6.88199 16.4367 8.02689 17.1135 9.2945 17.387C10.5621 17.6605 11.8842 17.5159 13.0628 16.9749C13.2062 16.9153 13.3672 16.9137 13.5119 16.9703C13.6565 17.027 13.7735 17.1376 13.8383 17.2788C13.9031 17.42 13.9106 17.5809 13.8592 17.7275C13.8078 17.8741 13.7015 17.995 13.5628 18.0649C12.1484 18.7139 10.5619 18.8873 9.04073 18.559C7.5196 18.2307 6.14578 17.4184 5.12505 16.2437C4.10432 15.0691 3.49162 13.5954 3.37873 12.0434C3.26585 10.4913 3.65884 8.94443 4.49886 7.63447C5.33888 6.32451 6.5807 5.32196 8.03831 4.77699C9.49592 4.23202 11.0909 4.17397 12.5842 4.61152C14.0776 5.04908 15.389 5.9587 16.3221 7.20412C17.2551 8.44954 17.7595 9.96374 17.7598 11.5199L16.5598 11.5199Z"
        fill="#332E20"
      />
      <path
        d="M14.8018 11.52L19.5208 11.52C19.5778 11.5201 19.6336 11.5364 19.6816 11.5671C19.7296 11.5977 19.768 11.6414 19.7921 11.693C19.8162 11.7446 19.8251 11.802 19.8178 11.8585C19.8105 11.915 19.7873 11.9682 19.7508 12.012L17.3928 14.844C17.3647 14.8778 17.3294 14.905 17.2896 14.9237C17.2498 14.9423 17.2063 14.952 17.1623 14.952C17.1183 14.952 17.0749 14.9423 17.0351 14.9237C16.9952 14.905 16.96 14.8778 16.9318 14.844L14.5718 12.012C14.5353 11.9682 14.5121 11.9148 14.5048 11.8582C14.4975 11.8017 14.5065 11.7442 14.5308 11.6925C14.555 11.6409 14.5935 11.5972 14.6417 11.5667C14.6899 11.5361 14.7458 11.5199 14.8028 11.52L14.8018 11.52Z"
        fill="#332E20"
      />
    </svg>
  );
};
