import { ArrowCloseSubMenuSvg } from "../../assets/images/svg/CloseSubMenu";
import { useAppDispatch, useAppSelector } from "../../hooks/useStoreHooks";
import { getKeySectionActive } from "../../store/selectors/settingsUISelectors";
import { setActiveSection } from "../../store/slices/settingsUI/settingsUISlice";

// scss
import s from "./SettingsBlock.module.scss";

export const SettingsBlock = ({ ...props }) => {
  const { children, id, label, descriptionText } = props;

  const dispatch = useAppDispatch()
  const activeSectionId = useAppSelector(getKeySectionActive)

  const handleCloseMenu = () => {
    dispatch(setActiveSection(null))
  }

  const isActiveScroll = activeSectionId !== null ? ["CabinetBuild", "CabinetColor"].includes(activeSectionId) : false;

  return (
    <div className={s.settingsWrap}>
      <div className={s.btnCloseSettings} onClick={handleCloseMenu}>
        <ArrowCloseSubMenuSvg />
      </div>
      <div className={`${s.scrollBlock} ${isActiveScroll && s.active}`}>
        {(label || descriptionText) && (
          <div className={s.titleBlock}>
            {label && <h3 className={s.settingsTitle}>{label}</h3>}
            {descriptionText && (
              <p className={s.settingsDescription}>{descriptionText}</p>
            )}
          </div>
        )}
        { children }
      </div>
    </div>
  );
};
