import { CONNECTORS_CABINET_ISLAND } from "../../../utils/constants/connectors";
import { NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getInstanceIdAssetFromNullModel } from "../../../utils/threekit/general/getFunctions";
import { setActiveTagsThreekit } from "../../../utils/threekit/general/setFunctions";
import { getConnectorConnected } from "../connectors";
import { getСompletedModelsNullNames } from "../getNodesCabinets";

export const checkCabinetsIslandConnectors = () => {
  
  const allNullForСabinetsIsland = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_ISLAND
  );

  allNullForСabinetsIsland.forEach((nameModel) => {

    const instanceIdAssetSelectedModel = getInstanceIdAssetFromNullModel({name: nameModel});

    const connectingLeft = getConnectorConnected(nameModel, CONNECTORS_CABINET_ISLAND.LEFT);
    const connectingLeftFront = getConnectorConnected(nameModel, CONNECTORS_CABINET_ISLAND.LEFT_FRONT);

    const connectingRight = getConnectorConnected(nameModel, CONNECTORS_CABINET_ISLAND.RIGHT);
    const connectingRightFront = getConnectorConnected(nameModel, CONNECTORS_CABINET_ISLAND.RIGHT_FRONT);

    if (connectingLeft !== null) {
      setActiveTagsThreekit({
        from: instanceIdAssetSelectedModel,
        name: CONNECTORS_CABINET_ISLAND.LEFT_FRONT,
        value: false,
      })
    } else {
      setActiveTagsThreekit({
        from: instanceIdAssetSelectedModel,
        name: CONNECTORS_CABINET_ISLAND.LEFT_FRONT,
        value: true,
      })
    }

    if (connectingRight !== null) {
      setActiveTagsThreekit({
        from: instanceIdAssetSelectedModel,
        name: CONNECTORS_CABINET_ISLAND.RIGHT_FRONT,
        value: false,
      })
    } else {
      setActiveTagsThreekit({
        from: instanceIdAssetSelectedModel,
        name: CONNECTORS_CABINET_ISLAND.RIGHT_FRONT,
        value: true,
      })
    }

    if (connectingLeftFront !== null) {
      setActiveTagsThreekit({
        from: instanceIdAssetSelectedModel,
        name: CONNECTORS_CABINET_ISLAND.LEFT,
        value: false,
      })
    } else {
      setActiveTagsThreekit({
        from: instanceIdAssetSelectedModel,
        name: CONNECTORS_CABINET_ISLAND.LEFT,
        value: true,
      })
    }

    if (connectingRightFront !== null) {
      setActiveTagsThreekit({
        from: instanceIdAssetSelectedModel,
        name: CONNECTORS_CABINET_ISLAND.RIGHT,
        value: false,
      })
    } else {
      setActiveTagsThreekit({
        from: instanceIdAssetSelectedModel,
        name: CONNECTORS_CABINET_ISLAND.RIGHT,
        value: true,
      })
    }

  })
}