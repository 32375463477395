import { ICoordinates } from "@threekit-tools/treble/dist/types";
import * as THREE from "three";

export const getTHREE = (): any =>
  //@ts-ignore
  window.threekit.player.THREE;

export const getVector3FromCoordinates = (coords: ICoordinates): THREE.Vector3 => {
  return new THREE.Vector3(coords["x"], coords["y"], coords["z"]);
}

export const getInvertedVector = (vector: THREE.Vector3): THREE.Vector3 => {
  return vector.clone().multiplyScalar(-1);
}