import { getEvalNode, getInstanceIdAssetFromNullModel, getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { setTranslationThreekit } from "../../../utils/threekit/general/setFunctions";

export const updatePositionConnectorBottom = (idCabinetWall: string) => {

  const instanceId = getInstanceIdAssetFromNullModel({
    id: idCabinetWall,
  });

  if (instanceId === undefined) return;

  const positionCabinetNull = getTranslationThreekit({
    id: idCabinetWall
  });
  const positionConnector = getTranslationThreekit({
    from: instanceId,
    name: "connector_top_back_*"
  });

  // set position for connector connection to Cabinets Base
  setTranslationThreekit({
    from: instanceId,
    name: "connector_top_back_*",
    value: {
      ...positionConnector,
      y: -positionCabinetNull["y"],
    }
  });

}