import Select from "./../../assets/images/svg/Menu/2D/Select.svg";
import Walls from "./../../assets/images/svg/Menu/2D/Walls.svg";
import Openings from "./../../assets/images/svg/Menu/2D/Openings.svg";
import LayoutTemplates from "./../../assets/images/svg/Menu/2D/Layout Templates.svg";
import Doors from "./../../assets/images/svg/Menu/2D/Doors.svg";
import Windows from "./../../assets/images/svg/Menu/2D/Windows.svg";
import Draw from "./../../assets/images/svg/Menu/2D/Draw.svg";
import AddingItemsToYourRoom from "./../../assets/images/Modals/Help/Adding items to your room.png";
import { IComponent } from "../../types/UI/dynamic-rendering.interfaces";
import { IUnits } from "@threekit-tools/treble/dist/hooks/useSpaces/types";

export interface MainMenuItem2DI {
  key: string;
  text: string;
  imgUrl: string;
  setting: IComponent[];
}

export type MainMenu2DT = MainMenuItem2DI[];

export const settingsDraw: Array<IComponent> = [
  {
    type: "SubMenu2DGrag",
    data: {
      id: "SubMenu2DHelp__Draw",
      typeOptions: "groupingOptions",
      values: [
        {
          position: 0,
          title: "Select a drawing type",
          description: "Click and drag in the player to add wall",
          image: AddingItemsToYourRoom,
        },
        {
          position: 1,
          title: "Modifying a wall",
          description:
            "Click on a wall to bring uop the config panel to adjust dimension or add doors/windows",
          image: AddingItemsToYourRoom,
        },
      ],
    },
  },
];

export const settingsSelect: Array<IComponent> = [
  {
    type: "SubMenu2DHelp",
    data: {
      id: "SubMenu2DHelp__Select",
      typeOptions: "groupingOptions",
      values: [
        {
          position: 0,
          title: "Adding Select",
          description: "Click and drag in the player to add wall",
          image: AddingItemsToYourRoom,
        },
        {
          position: 1,
          title: "Modifying a Select",
          description:
            "Click on a wall to bring uop the config panel to adjust dimension or add doors/windows",
          image: AddingItemsToYourRoom,
        },
      ],
    },
  },
];

export const settingsOpenings: Array<IComponent> = [
  {
    type: "SubMenu2DHelp",
    data: {
      id: "SubMenu2DHelp__Openings",
      typeOptions: "groupingOptions",
      values: [
        {
          position: 0,
          title: "Adding Openings",
          description: "Click and drag in the player to add wall",
          image: AddingItemsToYourRoom,
        },
        {
          position: 1,
          title: "Modifying a Openings",
          description:
            "Click on a wall to bring uop the config panel to adjust dimension or add doors/windows",
          image: AddingItemsToYourRoom,
        },
      ],
    },
  },
];

export const settingsTemplate: Array<IComponent> = [
  {
    type: "SubMenu2DTemplate",
    data: {
      id: "SubMenu2DHelp__Template",
      typeOptions: "groupingOptions",
      values: [
        {
          position: 0,
          title: "Set Template",
          description: "Click and drag in the player to add wall",
          image: AddingItemsToYourRoom,
        },
        {
          position: 1,
          title: "Modifying a Openings",
          description:
            "Click on a wall to bring uop the config panel to adjust dimension or add doors/windows",
          image: AddingItemsToYourRoom,
        },
      ],
    },
  },
];

export const settingsDoors: Array<IComponent> = [
  {
    type: "SubMenu2DHelp",
    data: {
      id: "SubMenu2DHelp__Doors",
      typeOptions: "groupingOptions",
      values: [
        {
          position: 0,
          title: "Adding Doors",
          description: "Click and drag in the player to add wall",
          image: AddingItemsToYourRoom,
        },
        {
          position: 1,
          title: "Modifying a Doors",
          description:
            "Click on a wall to bring uop the config panel to adjust dimension or add doors/windows",
          image: AddingItemsToYourRoom,
        },
      ],
    },
  },
];

export const settingsWindows: Array<IComponent> = [
  {
    type: "SubMenu2DHelp",
    data: {
      id: "SubMenu2DHelp__Windows",
      typeOptions: "groupingOptions",
      values: [
        {
          position: 0,
          title: "Adding Windows",
          description: "Click and drag in the player to add wall",
          image: AddingItemsToYourRoom,
        },
        {
          position: 1,
          title: "Modifying a Windows",
          description:
            "Click on a wall to bring uop the config panel to adjust dimension or add doors/windows",
          image: AddingItemsToYourRoom,
        },
      ],
    },
  },
];

export const mainMenu2D: MainMenu2DT = [
  {
    key: "Draw",
    text: "Draw",
    imgUrl: Draw,
    setting: settingsDraw,
  },
  {
    key: "Select",
    text: "Select",
    imgUrl: Select,
    setting: settingsSelect,
  },
  {
    key: "Doors",
    text: "Doors",
    imgUrl: Doors,
    setting: settingsDoors,
  },
  {
    key: "Windows",
    text: "Windows",
    imgUrl: Windows,
    setting: settingsWindows,
  },
  {
    key: "Openings",
    text: "Openings",
    imgUrl: Openings,
    setting: settingsOpenings,
  },
  {
    key: "Layout Templates",
    text: "Layout Templates",
    imgUrl: LayoutTemplates,
    setting: settingsTemplate,
  },
];

export interface RadioButtomsSmallValuesI {
  id: string;
  value: IUnits;
  label: string;
}
export const MeasurementUnitValues: RadioButtomsSmallValuesI[] = [
  {
    id: "Measurement_meter",
    value: IUnits["METER"],
    label: "m/cm",
  },
  {
    id: "Measurement_feet",
    value: IUnits["FEET"],
    label: "ft/in",
  },
];
