import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getValueOption } from "../../../store/selectors/settingsUISelectors";
import { updateSettings } from "../../../store/slices/settingsUI/settingsUISlice";
import { DistributeComponents } from "../../DistributeComponents/DistributeComponents";
import { IComponent } from "./../../../types/UI/dynamic-rendering.interfaces"
import s from "./Tabs.module.scss";

export const Tabs = ({ ...props }) => {
  const { id, embeddedView } = props;

  const dispatch = useAppDispatch();

  const selectValue = useAppSelector(getValueOption({idOption: id}));

  const handleClickTab = (idItemTab: string) => {
    dispatch(updateSettings([{optionId: id, value: idItemTab}]))
  }

  const listTitles = embeddedView.map((itemTab: IComponent) => {
    const label = itemTab["data"]["label"];
    const idItemTab = itemTab["data"]["id"];
    return (
      <li className={`${s.tabsLink} ${selectValue === idItemTab && s.active}`} onClick={() => handleClickTab(idItemTab)} key={idItemTab}>{label}</li>
    )
  })  

  const embeddedViewChildren = () => {
    let currentTabEmbeddedView = embeddedView.find((itemSettings: IComponent) => itemSettings["data"]["id"] === selectValue)
    return currentTabEmbeddedView["data"]["embeddedView"];
  }

  return (
    <div className={s.tabsWrap}>
      <ul className={s.tabsLinkList}>
        {listTitles}
      </ul>
      <div className={s.tabsContent}>
        {embeddedViewChildren() && embeddedViewChildren().map((setting: IComponent) => {
          return <DistributeComponents parentKeySection={setting['data']['id']} key={setting['data']['id']} {...setting} />
        })}
        
        {/* <FiltersGroup />
        <CategoryGroup options={optionsCategoryDoorsOnly} label="Doors Only" />
        <CategoryGroup options={optionsCategoryDrawersOnly} label="Drawers Only"  />
        <OptionsGroup options={productsList} />
        <SwitchStandart />
        <CabinetsStyleGroup />
        <SelectColor />
        <SubMenu2DWrap />
        <SubMenu2DHelp /> */}
      </div>
    </div>
  );
};