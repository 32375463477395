export const DoorOpeningLeft = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 0.810214C20 0.362745 19.6269 0 19.1667 0L0.833335 8.64588e-10C0.373096 8.86309e-10 0 0.362745 0 0.810214C0 1.1958 0.277038 1.51848 0.648146 1.60035L9.24842 19.532C9.38322 19.8131 9.66276 19.9836 9.96052 19.9989C9.97872 20 9.99701 20.0004 10.0153 19.9999C10.1297 19.9984 10.2455 19.9739 10.3559 19.9239C10.4392 19.8861 10.5131 19.8367 10.5764 19.7784C10.8883 19.6127 11.1891 19.4444 11.4794 19.2736C11.7769 19.0986 11.8763 18.7155 11.7012 18.418C11.5262 18.1205 11.1432 18.0212 10.8456 18.1962C10.743 18.2565 10.6389 18.3167 10.5332 18.3765L2.49674 1.62043H17.9285C17.9283 1.62682 17.9282 1.63323 17.9282 1.63967C17.9282 1.97225 17.9295 2.30549 17.9309 2.63722L17.9321 2.96462C17.9334 3.30979 18.2142 3.58862 18.5593 3.58739C18.9045 3.58617 19.1833 3.30535 19.1821 2.96018L19.1809 2.63027C19.1795 2.29922 19.1782 1.96953 19.1782 1.63975C19.1782 1.63326 19.1781 1.6268 19.1779 1.62035C19.633 1.61449 20 1.25402 20 0.810214Z"
        fill="#332E20"
      />
      <path
        d="M17.9068 5.58716C17.9181 5.24217 18.207 4.97167 18.552 4.98297C18.8969 4.99428 19.1674 5.28312 19.1561 5.62811C19.1267 6.52794 19.0672 7.42991 18.9497 8.32544C18.9048 8.66768 18.5909 8.90872 18.2487 8.86381C17.9064 8.81891 17.6654 8.50506 17.7103 8.16282C17.8209 7.31961 17.8782 6.46045 17.9068 5.58716Z"
        fill="#332E20"
      />
      <path
        d="M17.1907 10.6506C17.29 10.32 17.6385 10.1326 17.9691 10.2319C18.2997 10.3313 18.4871 10.6798 18.3878 11.0103C18.1269 11.8783 17.7812 12.7334 17.3234 13.5685C17.1575 13.8712 16.7776 13.982 16.4749 13.8161C16.1723 13.6502 16.0614 13.2703 16.2273 12.9676C16.6392 12.2163 16.9525 11.443 17.1907 10.6506Z"
        fill="#332E20"
      />
      <path
        d="M14.7905 15.02C15.0173 14.7598 15.4121 14.7327 15.6723 14.9595C15.9325 15.1864 15.9595 15.5812 15.7327 15.8414C15.1622 16.4958 14.5007 17.1284 13.7349 17.7367C13.4646 17.9514 13.0714 17.9063 12.8567 17.636C12.6421 17.3657 12.6872 16.9725 12.9575 16.7578C13.6669 16.1944 14.2726 15.614 14.7905 15.02Z"
        fill="#332E20"
      />
    </svg>
  );
};
