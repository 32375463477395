import useWindowSize from "./useWindowSize";

export default function useMobileWidth(responceWidth: number) {

    const windowSize = useWindowSize();
    
    const isMobileSize = (!!windowSize.width && windowSize.width <= responceWidth) ? true : false;

    return isMobileSize;

}
