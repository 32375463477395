import { KeysModalsT } from "../slices/modalsSlice/modalsSliceT";
import { RootState } from "../store";

export const getAllModalsInfo = (state: RootState) => {
  return state["modals"];
}

export const getIsShownModals = (state: RootState) => {
  const allModals = getAllModalsInfo(state);
  let isShownModals: boolean = false;
  const allModalsKeys = Object.keys(allModals) as KeysModalsT[];
  allModalsKeys.forEach((keyModal: KeysModalsT) => {
    if (allModals[keyModal]) isShownModals = true;
  });
  return isShownModals;
}

export const getModalInfo = (state: RootState) => (id: KeysModalsT) => {
  const allModals = getAllModalsInfo(state);
  const modalInfo = allModals[id];
  return modalInfo;
}