import { getModalInfo } from "../../../store/selectors/modalsSelectors";
import { ModalsWrap } from "../ModalsWrap/ModalsWrap";
import s from "./ModalHelp2D.module.scss";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { Accordion } from "../../Accordion/Accordion";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import { helpData2D } from "../../../utils/constants/helpData2D";

export const ModalHelp2D = ({ ...props }) => {
  const modalInfo = useAppSelector((state) => getModalInfo(state)(MODAL_KEYS.HELP_2D));
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.HELP_2D, value: false}));
  }

  if (!modalInfo["isVisible"]) return null;

  return (
    <ModalsWrap modalKey={MODAL_KEYS.HELP_2D}>
      <div className={s.modalHelp2D}>
        <div className={s.content}>
          <h3 className={s.title}>Recreate your layout</h3>
          <p className={s.headSubTitle}>When you are ready click <u>View Room Design</u>  to start designing your perfect room. </p>
          <Accordion accordionData={helpData2D} />
          <p className={s.footerDescr}>When you are ready click <u>View Room Design</u> to start designing your perfect room.</p>
          <div className={s.btnsWrap}>
            <ButtonMain text="Start Creating" onClick={handleCloseModal} color="blueDark" size="bigSuper" bold={true} />
          </div>
        </div>
      </div>
    </ModalsWrap>
  )
}