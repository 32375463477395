import React, { useState } from "react";
import { CloseModalSvg } from "../../assets/images/svg/CloseModal";
import s from "./SubMenu2DHelp.module.scss";
import AddingItemsToYourRoom from "./../../assets/images/Modals/Help/Adding items to your room.png";
import { ButtonMain } from "../StandartComponents/Buttons/ButtonMain";

const helpContent = [
  {
    position: 0,
    title: "Adding Walls",
    description: "Click and drag in the player to add wall",
    image: AddingItemsToYourRoom,
  },
  {
    position: 1,
    title: "Modifying a wall",
    description:
      "Click on a wall to bring uop the config panel to adjust dimension or add doors/windows",
    image: AddingItemsToYourRoom,
  },
];

export const SubMenu2DHelp = ({...props}) => {
  const { id, values, onCloseMenu2D } = props;

  const handleCloseSubMenu2D = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    onCloseMenu2D()
  };

  const [activeSlide, setActiveSlide] = useState<number>(0);

  const switchStepSlider = () => {
    const nextStep = (step: number) => step + 1;
    setActiveSlide(nextStep(activeSlide));
  };

  return (
    <div className={s.subMenu2DHelpWrap}>
      <div className={s.close} onClick={(e) => handleCloseSubMenu2D(e)}>
        <CloseModalSvg />
      </div>
      <div className={s.sliderContent}>
        <div className={s.title}>{values[activeSlide]["title"]}</div>
        <div className={s.description}>
          {values[activeSlide]["description"]}
        </div>
        <div className={s.image}>
          <img
            src={values[activeSlide]["image"]}
            alt={values[activeSlide]["title"]}
          />
        </div>
      </div>
      <div className={s.sliderControls}>
        {activeSlide === values.length - 1 ? (
          <ButtonMain
            text="Close"
            onClick={(e) => handleCloseSubMenu2D(e)}
            color="blueDark"
            size="middle"
          />
        ) : (
          <ButtonMain
            text="Next"
            onClick={switchStepSlider}
            color="blueDark"
            size="middle"
          />
        )}
      </div>
    </div>
  );
};
