import * as THREE from "three";

export const movePointInDirection = (point: THREE.Vector3, direction: THREE.Vector3, distance: number): THREE.Vector3 => {
  // Створюємо копію вектора, щоб не змінювати оригінал
  const dir = direction.clone().normalize();
  // Обчислюємо нові координати точки
  const x = point.x + dir.x * distance;
  const y = point.y + dir.y * distance;
  const z = point.z + dir.z * distance;
  return new THREE.Vector3(x, y, z);
}