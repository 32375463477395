export function handlePointerLeaveInCanvas(wrapper: any = undefined) {
    let el: any = wrapper ? wrapper : document.querySelector("#player_wrapper");
    let isMousePressed = false;

    el.addEventListener("mousedown", function () {
      isMousePressed = true;
    });

    el.addEventListener("mouseup", function () {
      isMousePressed = false;
    });

    el.addEventListener("mouseout", function (event: any) {
      if (isMousePressed && !el.contains(event.relatedTarget)) {
        console.log('123');
        let ev = new MouseEvent("mouseup", {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        el.dispatchEvent(ev);
      }
    });
  }