export const DeleteBasketSvg = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2753_5354)">
        <path
          d="M7.03603 1.77218L6.8277 1.77153C6.94228 1.77188 7.03631 1.68218 7.03665 1.57218L7.03603 1.77218L14.9527 1.79683L14.9533 1.59683C14.9529 1.70683 15.0464 1.79712 15.161 1.79748L14.9527 1.79683L14.9471 3.59682L16.822 3.60266L16.8283 1.60267C16.831 0.720178 16.0859 0.000353497 15.1666 -0.00250953L6.8333 -0.0284633C5.91404 -0.0313263 5.16441 0.683843 5.16166 1.56634L5.15543 3.56633L7.03043 3.57217L7.03603 1.77218ZM20.1554 3.61305L1.82212 3.55595C1.36118 3.55451 0.987675 3.91085 0.986296 4.35335L0.983805 5.15334C0.983462 5.26334 1.07693 5.35364 1.19151 5.35399L2.76442 5.35889L3.36693 18.4358C3.40594 19.2885 4.13822 19.9632 5.02623 19.966L16.8491 20.0028C17.7397 20.0056 18.4736 19.3379 18.5179 18.483L19.2018 5.41008L20.7748 5.41498C20.8893 5.41534 20.9834 5.32563 20.9837 5.21563L20.9862 4.41564C20.9876 3.97314 20.6163 3.61448 20.1554 3.61305ZM16.6542 18.2022L5.23236 18.1666L4.64202 5.36474L17.3242 5.40424L16.6542 18.2022Z"
          fill="#332E20"
        />
      </g>
      <defs>
        <clipPath id="clip0_2753_5354">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(1 0.015625) rotate(0.178445)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
