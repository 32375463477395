import React from "react";
import s from "./PresetList.module.scss";

import PresetIconCustom from "./../../assets/room-list/planning_custom.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/useStoreHooks";
import { ROUTES } from "../../router/RouterProvider/RouterProvider";
import { setActivePreset, setInitialWalls } from "../../store/slices/settingsUI/settingsUISlice";
import { getPresetsRoomsList } from "../../store/selectors/settingsUISelectors";
import { ArrWallTemplateT } from "../../utils/constants/presetsRoom";

export const PresetList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const presetsRoomsList = useAppSelector(getPresetsRoomsList);

  const onClickPreset = (templateArr: ArrWallTemplateT, templateId: string) => {
    dispatch(setInitialWalls(templateArr));
    dispatch(setActivePreset(templateId));
    navigate(ROUTES["buildRoom"], { replace: true });
  };

  return (
    <div className={s.wrap}>
      <p className={s.title}>Select a Starting Floor Plan Shape</p>
      <div className={s.list}>
        {presetsRoomsList.map((template, indx: number) => (
          <div
            className={s.presetButton}
            key={template["id"]}
            onClick={() => onClickPreset(template["data"], template["id"])}
          >
            <img src={template["icon"]} alt="" />
            {!!template["title"] && <p>{template["title"]}</p>}
          </div>
        ))}
        <div
            className={s.presetButton}
            key={"Custom Layout"}
            onClick={() => onClickPreset([], '')}
          >
            <img src={PresetIconCustom} alt="" />
            <p>Draw a custom floor plan</p>
          </div>
        </div>
    </div>
  );
};
