import s from "./WallTypeSwitch.module.scss"; 
interface IWallTypeSwitch {
  label: string;
  icon: JSX.Element;
  attribute: any; 
}

export const WallTypeSwitch = ({ ...props }: IWallTypeSwitch) => {
  const { label, attribute } = props;

  const itemVisble = attribute["values"].find(
    (item: any) => item["name"] === "Visible"
  );
  const itemInvisiblee = attribute["values"].find(
    (item: any) => item["name"] === "Invisible"
  ); 

  return (
    <div className={s.wallTypeSwitch}>
      {label && <p className={s.labelText}>{label}</p>}
      <div className={s.switchWrap}>
        <button
          className={`${itemVisble["selected"] && s.active}`}
          onClick={() => itemVisble.handleSelect()}
        >
          Wall
        </button>
        <button
          className={`${itemInvisiblee["selected"] && s.active}`}
          onClick={() => itemInvisiblee.handleSelect()}
        >
          Boundary
        </button>
      </div>
    </div>
  );
};
