import {
  ModelAppliancesT,
  ModelCabinetIslandT,
  NODES_THREEKIT,
} from "../../../../utils/constants/nodesNamesThreekit";
import { getNodeIdFromName, getTranslationThreekit } from "../../../../utils/threekit/general/getFunctions";
import { checkIntersectModelFromModelsList } from "../../IntersectsModelsBox";
import { getSizeModelBoxFromAssetCabinetBase } from "../../cabinetsBase/size";
import { getСompletedModelsNullNames } from "../../getNodesCabinets";
import { getConnectorConnected } from "../../connectors";
import { isEqualCoordsTolerance } from "../../addCornerModelBase";
import { CONNECTORS_CABINET_ISLAND } from "../../../../utils/constants/connectors";
import { ObjInfoForCabinetsIslandT, getObjInfoForCabinetsIsland } from "../getObjInfoForCabinetsIsland";
import { getKeys } from "../../../../utils/other/getObjKeysFromType";
import { getVector3FromCoordinates } from "../../../../utils/three/general/getFunctionsTHREE";
import { setTranslationThreekit } from "../../../../utils/threekit/general/setFunctions";
import { selectClearThreekit, selectSetThreekit } from "../../../../utils/threekit/general/selection";

type nameCabinetT = ModelCabinetIslandT | ModelAppliancesT;
type regexpModelT =
  | NODES_THREEKIT.MODEL_CABINET_ISLAND
  | NODES_THREEKIT.MODEL_APPLIANCES;
// function checkModelInPositionFree(nameNullNewModel: ModelCabinetIslandT): boolean {
//   const newModelSize = getSizeModelBoxFromAssetCabinetBase(nameNullNewModel);
//   const completedModelsNullNamesCabinetsIsland = getСompletedModelsNullNames(
//     NODES_THREEKIT.MODEL_CABINET_ISLAND
//   );
//   let isStartCoordsFree = true;
//   completedModelsNullNamesCabinetsIsland.forEach((nulNameCompletedModel) => {
//     const modelPos = getModelPositionFromName(nulNameCompletedModel);
//     if (nulNameCompletedModel !== nameNullNewModel && checkPointEquality({x: 0, y: 0, z: 0}, modelPos, newModelSize["x"]/2)) {
//       isStartCoordsFree = false;
//     }
//   })
//   return isStartCoordsFree
// }

const checkOverlapOfPositionModel = (nameCabinet: nameCabinetT): boolean => {
  const positionCurentModel = getTranslationThreekit({name: nameCabinet});
  const completedCabinetsIsland = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_ISLAND
  );
  const completedAppliances = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_APPLIANCES
  );
  const completedCabinetsbase = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_BASE
  );
  const modelsList = [ ...completedCabinetsIsland, ...completedAppliances, ...completedCabinetsbase ];
  let isOverlapOfPosition = false;

  modelsList.forEach((modelName) => {
    if (modelName === nameCabinet) return;
    const positionModel = getTranslationThreekit({name: modelName});
    const isEqualCoords = isEqualCoordsTolerance(
      positionCurentModel,
      positionModel,
      0.01
    );
    if (isEqualCoords) isOverlapOfPosition = true;
  })

  return isOverlapOfPosition;
}

/**
 * Перевіряє чи перетинається модель з іншими моделями
 *
 * @param {nameCabinetT} nameCabinet Name Null моделі, для якої робимо перевірку на перетин з іншими моделями
 * @return {ICoordinates} true - модель перетинається з іншими моделями, false - модель не перетинається з іншими моделями
 */
function checkModelItersects(nameCabinet: nameCabinetT): boolean {
  const isIntersectNewModelFromIsland = checkIntersectModelFromModelsList(
    nameCabinet,
    NODES_THREEKIT.MODEL_CABINET_ISLAND
  );
  const isIntersectNewModelFromAppliances = checkIntersectModelFromModelsList(
    nameCabinet,
    NODES_THREEKIT.MODEL_APPLIANCES
  );
  const isIntersectNewModelFromBase = checkIntersectModelFromModelsList(
    nameCabinet,
    NODES_THREEKIT.MODEL_CABINET_BASE
  );

  const intersecting = isIntersectNewModelFromIsland || isIntersectNewModelFromAppliances || isIntersectNewModelFromBase;

  return intersecting;
}

/**
 * Перевіряє чи перетинається модель з іншими моделями
 * Перевіряє чи є з'єнання для конекторів моделі
 * Перевіряє чи є співпадіння позиції моделі з іншими моделями
 *
 * @param {nameCabinetT} nameCabinet Name Null моделі, для якої робимо перевірку
 * @return {boolean} 
 * true - модель не перетинається з іншими моделями, або в моделі з'єднаний один з конекторів, або позиція моделі не спіппадає з позиціями жодної іншой моделі
 * false - або модель перетинається з іншими моделями, або в моделі не з'єднаний жоден з конекторів, або позиція моделі спіппадає з позицією іншой моделі
 */
function checkIntersectOrConections(nameCabinet: nameCabinetT): boolean {

  const isIntersectNewModels =
    checkModelItersects(nameCabinet);

  const connectingLeft = getConnectorConnected(
    nameCabinet,
    CONNECTORS_CABINET_ISLAND.LEFT
  );
  const connectingRight = getConnectorConnected(
    nameCabinet,
    CONNECTORS_CABINET_ISLAND.RIGHT
  );

  const isIntersectAndConnectors = !isIntersectNewModels || connectingLeft !== null || connectingRight !== null;
  const isOverlapOfPositionModel = checkOverlapOfPositionModel(nameCabinet);

  return isIntersectAndConnectors && !isOverlapOfPositionModel;
}

// const getMoveDistance = (nameCurentModel: nameCabinetT): number => {
//   let moveDistance = 50;

//   const positionCurentModel = getTranslationThreekit({name: nameCurentModel});
//   const objInfoForCabinetsIsland = getObjInfoForCabinetsIsland();
//   console.log("objInfoForCabinetsIsland --- ==== ", objInfoForCabinetsIsland);
//   const keysObjInfoForCabinetsIsland = getKeys(objInfoForCabinetsIsland);

//   let arrDistanceExtremeModels: number[] = []

//   keysObjInfoForCabinetsIsland.forEach((nameModel) => {
//     if (nameModel === nameCurentModel) return;
//     const filteredArrNeabors = objInfoForCabinetsIsland[nameModel]["neabor"].filter((nameModelNeabor) => {
//       return nameModelNeabor !== nameCurentModel;
//     })
//     console.log("filteredArrNeabors --- ==== ", filteredArrNeabors);
//     if (filteredArrNeabors.length < 2) {
//       const positionModel = getTranslationThreekit({name: nameModel});
//       const distance = getVector3FromCoordinates(positionCurentModel).distanceTo(getVector3FromCoordinates(positionModel));
//       arrDistanceExtremeModels.push(distance);
//     }
//   })

//   console.log("arrDistanceExtremeModels --- ==== ", arrDistanceExtremeModels);

//   const minDastance = Math.min.apply(null, arrDistanceExtremeModels);
//   console.log("minDastance --- ==== ", minDastance);
//   if (arrDistanceExtremeModels.length === 1) {
//     return moveDistance
//   } else if (arrDistanceExtremeModels.length > 1 && minDastance === 0) {
//     return -moveDistance
//   } else {
//     return minDastance * 100
//   }



//   return moveDistance;
// }

const getModelFromMinDistance = (nameCurentModel: nameCabinetT): ObjInfoForCabinetsIslandT => {

  const positionCurentModel = getTranslationThreekit({name: nameCurentModel});
  const objInfoForCabinetsIsland = getObjInfoForCabinetsIsland();
  const keysObjInfoForCabinetsIsland = getKeys(objInfoForCabinetsIsland);
  let distanceMin: number | null = null;
  let modelObj: ObjInfoForCabinetsIslandT = {}

  keysObjInfoForCabinetsIsland.forEach((nameModel) => {
    if (nameModel === nameCurentModel) return;
    const filteredArrNeabors = objInfoForCabinetsIsland[nameModel]["neabor"].filter((nameModelNeabor) => {
      return nameModelNeabor !== nameCurentModel;
    })
    if (filteredArrNeabors.length < 2) {
      const positionModel = getTranslationThreekit({name: nameModel});
      const distance = getVector3FromCoordinates(positionCurentModel).distanceTo(getVector3FromCoordinates(positionModel));
      if (distanceMin === null) {
        modelObj = {
          [nameModel]: {
            ...objInfoForCabinetsIsland[nameModel]
          }
        }
        distanceMin = distance
      } else {
        if (distance < distanceMin) {
          modelObj = {
            [nameModel]: {
              ...objInfoForCabinetsIsland[nameModel]
            }
          }
          distanceMin = distance
        }
      }
      

    }
  })

  return modelObj;

}

function moveElementInScene(
  nameCabinet: nameCabinetT,
  moveDistanceX: number,
  moveDistanceY: number,
  updatedX: number,
  x: number,
  y: number
) {

  selectClearThreekit();

  var el: HTMLElement | null = document.querySelector("#player_wrapper canvas");

  // TEST CODE
  // var elPL: HTMLElement | null = document.querySelector("#player_wrapper");
  // TEST CODE

  const modelId = getNodeIdFromName(nameCabinet);
  selectSetThreekit(modelId);

  if (!!el) {
    // для урахування положення канвасу в вікні браузера
    const boundingClientRect: DOMRect = el?.getBoundingClientRect();
    let ev = new MouseEvent("mousedown", {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: x + boundingClientRect["left"] + updatedX,
      clientY: y,
    });
    el.dispatchEvent(ev);

    // TEST CODE
    // const newDiv1 = document.createElement('div');
    // newDiv1.className = 'mousedown';
    // newDiv1.style.backgroundColor = 'red';
    // newDiv1.style.width = '10px';
    // newDiv1.style.height = '10px';
    // newDiv1.style.position = 'absolute';
    // newDiv1.style.left = `${x + updatedX}px`;
    // newDiv1.style.top = `${y}px`;
    // if (elPL)
    //   elPL.appendChild(newDiv1);
    // TEST CODE

    ev = new MouseEvent("mousemove", {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: x + boundingClientRect["left"] + updatedX + moveDistanceX,
      clientY: y + moveDistanceY,
    });
    el.dispatchEvent(ev);

    // TEST CODE
    // const newDiv2 = document.createElement('div');
    // newDiv2.className = 'mousemove';
    // newDiv2.style.backgroundColor = 'pink';
    // newDiv2.style.width = '10px';
    // newDiv2.style.height = '10px';
    // newDiv2.style.position = 'absolute';
    // newDiv2.style.left = `${x + updatedX + moveDistanceX}px`;
    // newDiv2.style.top = `${y + moveDistanceY}px`;
    // if (elPL)
    //   elPL.appendChild(newDiv2);
    // TEST CODE

    ev = new MouseEvent("mouseup", {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: x + boundingClientRect["left"] + updatedX + moveDistanceX,
      clientY: y + moveDistanceY,
    });
    el.dispatchEvent(ev);

    // TEST CODE
    // const newDiv3 = document.createElement('div');
    // newDiv3.className = 'mouseup';
    // newDiv3.style.backgroundColor = 'blue';
    // newDiv3.style.width = '10px';
    // newDiv3.style.height = '10px';
    // newDiv3.style.position = 'absolute';
    // newDiv3.style.left = `${x + updatedX + moveDistanceX}px`;
    // newDiv3.style.top = `${y + moveDistanceY}px`;
    // if (elPL)
    //   elPL.appendChild(newDiv3);
    // TEST CODE

  }
  // @ts-ignore
  // window.threekit.player.selectionSet.clear();
}

function clickCustom(
  nameCabinet: nameCabinetT,
  x: number,
  y: number,
  regexpModel: regexpModelT,
  allModelsLength: number
) {

  const isIntersectNewModels = checkModelItersects(nameCabinet);

  const emptyModelsOrNoIntersectingAppliancesAndIslands =
    allModelsLength === 0 || !isIntersectNewModels;
  if (emptyModelsOrNoIntersectingAppliancesAndIslands) return;

  function repeatMovingScene() {

    const sizeCurrentModel = getSizeModelBoxFromAssetCabinetBase(nameCabinet);
    let moveDistanceX = sizeCurrentModel["x"]/2 * 100;
    let moveDistanceY = 0;
    let updatedX = 0;
    let isModelPositionValidate = checkIntersectOrConections(nameCabinet);
    let countToStop = 0;

    async function innerFunction() {

      if (countToStop >= 4) return;

      if (!isModelPositionValidate) {

        moveElementInScene(nameCabinet, moveDistanceX, moveDistanceY, updatedX, x, y);
        
        if (countToStop === 0) {
          moveDistanceX = moveDistanceX * -2;
          moveDistanceY = 0
        }
        if (countToStop === 1) {
          moveDistanceX = 0;
          moveDistanceY = sizeCurrentModel["z"]/2 * 100;
        }
        if (countToStop === 2) {
          moveDistanceX = 0;
          moveDistanceY = moveDistanceY * -1;
        }
        updatedX = 0;

        await new Promise((resolve) => setTimeout(resolve, 100));
        isModelPositionValidate = checkIntersectOrConections(nameCabinet);

        setTimeout(() => {
          innerFunction();
        }, 100);

      }

      countToStop++;

    }

    innerFunction();
  }

  repeatMovingScene();
}

export const emulationMoveCabinetIsland = (
  countNewCabinets: number,
  regexpModel: regexpModelT
) => {

  // TEST CODE
  // const nameElement1 = document.querySelectorAll('.mousedown');
  // const nameElement2 = document.querySelectorAll('.mousemove');
  // const nameElement3 = document.querySelectorAll('.mouseup');

  // nameElement1.forEach(nameElement => {
  //   nameElement.remove();
  // });
  // nameElement2.forEach(nameElement => {
  //   nameElement.remove();
  // });
  // nameElement3.forEach(nameElement => {
  //   nameElement.remove();
  // });
  // TEST CODE
  
  const currentCompletedCabinets = getСompletedModelsNullNames(regexpModel);
  const completedCabinetsIsland = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_ISLAND
  );
  const completedAppliances = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_APPLIANCES
  );
  const completedCabinetsbase = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_BASE
  );
  const modelsList = [
    ...completedCabinetsIsland,
    ...completedAppliances,
    ...completedCabinetsbase
  ];

  for (
    let i = currentCompletedCabinets.length - countNewCabinets;
    i < currentCompletedCabinets.length;
    i++
  ) {

    const allModelsLength = modelsList.length - countNewCabinets;
    const nameCabinet: nameCabinetT = `${regexpModel}${i}`;
    const objModelsFromMinDistance = getModelFromMinDistance(nameCabinet);
    const objModelsFromMinDistanceValues = Object.values(objModelsFromMinDistance);

    if (objModelsFromMinDistanceValues.length > 0) {

      const posModelFromMinDistance = objModelsFromMinDistanceValues[0]["position"];
      //@ts-ignore
      const worldPositionToScreen = window.threekit.player.worldPositionToScreen(posModelFromMinDistance);

      setTranslationThreekit({
        name: nameCabinet,
        value: posModelFromMinDistance,
      })

      setTimeout(() => {
        clickCustom(
          nameCabinet,
          worldPositionToScreen["x"],
          worldPositionToScreen["y"] - 30,
          regexpModel,
          allModelsLength
        );
      }, 100)
      
    } else {
      // @ts-ignore
      const worldPositionToScreen = window.threekit.player.worldPositionToScreen({x: 0, y: 0, z: 0});
      clickCustom(
        nameCabinet,
        worldPositionToScreen["x"],
        worldPositionToScreen["y"] - 30,
        regexpModel,
        allModelsLength
      );
    }
    
  }
};
