import { IHydratedAttribute } from "@threekit-tools/treble/dist/types";
import * as THREE from "three";
import { ATTRIBUTE_NAMES } from "../../utils/constants/attributesThreekitRoomBuilder";
import { getEndPointFromConfigurationWall, getStartPointFromConfigurationWall } from "./wallsGeneral";

export const arePointsEqual2D = (
  p1: THREE.Vector2,
  p2: THREE.Vector2,
  tolerance: number
): boolean => {
  // Обчислюємо відстань між точками за допомогою відповідних векторів
  const distance = p1.distanceTo(p2);
  // Перевіряємо, чи відстань між точками менша або рівна допустимій розбіжності
  return distance <= tolerance;
};

export const getIndexWallNextToRight = (wallAttributeValue: any, indxSelectedWall: number, endPointSelectedWall: THREE.Vector2): number => {
  return wallAttributeValue.findIndex(
    (wall: any, indx: number) => {
      const startPointWall = getStartPointFromConfigurationWall(wall);
      const endPointWall = getEndPointFromConfigurationWall(wall);
      return (
        indx !== indxSelectedWall &&
        (arePointsEqual2D(endPointSelectedWall, startPointWall, 0.001) ||
          arePointsEqual2D(endPointSelectedWall, endPointWall, 0.001))
      );
    }
  );
}

export const getIndexWallNextToLeft = (wallAttributeValue: any, indxSelectedWall: number, startPointSelectedWall: THREE.Vector2): number => {
  return wallAttributeValue.findIndex(
    (wall: any, indx: number) => {
      const startPointWall = getStartPointFromConfigurationWall(wall);
      const endPointWall = getEndPointFromConfigurationWall(wall);
      return (
        indx !== indxSelectedWall &&
        (arePointsEqual2D(startPointSelectedWall, startPointWall, 0.001) ||
          arePointsEqual2D(startPointSelectedWall, endPointWall, 0.001))
      );
    }
  );
}

type OrientationWallT = "x" | "y";
const getOrientationWall = (
  startPoint: THREE.Vector2,
  endPoint: THREE.Vector2,
): OrientationWallT => {

  const deltaX0 = endPoint["x"] - startPoint["x"];
  const deltaY0 = endPoint["y"] - startPoint["y"];

  return Math.abs(deltaX0) > Math.abs(deltaY0) ? 'x' : 'y';

}

const getChangedPointSelectedWall = (
  startPoint: THREE.Vector2,
  endPoint: THREE.Vector2,
  orientationWall: OrientationWallT
): THREE.Vector2 => {
  
  if (orientationWall === "x") {

    if (endPoint["x"] > startPoint["x"]) {
      return endPoint;
    } else {
      return startPoint;
    }

  } else {

    if (endPoint["y"] > startPoint["y"]) {
      return endPoint;
    } else {
      return startPoint;
    }

  }

}

const getIndexNeaborWallInPoint = (
  wallAttributeValue: any,
  indxSelectedWall: number,
  point: THREE.Vector2
): number => {
  return wallAttributeValue.findIndex(
    (wall: any, indx: number) => {
      const startPointWall = getStartPointFromConfigurationWall(wall);
      const endPointWall = getEndPointFromConfigurationWall(wall);
      return (
        indx !== indxSelectedWall &&
        (arePointsEqual2D(point, startPointWall, 0.001) ||
          arePointsEqual2D(point, endPointWall, 0.001))
      );
    }
  );
}

const getWallDirection = (
  startPoint: THREE.Vector2,
  endPoint: THREE.Vector2,
  changedPoint: THREE.Vector2,
): THREE.Vector2 => {

  if (arePointsEqual2D(endPoint, changedPoint, 0.001)) {

    return new THREE.Vector2()
      .subVectors(endPoint, startPoint)
      .normalize();

  } else {
    
    return new THREE.Vector2()
      .subVectors(startPoint, endPoint)
      .normalize();

  }

}

const getMovedCoordsSelectedWall = (
  startPoint: THREE.Vector2,
  endPoint: THREE.Vector2,
  changedPoint: THREE.Vector2,
  newWallLength: number
): THREE.Vector2 => {

  const wallDirection = getWallDirection(
    startPoint,
    endPoint,
    changedPoint,
  );

  const notChangedPoint = arePointsEqual2D(endPoint, changedPoint, 0.001) ? startPoint : endPoint;

  const newFinalPoint = new THREE.Vector2().addVectors(
    notChangedPoint,
    wallDirection.multiplyScalar(newWallLength)
  );

  return newFinalPoint;

}

const getNewConfigurationCoordsWall = (
  startPoint: THREE.Vector2,
  endPoint: THREE.Vector2,
  changedPoint: THREE.Vector2,
  newCoordsChangedPoint: THREE.Vector2,
) => {

  if (arePointsEqual2D(endPoint, changedPoint, 0.001)) {
    return {
      [ATTRIBUTE_NAMES.wallStartX]: startPoint["x"],
      [ATTRIBUTE_NAMES.wallStartZ]: startPoint["y"],
      [ATTRIBUTE_NAMES.wallEndX]: newCoordsChangedPoint["x"],
      [ATTRIBUTE_NAMES.wallEndZ]: newCoordsChangedPoint["y"],
    }
  } else {
    return {
      [ATTRIBUTE_NAMES.wallStartX]: newCoordsChangedPoint["x"],
      [ATTRIBUTE_NAMES.wallStartZ]: newCoordsChangedPoint["y"],
      [ATTRIBUTE_NAMES.wallEndX]: endPoint["x"],
      [ATTRIBUTE_NAMES.wallEndZ]: endPoint["y"],
    }
  }

}

export const getNewConfigurationLengthWalls = (
  attributeWalls: IHydratedAttribute,
  selectedWallPath: any,
  value: number
) => {

  const wallAttributeValue = JSON.parse(
    JSON.stringify(attributeWalls["value"])
  );

  const indxSelectedWall = selectedWallPath[1];
  const startPointSelectedWall = getStartPointFromConfigurationWall(wallAttributeValue[indxSelectedWall]);
  const endPointSelectedWall = getEndPointFromConfigurationWall(wallAttributeValue[indxSelectedWall]);

  const orientationSelectedWall = getOrientationWall(
    startPointSelectedWall,
    endPointSelectedWall
  );

  const changedPointSelectedWall = getChangedPointSelectedWall(
    startPointSelectedWall,
    endPointSelectedWall,
    orientationSelectedWall
  );

  const indxNeaborWall = getIndexNeaborWallInPoint(
    wallAttributeValue,
    indxSelectedWall,
    changedPointSelectedWall
  );

  const movedCoords = getMovedCoordsSelectedWall(
    startPointSelectedWall,
    endPointSelectedWall,
    changedPointSelectedWall,
    value,
  );

  const newValuesAttributeWall = wallAttributeValue.map(
    (attributeValue: any, indx: number) => {
      if (indx === indxSelectedWall) {
        const newConfigurationCoordsWall = getNewConfigurationCoordsWall(
          startPointSelectedWall,
          endPointSelectedWall,
          changedPointSelectedWall,
          movedCoords
        )
        return {
          ...attributeValue,
          configuration: {
            ...attributeValue["configuration"],
            ...newConfigurationCoordsWall
          },
        };
      }
      if (indxNeaborWall !== -1 && indx === indxNeaborWall) {
        const startPointWall = getStartPointFromConfigurationWall(attributeValue);
        const endPointWall = getEndPointFromConfigurationWall(attributeValue);
        const newConfigurationCoordsWall = getNewConfigurationCoordsWall(
          startPointWall,
          endPointWall,
          changedPointSelectedWall,
          movedCoords
        )
        return {
          ...attributeValue,
          configuration: {
            ...attributeValue["configuration"],
            ...newConfigurationCoordsWall
          },
        };
      }
      return attributeValue;
    }
  );

  return newValuesAttributeWall;

};