export const DeleteAllSvg = () => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0951 9.75887H11.9596C12.0341 9.75887 12.0951 9.70037 12.0951 9.62887V9.75887H17.2409V9.62887C17.2409 9.70037 17.3018 9.75887 17.3763 9.75887H17.2409V10.9289H18.4596V9.62887C18.4596 9.05524 17.9738 8.58887 17.3763 8.58887H11.9596C11.3621 8.58887 10.8763 9.05524 10.8763 9.62887V10.9289H12.0951V9.75887ZM20.6263 10.9289H8.70964C8.41003 10.9289 8.16797 11.1612 8.16797 11.4489V11.9689C8.16797 12.0404 8.22891 12.0989 8.30339 12.0989H9.32578L9.74388 20.5976C9.77096 21.1517 10.2483 21.5889 10.8255 21.5889H18.5104C19.0893 21.5889 19.565 21.1534 19.5921 20.5976L20.0102 12.0989H21.0326C21.107 12.0989 21.168 12.0404 21.168 11.9689V11.4489C21.168 11.1612 20.9259 10.9289 20.6263 10.9289ZM18.3801 20.4189H10.9559L10.5462 12.0989H18.7897L18.3801 20.4189Z"
        fill="#332E20"
      />
      <path
        d="M21.168 8.08887V1.58887H1.16797V21.5889H7.66797"
        stroke="#332E20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4 4"
      />
    </svg>
  );
};
