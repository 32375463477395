import React, { useState } from "react";

// import 'antd/lib/switch/style/index.css'
// import "antd/lib/switch/style/index.css";
import s from "./SwitchStandart.module.scss";
import { SwitchStandardBase } from "../../BaseComponent/SwitchStandardBase/SwitchStandardBase";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { updateSettings } from "../../../store/slices/settingsUI/settingsUISlice";
import { getValueOption } from "../../../store/selectors/settingsUISelectors";

export const SwitchStandart = ({ ...props }) => {
  const { id, label, defaultValue, values } = props;
  const dispatch = useAppDispatch();
  const selectValue = useAppSelector(getValueOption({idOption: id,}));

  const changeSwitchStandart = (value: boolean) => {
    dispatch(updateSettings([{optionId: id, value: value}]))
  };

  return (
    <div className={s.switch}>
      {label && <label className={s.label}>{ label }</label>}
      <SwitchStandardBase
        checked={!!selectValue}
        onChange={(value: any) => changeSwitchStandart(value)}
      />
    </div>
  );
};
