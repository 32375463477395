import { NODES_THREEKIT, PlaneCabinetsWallT, WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getTHREE, getVector3FromCoordinates } from "../../utils/three/general/getFunctionsTHREE";
import { getPositionCamera } from "../../utils/threekit/general/getFunctions";
import { getNumberNodeThreekitFromName } from "../general";
import { getAllWallsEvalNode } from "./buildWallFromData"

/**
 * З усіх динамічно створених стін шукає ту, яка знаходиться на найбільшій відстані від камери.
 *
 * @return {String} lastWallName, Ім'я стіни з Threekit.
 */
export const getWallInFrontCamera = (): WallItemT => {
  const THREE = getTHREE();
  const allWalls = getAllWallsEvalNode();
  const camWorldPos = getPositionCamera();

  let arrDistance: number[] = [];
  let lastWallName = "";
  
  Object.values(allWalls).forEach((wallEvalNode: any) => {
    const wallPos = new THREE.Vector3().setFromMatrixPosition(wallEvalNode.worldTransform);
    const wallDir = new THREE.Vector3(0,0,1).transformDirection(wallEvalNode.worldTransform);
    //@ts-ignore
    const distanceFromCameraToWall = camWorldPos.sub(wallPos).dot(wallDir);

    // ----------------------------------
    // const vectorCamWorldPos = getVector3FromCoordinates(camWorldPos)
    // const distanceFromCameraToWall = vectorCamWorldPos.distanceTo(wallPos);
    // -------------------------------------

    const wallName = wallEvalNode["name"];
    
    if (arrDistance.length < 1) {
      lastWallName = wallName;
      arrDistance.push(distanceFromCameraToWall);
      return;
    }

    const maxDistance = Math.max.apply(null, arrDistance).toFixed(6);
    if (distanceFromCameraToWall > maxDistance)
      lastWallName = wallName;
    arrDistance.push(distanceFromCameraToWall);
    
  })

  return lastWallName as WallItemT;
}

export const getPlaneNameInFrontCamera = (): PlaneCabinetsWallT => {
  const currentWallName = getWallInFrontCamera();
  const wallNum = getNumberNodeThreekitFromName(currentWallName);
  const currentPlaneName: PlaneCabinetsWallT = `${NODES_THREEKIT.PLANE_CABINETS_WALL}${wallNum}`;
  return currentPlaneName;
}

// 00000000000000000000000000000000000000 --- ====  wall_item_0
// directionFrontVector --- ====  r {x: 0, y: 0, z: 1}

// 00000000000000000000000000000000000000 --- ====  wall_item_1
// directionFrontVector --- ====  r {x: -1, y: 0, z: 0}

// 00000000000000000000000000000000000000 --- ====  wall_item_2
// directionFrontVector --- ====  r {x: 0, y: 0, z: -1}

// 00000000000000000000000000000000000000 --- ====  wall_item_3
// directionFrontVector --- ====  r {x: 1, y: -0, z: 0}

// ==========================================================================================

// 00000000000000000000000000000000000000 --- ====  wall_item_0
// directionFrontVector --- ====  r {x: 0, y: 0, z: 1}

// 00000000000000000000000000000000000000 --- ====  wall_item_1
// directionFrontVector --- ====  r {x: 1, y: 0, z: -0}

// 00000000000000000000000000000000000000 --- ====  wall_item_2
// directionFrontVector --- ====  r {x: 0, y: 0, z: -1}

// 00000000000000000000000000000000000000 --- ====  wall_item_3
// directionFrontVector --- ====  r {x: -1, y: -0, z: -0}