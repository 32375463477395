import { ISceneResult } from "@threekit-tools/treble/dist/types";
import { ModelCabinetWallT, ModelsName_NodesT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getInstanceIdAssetFromNullModel, getItemNodeFromNullModel } from "../../../utils/threekit/general/getFunctions";
import { getСompletedEvalNodeModels } from "./../getNodesCabinets";
import { Dispatch } from "@reduxjs/toolkit";
import { removeModels } from "../../../store/actions/player.action";
import { getDistanceFromCornerEmtyWallBoxToCabinetsWall } from "./distanceBox";
import { checkIfCornerEmptyCabinet } from "./extremePoints";
import { setActiveTagsThreekit } from "../../../utils/threekit/general/setFunctions";
import { CONNECTORS_CABINET_WALL } from "../../../utils/constants/connectors";

export const getEvalNodesCornerEmptyCabinetsWall = (): ISceneResult[] => {
  const allEvalNodeCabinetsWall = getСompletedEvalNodeModels(NODES_THREEKIT.MODEL_CABINET_WALL);
  let arrEvalNodeCornerEmpty: ISceneResult[] = [];
  allEvalNodeCabinetsWall.forEach((evalNode) => {
    const modelItem = getItemNodeFromNullModel({ name: evalNode["name"] });
    if (checkIfCornerEmptyCabinet(modelItem)) {
      arrEvalNodeCornerEmpty.push(evalNode);
    }
  });
  return arrEvalNodeCornerEmpty
}

export const removeCornerEmpty = (
  dispatch: Dispatch,
  nullNameCornerEmpty: ModelsName_NodesT,
) => {

  const instanceIdCornerEmptyCabinetWall = getInstanceIdAssetFromNullModel({
    name: nullNameCornerEmpty
  });
  
  if (instanceIdCornerEmptyCabinetWall === undefined) return;

  setActiveTagsThreekit({
    from: instanceIdCornerEmptyCabinetWall,
    name: CONNECTORS_CABINET_WALL.TOP_LEFT,
    value: false,
  })
  setActiveTagsThreekit({
    from: instanceIdCornerEmptyCabinetWall,
    name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
    value: false,
  })
  setActiveTagsThreekit({
    from: instanceIdCornerEmptyCabinetWall,
    name: CONNECTORS_CABINET_WALL.TOP_RIGHT,
    value: false,
  })
  setActiveTagsThreekit({
    from: instanceIdCornerEmptyCabinetWall,
    name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
    value: false,
  })

  dispatch(removeModels([nullNameCornerEmpty]));

}

export const checkCornersEmptyRemoveCabinetsWall = (dispatch: Dispatch) => {
  const arrEvalNodesCornerEmptyCabinetsWall = getEvalNodesCornerEmptyCabinetsWall();
  arrEvalNodesCornerEmptyCabinetsWall.forEach((evalNodeCornerEmpty) => {
    const arrModelsNamesWithShortDistances = getDistanceFromCornerEmtyWallBoxToCabinetsWall(evalNodeCornerEmpty["name"] as ModelCabinetWallT);
    // if (arrModelsNamesWithShortDistances.length !== 2) {
    if (arrModelsNamesWithShortDistances.length < 2) {
      //@ts-ignore
      const instanceIdCornerEmptyCabinetWall = evalNodeCornerEmpty["Null"]["link"]["Proxy"]["proxy"]["instanceId"];
      setActiveTagsThreekit({
        from: instanceIdCornerEmptyCabinetWall,
        name: CONNECTORS_CABINET_WALL.TOP_LEFT,
        value: false,
      })
      setActiveTagsThreekit({
        from: instanceIdCornerEmptyCabinetWall,
        name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
        value: false,
      })
      setActiveTagsThreekit({
        from: instanceIdCornerEmptyCabinetWall,
        name: CONNECTORS_CABINET_WALL.TOP_RIGHT,
        value: false,
      })
      setActiveTagsThreekit({
        from: instanceIdCornerEmptyCabinetWall,
        name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
        value: false,
      })
      dispatch(removeModels([evalNodeCornerEmpty["name"]]));
    }
  })
}