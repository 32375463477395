import React from "react";
import { useAppSelector } from "../../../../hooks/useStoreHooks";
import { isSinkCabinetFilled } from "../../../../store/selectors/appliancesSelectors";
import { getImgLinkForCategoryGroup, getKeySectionActive, getValueOption } from "../../../../store/selectors/settingsUISelectors";

// scss
import s from "./OptionCategory.module.scss"
import NotImage from "./../../../../assets/images/Cabinets/not_image.png";

export const OptionCategory = ({ ...props }) => {
  const { id, value, label, images, onClick, parentId } = props;

  const imgSrc = useAppSelector(getImgLinkForCategoryGroup(images));

  // *** START Check Disabled Category Group Sink

  // const isSinkCabinet = useAppSelector(isSinkCabinetFilled);
  // const activeSectionKey = useAppSelector(getKeySectionActive);
  // const valueAppliances = useAppSelector(getValueOption({ idOption: "Cabinets Appliances", sectionId: activeSectionKey })) as string[];

  // const chekDisabledOptionSink = (isSinkCabinet: string | undefined, idOptionCategory: string, valueAppliancesSink: string | undefined, activeSectionKey: string | null) => {

  //   if (isSinkCabinet === undefined)
  //     return false;

  //   if (!!activeSectionKey && activeSectionKey === "Appliances") {
  //     const sinkCabinetIdUI = isSinkCabinet;

  //     if (sinkCabinetIdUI === "1011")
  //       return true;

  //     if(idOptionCategory !== valueAppliancesSink)
  //       return false
        
  //   }
    
  //   return true;
  // }

  // const isSinkCategoryGroup = parentId.includes("Sink");
  // let isDisabledOptionSink: boolean = false;
  // if (isSinkCategoryGroup) {
  //   const sinkValueAppliances = !!valueAppliances && valueAppliances.find((value) => value.includes("sink"));
  //   isDisabledOptionSink = chekDisabledOptionSink(isSinkCabinet, id, sinkValueAppliances, activeSectionKey);
  // }

  // Added class for styled optionCategory ${isDisabledOptionSink ? s.disabled : ""}

  // *** END Check Disabled Category Group Sink

  return (
    <div className={`${s.optionCategory}`} onClick={() => onClick(value)}>
      <div className={s.optionCategoryImg}>
        <div className={s.imgWrap}>
          <img src={imgSrc.length < 1 ? NotImage : imgSrc} alt={label} />
        </div>
      </div>
      <p className={s.optionCategoryLabel}>{label}</p>
    </div>
  );
};
