export const ArrowRightSmallSvg = () => {
  return (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29274 0.964463C1.90222 0.573939 1.26905 0.573939 0.878529 0.964463C0.488004 1.35499 0.488004 1.98815 0.878529 2.37868L2.99982 4.49997L0.878502 6.62129C0.487978 7.01182 0.487978 7.64498 0.878502 8.03551C1.26903 8.42603 1.90219 8.42603 2.29272 8.03551L5.11503 5.21319C5.11709 5.21117 5.11913 5.20914 5.12117 5.2071C5.50559 4.82268 5.5116 4.20314 5.13919 3.81137C5.13328 3.80515 5.12727 3.79899 5.12117 3.79289C5.12116 3.79288 5.12115 3.79287 5.12114 3.79286C5.12107 3.79279 5.121 3.79273 5.12094 3.79266L2.29274 0.964463Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
};
