import { ValuesConfigurationT } from "../types/UI/dynamic-rendering.interfaces";
import {
  CategoryOption,
  groupCabinetsOptions,
  PRODUCT_GROUP_ALL_T,
  PRODUCT_POSITIONS_KEYS,
  PRODUCT_SECTION_GROUP_ALL_T,
  PRODUCT_SECTION_GROUP_T,
} from "../utils/constants/cabinets";

export const convertArrToSelectValues = (
  arr: (string | number)[]
): ValuesConfigurationT[] => {
  return arr.map((value: string | number) => {
    return { label: value, value: value };
  });
};

// написати тест + комент !#!
export const getArrGroupsForType = (
  position: PRODUCT_POSITIONS_KEYS,
  type: PRODUCT_SECTION_GROUP_ALL_T
): string[] => {
  let resultArr: string[] = [];
  const objAllGroupsTypes = groupCabinetsOptions[position];
  if (type === "All") {
    Object.values(objAllGroupsTypes).forEach((arrGroups: CategoryOption[]) => {
      const arrCategories = arrGroups.map(
        (category: CategoryOption) => category["value"]
      );
      resultArr = [...resultArr, ...arrCategories];
    });
    return resultArr;
  }

  const objAllGroupsTypesForPosition = objAllGroupsTypes[type];
  if (!!objAllGroupsTypesForPosition) {
    resultArr = objAllGroupsTypesForPosition?.map(
      (category: CategoryOption) => category["value"]
    );
  }
  return resultArr;
};

/**
 * Функція для отримання груп PRODUCT_GROUP_APPLIANCES для APPLIANCES.
 *
 * @returns {string[]} Масив назв груп.
 */
export const getArrGroupsForAppliances = (): string[] => {
  let resultArr: string[] = [];
  const objAllGroupsTypes = groupCabinetsOptions[PRODUCT_POSITIONS_KEYS.APPLIANCES];

  Object.values(objAllGroupsTypes).forEach((arrGroups: CategoryOption[]) => {
    const arrCategories = arrGroups.map(
      (category: CategoryOption) => category["value"]
    );
    resultArr = [...resultArr, ...arrCategories];
  });

  return resultArr;

};

// написати тест + комент !#!
export const getArrTypesFromArrGroups = (
  position: PRODUCT_POSITIONS_KEYS,
  arrGroups: (PRODUCT_GROUP_ALL_T)[]
) => {
  let resultArr: (PRODUCT_SECTION_GROUP_T)[] =
    [];
  const objAllGroupsTypes = groupCabinetsOptions[position];
  const keysObjAllGroupsTypes = Object.keys(objAllGroupsTypes) as Array<
    keyof typeof objAllGroupsTypes
  >;

  keysObjAllGroupsTypes.forEach((key) => {
    objAllGroupsTypes[key]?.forEach((category: CategoryOption) => {
      if (arrGroups.includes(category["value"])) {
        resultArr.push(key);
      }
    });
  });

  return resultArr;
};
