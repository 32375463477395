import { NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getConfiguratorModelFromNullName } from "../configuration/decorativePanel";
import { getModelsBaseNullOnAllWalls, getСompletedModelsNullNames } from "../getNodesCabinets";

/**
 * Функція активізує або вимикає конектори для з'єднання з  Cabinets Island.
 * Для Appliances по замовчуванню ввімкнуті всі конектори: конектори для з'єднання з Cabinets Base та конектори для з'єднання з Cabinets Island.
 * Для Appliances достатньо зробити перевірку на те, що ці моделі розташовуються біля стіни.
 * І в цьому випадку вимкнути конектори, які створені для з'єднання з островними шкафами.
 * Якщо модель Appliances не розташовується біля стіни, то вмикаємо всі конектори для з'єднання з Cabinets Base та Cabinets Island.
 *
 * @return Оновлює значення булувого атрибуту isVisibleConnectorsCabinetsIsland.
 */
export const checkAppliancesConnectors = () => {
  
  // Можна використати функцію для отримання сусідніх моделей getExtremeModelsAppliances
  // для того щов визначити біля яких моделей знаходиться модель Appliance

  const allСompletedNullForAppliances = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_APPLIANCES
  );
  const modelsBaseNullOnAllWalls = getModelsBaseNullOnAllWalls();

  allСompletedNullForAppliances.forEach((nameAppliance) => {

    const configuratorModel = getConfiguratorModelFromNullName(nameAppliance);
    let isConnectorsIsland = true;

    if (modelsBaseNullOnAllWalls.includes(nameAppliance)) {
      // Вимикаємо конектори для з'єднання з Cabinets Island
      isConnectorsIsland = false
    } else {
      // Вмикаємо конектори для з'єднання з Cabinets Island
      isConnectorsIsland = true
    }

    configuratorModel.setConfiguration({
      isVisibleConnectorsCabinetsIsland: isConnectorsIsland,
    });

  })
}