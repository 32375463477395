import { ProductArrT, PRODUCT_POSITIONS_KEYS } from "../../utils/constants/cabinets";
type ProductInfoByPositionT = {
  [key in PRODUCT_POSITIONS_KEYS]?: ProductArrT;
}
export const getProductInfoFilteredByPosition = (products: ProductArrT): ProductInfoByPositionT => {
  let resultObj: ProductInfoByPositionT = {};
  products.forEach((objProduct) => {
    if (!resultObj.hasOwnProperty(objProduct["position"]))
      return resultObj[objProduct["position"]] = [ objProduct ];
    resultObj[objProduct["position"]]?.push(objProduct);
  })
  return resultObj;
}