export const InfoCircleSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.125C3.65117 0.125 0.125 3.65117 0.125 8C0.125 12.3488 3.65117 15.875 8 15.875C12.3488 15.875 15.875 12.3488 15.875 8C15.875 3.65117 12.3488 0.125 8 0.125ZM8 14.5391C4.38945 14.5391 1.46094 11.6105 1.46094 8C1.46094 4.38945 4.38945 1.46094 8 1.46094C11.6105 1.46094 14.5391 4.38945 14.5391 8C14.5391 11.6105 11.6105 14.5391 8 14.5391Z"
        fill="#1F2944"
      />
      <path
        d="M7.15598 4.90625C7.15598 5.13003 7.24487 5.34464 7.4031 5.50287C7.56134 5.66111 7.77595 5.75 7.99973 5.75C8.2235 5.75 8.43811 5.66111 8.59635 5.50287C8.75458 5.34464 8.84348 5.13003 8.84348 4.90625C8.84348 4.68247 8.75458 4.46786 8.59635 4.30963C8.43811 4.15139 8.2235 4.0625 7.99973 4.0625C7.77595 4.0625 7.56134 4.15139 7.4031 4.30963C7.24487 4.46786 7.15598 4.68247 7.15598 4.90625ZM8.4216 6.875H7.57785C7.50051 6.875 7.43723 6.93828 7.43723 7.01562V11.7969C7.43723 11.8742 7.50051 11.9375 7.57785 11.9375H8.4216C8.49894 11.9375 8.56223 11.8742 8.56223 11.7969V7.01562C8.56223 6.93828 8.49894 6.875 8.4216 6.875Z"
        fill="#1F2944"
      />
    </svg>
  );
};
