export const EditSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#E5E5E5" />
      <g id="Desktop - 50" clipPath="url(#clip0_536_240)">
        <rect
          width="1440"
          height="1024"
          transform="translate(-658 -959)"
          fill="white"
        />
        <g id="Frame 41"></g>
        <g id="Frame 445">
          <rect x="-20" y="-17" width="374" height="58" rx="2" fill="#EFF4F9" />
          <g id="edit" clipPath="url(#clip1_536_240)">
            <path
              id="Vector"
              d="M3 24H17C17.7956 24 18.5587 23.6839 19.1213 23.1213C19.6839 22.5587 20 21.7956 20 21V13.48C20 13.2148 19.8946 12.9604 19.7071 12.7729C19.5196 12.5854 19.2652 12.48 19 12.48C18.7348 12.48 18.4804 12.5854 18.2929 12.7729C18.1054 12.9604 18 13.2148 18 13.48V21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H11.52C11.7852 5 12.0396 4.89464 12.2271 4.70711C12.4146 4.51957 12.52 4.26522 12.52 4C12.52 3.73478 12.4146 3.48043 12.2271 3.29289C12.0396 3.10536 11.7852 3 11.52 3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6L0 21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24Z"
              fill="#31344B"
            />
            <path
              id="Vector_2"
              d="M21.0758 0.0109952C20.7023 -0.0259141 20.3253 0.0195171 19.9712 0.144088C19.6172 0.26866 19.2948 0.469341 19.0268 0.731995L10.0998 9.655C9.94059 9.81335 9.83977 10.021 9.81377 10.244L9.43277 13.453C9.4161 13.5933 9.42934 13.7355 9.47163 13.8703C9.51392 14.0051 9.58429 14.1294 9.67811 14.2351C9.77193 14.3407 9.88707 14.4253 10.0159 14.4832C10.1448 14.5411 10.2845 14.571 10.4258 14.571C10.4652 14.5711 10.5046 14.5688 10.5438 14.564L13.7528 14.183C13.9758 14.157 14.1834 14.0562 14.3418 13.897L23.2648 4.975C23.5269 4.70634 23.7272 4.38372 23.8517 4.02964C23.9763 3.67557 24.022 3.29858 23.9858 2.925C23.9172 2.17689 23.5859 1.47722 23.0508 0.949995C22.5241 0.413355 21.8244 0.0806696 21.0758 0.0109952ZM21.8548 3.56L13.1768 12.238L11.5718 12.428L11.7628 10.828L20.4418 2.15C20.7668 1.821 21.3648 2.092 21.6358 2.36C21.9068 2.628 22.1858 3.229 21.8548 3.56Z"
              fill="#31344B"
            />
          </g>
        </g>
      </g>
      <g id="Group 14">
        <rect
          id="Rectangle 1831"
          x="-692"
          y="-967"
          width="1508"
          height="1071.2"
          rx="18"
          stroke="#7F986C"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="54 54"
        />
      </g>
      <defs>
        <clipPath id="clip0_536_240">
          <rect
            width="1440"
            height="1024"
            fill="white"
            transform="translate(-658 -959)"
          />
        </clipPath>
        <clipPath id="clip1_536_240">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
