import { NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { getIdNodeThreekit } from "../../utils/threekit/general/getFunctions";
import { selectSetThreekit } from "../../utils/threekit/general/selection";
import { getСompletedModelsNullNames } from "./getNodesCabinets";

export const selectLastModel = (
  regexpModel: NODES_THREEKIT
) => {
  const completedNullModels = getСompletedModelsNullNames(regexpModel);
  const lastModelId = getIdNodeThreekit({name: completedNullModels[completedNullModels.length - 1]});
  selectSetThreekit(lastModelId);
}