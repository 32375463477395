import { PRODUCT_GROUP_APPLIANCES } from "../utils/constants/cabinets"

export const isProductGroupAppliances = (idUI: string) => {
  return Object.values(PRODUCT_GROUP_APPLIANCES).includes(idUI as PRODUCT_GROUP_APPLIANCES);
}

export type SinkSizeT = "sink 25" | "sink 33";
export type CabinetSinkIdT = "1011" | "1015";
const objRelativeSinkSizeCabinetSink: {[key in SinkSizeT]: CabinetSinkIdT} = {
  "sink 25": "1011",
  "sink 33": "1015"
}
export const getCabinetSinkIdFromSinkSize = (sinkSize: SinkSizeT): CabinetSinkIdT => {
  return objRelativeSinkSizeCabinetSink[sinkSize]
}
export const getSinkSizeFromCabinetSinkId = (cabinetId: CabinetSinkIdT) => {
  const sinkSizeKeys = Object.keys(objRelativeSinkSizeCabinetSink) as Array<
    keyof typeof objRelativeSinkSizeCabinetSink
  >;
  return sinkSizeKeys.find((sinkSize) => {
    return objRelativeSinkSizeCabinetSink[sinkSize] === cabinetId
  })
}

export const replaceValuesForAppliances = (arr1: string[], arr2: string[]) => {
  const resultArr = arr1.map(value => {
    const matchingValue = arr2.find(val => val.startsWith(value.split(" ")[0]));
    return matchingValue ? matchingValue : value;
  });
  return resultArr;
}