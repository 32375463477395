import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { getFullConfiguration } from "../../utils/threekit/general/getFunctions"

export const getConfiguration2D = () => {
  const configuration = getFullConfiguration();
  return {
    [ATTRIBUTES_NAMES_THREEKIT.WALLS]: configuration[ATTRIBUTES_NAMES_THREEKIT.WALLS],
    [ATTRIBUTES_NAMES_THREEKIT.WINDOWS]: configuration[ATTRIBUTES_NAMES_THREEKIT.WINDOWS],
    [ATTRIBUTES_NAMES_THREEKIT.DOORS]: configuration[ATTRIBUTES_NAMES_THREEKIT.DOORS],
    [ATTRIBUTES_NAMES_THREEKIT.OPENINGS]: configuration[ATTRIBUTES_NAMES_THREEKIT.OPENINGS],
  }
}