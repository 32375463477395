export const CloneCabinetSvg = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9921 5.01277C15.7348 5.01046 16.3388 5.6107 16.3411 6.35345L16.3695 15.4687C16.3719 16.2114 15.7716 16.8154 15.0289 16.8177L5.91364 16.8461C5.17089 16.8484 4.5669 16.2482 4.56459 15.5054L4.56082 14.297C4.55967 13.9256 4.85979 13.6236 5.23116 13.6224C5.60254 13.6213 5.90453 13.9214 5.90569 14.2928L5.90945 15.5012L15.0247 15.4729L14.9963 6.35764L13.7717 6.36145C13.4003 6.36261 13.0983 6.06249 13.0972 5.69111C13.096 5.31974 13.3961 5.01774 13.7675 5.01658L14.9921 5.01277ZM10.4952 0.543838C11.238 0.541525 11.842 1.14177 11.8443 1.88452L11.8727 10.9997C11.875 11.7425 11.2748 12.3465 10.532 12.3488L1.41679 12.3772C0.67404 12.3795 0.070047 11.7792 0.0677338 11.0365L0.0393448 1.92128C0.0370315 1.17853 0.637274 0.574541 1.38002 0.572227L10.4952 0.543838ZM10.4994 1.88871L1.38421 1.91709L1.4126 11.0323L10.5278 11.0039L10.4994 1.88871ZM5.94671 3.47191C6.35934 3.47063 6.6949 3.8041 6.69618 4.21673L6.70009 5.71029L8.19513 5.70638C8.60777 5.70509 8.94332 6.03856 8.94461 6.4512C8.94589 6.86384 8.61243 7.19939 8.19979 7.20068L6.70474 7.20458L6.71014 8.69963C6.71143 9.11227 6.37796 9.44782 5.96532 9.4491C5.55268 9.45039 5.21713 9.11692 5.21585 8.70428L5.21044 7.20924L3.71689 7.21464C3.30426 7.21592 2.9687 6.88245 2.96742 6.46982C2.96613 6.05718 3.2996 5.72162 3.71224 5.72034L5.20579 5.71494L5.20188 4.22139C5.2006 3.80875 5.53407 3.4732 5.94671 3.47191Z"
        fill="#332E20"
      />
    </svg>
  );
};
