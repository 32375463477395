import React, { useState, useEffect, useRef } from "react";
import { InputNumberMinusSvg } from "../../../assets/images/svg/InputNumberMinus";
import { InputNumberPlusSvg } from "../../../assets/images/svg/InputNumberPlus";
import { getToFixed, roundToTwo } from "../../../utils/other/numberData";

// scss
import s from "./InputNumber.module.scss";
import { useDebouncedFunction } from "../../../hooks/useDebouncedFunction";

type InputNumberT = {
  valueDefault?: any;
  min?: number;
  max?: number;
  onCountChange?: any;
  controls?: {
    upIcon: any;
    downIcon: any;
  };
  step?: number;
  unit?: string;
  readOnly?: boolean;
};

export const InputNumber = ({ ...props }: InputNumberT) => {
  const {
    valueDefault = 0,
    min = -100,
    max = 100,
    onCountChange,
    controls,
    step = 1,
    unit,
    readOnly,
  } = props;

  const [value, setInputValue] = useState<string | number>(roundToTwo(valueDefault));
  const toFixed = getToFixed(step);

  const decrement = () => {
    let newValue = Number((Number(value) - step).toFixed(toFixed));
    if (newValue < min) return;
    onCountChange(newValue);
    setInputValue(newValue);
  };

  const increment = () => {
    let newValue = Number((Number(value) + step).toFixed(toFixed));
    if (newValue > max) return;
    onCountChange(newValue);
    setInputValue(newValue);
  };

  const handleChangeInput = (value: string) => {
    let newValue = parseFloat(value);
    if (
      onCountChange !== undefined &&
      typeof newValue === "number" &&
      !Number.isNaN(newValue)
    ) {
      if (newValue < min) {
        newValue = min;
      } else if (newValue > max) {
        newValue = max;
      }
      onCountChange(newValue);
    }
  };

  const debouncedValueDispatching = useDebouncedFunction(
    handleChangeInput,
    500
  );

  function onChangeNumberValue(event: any) {
    const numericValue = event.target.value.replace(/,+/g, '.').replace(/[^0-9.]/g, '');
    setInputValue(numericValue);
    debouncedValueDispatching(numericValue);
  }

  // useEffect(() => {
  //   setInputValue(valueDefault);
  // }, [valueDefault]);

  return (
    <div className={s.wrapperInputNumber}>
      <div
        className={`${s.btnInputNumber} ${s.btnInputNumberDown} ${
          value === min ? s.disabled : ""
        }`}
        onClick={decrement}
      >
        {!!controls && !!controls["downIcon"] ? (
          controls["downIcon"]
        ) : (
          <InputNumberMinusSvg />
        )}
      </div>

      <input
        className={s.inputStyle}
        type="text"
        value={value}
        onChange={onChangeNumberValue}
        step={0.1}
        readOnly={!!readOnly ? readOnly : false}
      />

      {!!unit && (
        <div className={s.unitVisible}>
          <span className={s.unitHiddenValue}>{value}</span>
          {unit}
        </div>
      )}
      {!!unit && <div className={s.unitHidden}>{unit}</div>}

      <div
        className={`${s.btnInputNumber} ${s.btnInputNumberUp} ${
          value === max ? s.disabled : ""
        }`}
        onClick={increment}
      >
        {!!controls && !!controls["upIcon"] ? (
          controls["upIcon"]
        ) : (
          <InputNumberPlusSvg />
        )}
      </div>
    </div>
  );
};
