import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getIsShownModals } from "../../../store/selectors/modalsSelectors";
import {
  closeAllModals,
  setModalVisible,
} from "../../../store/slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import s from "./ModalsWrap.module.scss";

export const ModalsWrap = ({ ...props }) => {
  const { children, modalKey, zIndex = 1001 } = props;
  const isShownModals = useAppSelector((state) => getIsShownModals(state));
  const dispatch = useAppDispatch();

  const handleClickToModalsWrap = (e: React.MouseEvent<HTMLElement>) => {

    const target = e.target as Element;

    if (
      !!modalKey &&
      target["localName"] === "div" &&
      target["className"].includes("modalsWrap")
    ) {
      return dispatch(setModalVisible({ modalId: modalKey, value: false }));
    }

    if (
      target.children[0] &&
      target.children[0].className &&
      target.children[0].className.length > 0 &&
      target.children[0].className.includes("modalHelp3D")
    ) {
      dispatch(setModalVisible({ modalId: MODAL_KEYS.HELP_3D, value: false }));
      return;
    }

    if (
      target["localName"] === "div" &&
      target["className"].includes("modalsWrap")
    ) {
      dispatch(closeAllModals());
    }
  };

  if (!isShownModals) return null;

  return (
    <div
      className={s.modalsWrap}
      onClick={(e) => handleClickToModalsWrap(e)}
      onMouseUp={(e) => e.stopPropagation()}
      style={{zIndex: zIndex,}}
    >
      {children}
    </div>
  );
};