import { useState, useEffect, useRef } from "react";
import { useSpaces } from "@threekit-tools/treble";

// scss
import s from "./TestPage.module.scss";
import useWindowSize from "../../hooks/useWindowSize";
import { IUnits } from "@threekit-tools/treble/dist/hooks/useSpaces/types";

export const TestPage = () => {
  const dataRoomBuilder = useSpaces({
    config: {
      unit: IUnits.METER,
      zoomDefault: 20,
      translateDefault: [4, 4],
      zoomSensitivity: 0.8,
    },
    attributes: {
      walls: "Walls",
      windows: "Windows",
      doors: "Doors",
      openings: "Openings",
    },
    //@ts-ignore
    validators: [],
  });

  const {
    canvasRef,

    //@ts-ignore
    wallMode,
    mode,
  } = dataRoomBuilder;

  const windowSize = useWindowSize();

  const player2DWrap = useRef<HTMLDivElement>(null);
  const [player2DWrapSize, setPlayer2DWrapSize] = useState<any>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    setPlayer2DWrapSize({
      width: player2DWrap["current"]?.clientWidth,
      height: player2DWrap["current"]?.clientHeight,
    });
  }, [windowSize["width"], windowSize["height"]]);

  useEffect(() => {
    console.log("dataRoomBuilder", dataRoomBuilder);
  }, [dataRoomBuilder]);

  return (
    <div className={s.pageTestGrid}>
      <aside className={s.mainTestSidebar}>
        <h3>Sidebar</h3>
        <main>
          <div className="">
            {mode.options.map((el: any) => {
              return (
                <>
                  <button
                    style={{ background: el.selected ? "blue" : "white" }}
                    onClick={() => el.handleSelect()}
                  >
                    {el.label}
                  </button>
                  <span> </span>
                </>
              );
            })}
          </div>
          <br />
          <div className="">
            {wallMode &&
              wallMode.options &&
              wallMode.options.map((el: any) => (
                <>
                  <button
                    style={{ background: el.selected ? "blue" : "white" }}
                    onClick={() => el.handleSelect()}
                  >
                    {el.label}
                  </button>
                  <span> </span>
                </>
              ))}
          </div>
        </main>
      </aside>
      <div className={s.sceneTestContent} ref={player2DWrap}>
        <canvas
          ref={canvasRef}
          width={player2DWrapSize["width"]}
          height={player2DWrapSize["height"]}
        />
      </div>
    </div>
  );
};
