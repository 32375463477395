import { NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { getAllWallsNode } from "./buildWallFromData";

export const getAllWallsPlaneCabinetsWall = () => {
  //@ts-ignore
  return window.threekit.player.scene.getAll({
    name: `${NODES_THREEKIT.PLANE_CABINETS_WALL}*`,
  });
};

export const getAllWallsPlaneBoundingWall = () => {
  //@ts-ignore
  return window.threekit.player.scene.getAll({
    name: `${NODES_THREEKIT.PLANE_BOUNDING_WALL}*`,
  });
};

export const getAllWallsPlaneBoundingCeiling = () => {
  //@ts-ignore
  return window.threekit.player.scene.getAll({
    name: `${NODES_THREEKIT.PLANE_BOUNDING_CEILING}*`,
  });
};

export const deleteAllWallsFrom3D = () => {
  const allWallsNode = getAllWallsNode();
  const allWallsPlaneCabinetsWall = getAllWallsPlaneCabinetsWall();
  const allWallsPlaneBoundingWall = getAllWallsPlaneBoundingWall();
  const allWallsPlaneBoundingCeiling = getAllWallsPlaneBoundingCeiling();
  Object.keys(allWallsNode).forEach((idNodeWall) => {
    //@ts-ignore
    window.threekit.player.scene.deleteNode(idNodeWall);
  })
  Object.keys(allWallsPlaneCabinetsWall).forEach((idNodeWall) => {
    //@ts-ignore
    window.threekit.player.scene.deleteNode(idNodeWall);
  })
  Object.keys(allWallsPlaneBoundingWall).forEach((idNodeWall) => {
    //@ts-ignore
    window.threekit.player.scene.deleteNode(idNodeWall);
  })
  Object.keys(allWallsPlaneBoundingCeiling).forEach((idNodeWall) => {
    //@ts-ignore
    window.threekit.player.scene.deleteNode(idNodeWall);
  })
}