import React from "react";
import s from "./InputNumberSize.module.scss";
import { ArrowRightSmallSvg } from "../../../assets/images/svg/ArrowRightSmall";
import { ArrowLeftSmallSvg } from "../../../assets/images/svg/ArrowLeftSmall";
import { InputNumber } from "../../BaseComponent/InputNumber/InputNumber";
import { useAttribute, useConfigurator } from "@threekit-tools/treble/dist";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { ATTRIBUTE_NAMES } from "../../../utils/constants/attributesThreekitRoomBuilder";
import { getNewConfigurationLengthWalls } from "../../../functionsConfigurator/configurator2D/wallLength";
import { useAppSelector } from "../../../hooks/useStoreHooks";
import { getMeasurement } from "../../../store/selectors/settingsUISelectors";
import { IUnits } from "@threekit-tools/treble/dist/hooks/useSpaces/types";
import InputFeetAndInches from "../InputFeetAndInches/InputFeetAndInches";

export const InputNumberSize = ({ ...props }) => {
  const { id, label, min, max, step, defaultValue, path, attributeNameNode, setAttribute } =
    props;
  const [attributeCurrent, setAttributeCurrent] =
    useAttribute(attributeNameNode);
  const [attributeHeight, setAttributeHeight] = useAttribute(
    ATTRIBUTE_NAMES.wallHeight
  );
  const [attributes, setConfiguration] = useConfigurator();
  const [attributeWalls, setAttributeWalls] = useAttribute(
    ATTRIBUTES_NAMES_THREEKIT.WALLS
  );
  const [attributeWindows, setAttributeWindows] = useAttribute(
    ATTRIBUTES_NAMES_THREEKIT.WINDOWS
  );
  const [attributeDoors, setAttributeDoors] = useAttribute(
    ATTRIBUTES_NAMES_THREEKIT.DOORS
  );
  const [attributeOpenings, setAttributeOpenings] = useAttribute(
    ATTRIBUTES_NAMES_THREEKIT.OPENINGS
  );
  const measurementValue = useAppSelector(getMeasurement);

  const changeInputNumberCount = (value: number | string | null) => {
    if (id === "WallHeightGlobal" && !!attributeHeight) {
      return setAttributeHeight(value as number);
    }

    if (!!path) {
      switch (path[0]) {
        case "Walls":
          if (!!id && id === "wallLength") {
            if (!!attributeWalls) {
              const newValuesAttributeWall = getNewConfigurationLengthWalls(
                attributeWalls,
                path,
                Number(value)
              );
              setAttributeWalls(newValuesAttributeWall);
            }
            return;
          }

          if (
            attributeNameNode === ATTRIBUTE_NAMES.wallHeight &&
            !!attributeCurrent
          ) {
            if (typeof value === "number") {
              return setAttributeCurrent(value);
            }
          }

          if (!!attributes) {
            const wallAttributeValue = JSON.parse(
              JSON.stringify(
                attributes[ATTRIBUTES_NAMES_THREEKIT.WALLS]["value"]
              )
            );
            // const configurationCurrentWall = wallAttributeValue[path[1]]["configuration"];
            // const wallLockThickness = configurationCurrentWall[ATTRIBUTE_NAMES.wallLockThickness];
            const newValueAttributeWall = wallAttributeValue.map(
              (attributeValue: any, indx: number) => {
                if (indx === path[1]) {
                  return {
                    ...attributeValue,
                    configuration: {
                      ...attributeValue["configuration"],
                      [ATTRIBUTE_NAMES.wallThicknessLocal]: value,
                      [ATTRIBUTE_NAMES.wallLockThickness]: false,
                    },
                  };
                }
                return attributeValue;
              }
            );
            const objAllConfiguration = {
              [ATTRIBUTES_NAMES_THREEKIT.WALLS]: newValueAttributeWall,
            };
            setConfiguration(objAllConfiguration);
          }

          break;
        case "Windows":

          // bug from useNestedConfigurator
          if (
            (attributeNameNode.includes("featureWidth") ||
              attributeNameNode.includes("featureHeight") ||
              attributeNameNode.includes("offsetY")) &&
            !!attributeWindows
          ) {
            const newValueAttributeWindows = JSON.parse(
              JSON.stringify(attributeWindows["value"])
            ).map((attributeValue: any, indx: number) => {
              if (indx === path[1]) {
                return {
                  ...attributeValue,
                  configuration: {
                    ...attributeValue["configuration"],
                    [attributeNameNode]: value,
                  },
                };
              }
              return attributeValue;
            });
            return setAttributeWindows(newValueAttributeWindows);
          }

          if (!!setAttribute) {
            setAttribute(value);
          }

          break;
        case "Doors":

          // bug from useNestedConfigurator
          if (attributeNameNode.includes("featureWidth") && !!attributeDoors) {
            const newValueAttributeDoors = JSON.parse(
              JSON.stringify(attributeDoors["value"])
            ).map((attributeValue: any, indx: number) => {
              if (indx === path[1]) {
                return {
                  ...attributeValue,
                  configuration: {
                    ...attributeValue["configuration"],
                    [attributeNameNode]: value,
                  },
                };
              }
              return attributeValue;
            });
            return setAttributeDoors(newValueAttributeDoors);
          }

          if (!!setAttribute) {
            setAttribute(value);
          }

          break;
        case "Openings":

          // bug from useNestedConfigurator
          if (
            (attributeNameNode.includes("featureWidth") ||
              attributeNameNode.includes("featureHeight")) &&
            !!attributeOpenings
          ) {
            const newValueAttributeOpenings = JSON.parse(
              JSON.stringify(attributeOpenings["value"])
            ).map((attributeValue: any, indx: number) => {
              if (indx === path[1]) {
                return {
                  ...attributeValue,
                  configuration: {
                    ...attributeValue["configuration"],
                    [attributeNameNode]: value,
                  },
                };
              }
              return attributeValue;
            });
            return setAttributeOpenings(newValueAttributeOpenings);
          }

          if (!!setAttribute) {
            setAttribute(value);
          }

          break;
        default:
          break;
      }
    }
  };

  const getDefaultValue = () => {
    if (id === "WallHeightGlobal" && !!defaultValue) {
      return !!attributeHeight ? attributeHeight["value"] : defaultValue;
    }
    return !!defaultValue ? defaultValue : 1;
  };

  return (
    <div className={s.inputNumberSize}>
      {label && <div className={s.inputNumberSizeLabel}>{label}</div>}
      {measurementValue === IUnits.METER && (
        <InputNumber
          min={!!min ? min : 1}
          max={!!max ? max : 10}
          valueDefault={getDefaultValue()}
          onCountChange={(value: number) => changeInputNumberCount(value)}
          controls={{
            upIcon: <ArrowRightSmallSvg />,
            downIcon: <ArrowLeftSmallSvg />,
          }}
          step={step ? step : 0.1}
          unit="m"
          readOnly={false}
        />
      )}

      {measurementValue === IUnits.FEET && (
        <InputFeetAndInches
          valueDefault={getDefaultValue()}
          onCountChange={(value) => changeInputNumberCount(value)}
        />
      )}

      {/* <StyledInputNumberSize
        min={0}
        max={10}
        defaultValue={2.5}
        onChange={(value) => changeInputNumberCount(value)}
        controls={{
          upIcon: <ArrowRightSmallSvg />,
          downIcon: <ArrowLeftSmallSvg />,
        }}
        step="0.1"
        formatter={(value) => `${value}m`}
      /> */}
    </div>
  );
};
