import { ModelCabinetWallT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getVector3FromCoordinates } from "../../../utils/three/general/getFunctionsTHREE";
import { getBoundingBoxEvalNode, getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { getСompletedModelsNullNames } from "./../getNodesCabinets";
import { getSizeModelBoxFromAssetCabinetWall } from "./size";

export type DistanceFromCabinetWallBoxT = {
  [key in ModelCabinetWallT]: number;
}
export const getDistanceFromCabinetWallBoxToCabinetsWall = (nullNameModel: ModelCabinetWallT): DistanceFromCabinetWallBoxT => {
  const boundingBoxCurrentModel = getBoundingBoxEvalNode(nullNameModel);
  const allСompletedNullNameCabinetsWall = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);

  let objDistanceFromModelBoxToModelsNull: DistanceFromCabinetWallBoxT = {};

  allСompletedNullNameCabinetsWall.forEach((nullName) => {
    if (nullName !== nullNameModel) {
      const modelSize = getSizeModelBoxFromAssetCabinetWall(nullName);
      const position = getTranslationThreekit({name: nullName})
      const positionVector = getVector3FromCoordinates(position)
      const distance = boundingBoxCurrentModel.distanceToPoint(positionVector);

      const distanceX = Math.abs(distance - modelSize["x"]/2);
      const distanceY = Math.abs(distance - modelSize["y"]);

      objDistanceFromModelBoxToModelsNull[nullName as ModelCabinetWallT] = Math.min(distanceX, distanceY);

    }
  })

  return objDistanceFromModelBoxToModelsNull;
}

export const getDistanceFromCornerEmtyWallBoxToCabinetsWall = (nullNameModel: ModelCabinetWallT): ModelCabinetWallT[] => {
  const arrDistanceFromCabinetBaseBoxToCabinetsBase = getDistanceFromCabinetWallBoxToCabinetsWall(nullNameModel);
  const distanceKeys = Object.keys(
    arrDistanceFromCabinetBaseBoxToCabinetsBase
  ) as Array<keyof typeof arrDistanceFromCabinetBaseBoxToCabinetsBase>;
  let arrModelsNamesWithShortDistances: ModelCabinetWallT[] = [];
  distanceKeys.forEach((nullName) => {
    if (arrDistanceFromCabinetBaseBoxToCabinetsBase[nullName] < 0.1)
      arrModelsNamesWithShortDistances.push(nullName);
  })
  return arrModelsNamesWithShortDistances;
}