import { HistoryInterfaceI, HistoryListT } from "../slices/history/historySliceT";
import { RootState } from "../store";

export const getHistoryFull2D = (state: RootState): HistoryListT => {
  return state["history"]["present"]["history2D"];
};

export const getHistoryLatest2D = (state: RootState): HistoryInterfaceI => {
  const historyPresent2D = state["history"]["present"]["history2D"];
  return historyPresent2D[historyPresent2D.length - 1];
};