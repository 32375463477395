import { PRIVATE_APIS } from "@threekit-tools/treble/dist/types";
import { isEqual, pickBy } from "lodash";
import { updateWallsMaterial } from "../../functionsConfigurator/wallsAndFloor/updateWallsMaterial";
import { getConfigurationThreekit } from "./general/getFunctions";

/**
 * Встановлює обробник події зміни конфігурації на сцені Threekit.
 * Слідкує за зміною атрибутів Threekit.
 *
 */
export const runTrackingConfigurationChanges = () => {

  const privateConfig = window.threekit.player.enableApi("player" as PRIVATE_APIS.PLAYER).getConfigurator();

  //@ts-ignore
  privateConfig.on("setConfiguration", (config: any) => {


    // todo винести в окрему ф-цію / test !#
    const configuration = getConfigurationThreekit();
    //@ts-ignore
    let prevConfiguration = window.threekit.player.player.configurator.previousConfiguration;

    // todo винести в окрему ф-цію / test !#!
    let diff: any = pickBy(
      prevConfiguration,
      (v, k) => !isEqual(configuration[k], v)
    );

    if (diff.hasOwnProperty('Wall material')) {
      updateWallsMaterial(configuration, prevConfiguration)
    }

  });
};