import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getModalInfo } from "../../../store/selectors/modalsSelectors";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { ModalsWrap } from "../ModalsWrap/ModalsWrap";
import s from "./ModalRemoveAllCabinets.module.scss";
import { removeAllModels } from "../../../store/actions/player.action";

export const ModalRemoveAllCabinets = ({ ...props }) => {
  const modalInfo = useAppSelector((state) => getModalInfo(state)(MODAL_KEYS.DELETE_ALL_CABINETS));
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.DELETE_ALL_CABINETS, value: false}))
  }

  const handleContinue = () => {
    dispatch(removeAllModels());
    handleCloseModal();
  }

  if (!modalInfo["isVisible"]) return null;

  return (
    <ModalsWrap modalKey={MODAL_KEYS.CONTINUE_2D}>
      <div className={s.modalContinue2D}>
        <p className={s.title}>Are you sure you want to delete all of your cabinets.</p>
        <p className={s.noteText}>Delete anyway?</p>
        <div className={s.btnsWrap}>
          <div className={s.col}>
            <ButtonMain text="Cancel" onClick={handleCloseModal} color="white" size="bigSuper" fullWidth={true} />
          </div>
          <div className={s.col}>
            <ButtonMain text="Continue" onClick={handleContinue} color="blueDark" size="bigSuper" fullWidth={true} bold={true} />
          </div>
        </div>
      </div>
    </ModalsWrap>
  )
}