import { activeOptionT, listActiveOption, listActiveSettingT } from "../store/slices/settingsUI/typesSettingsUISlice";
import { IComponent } from "../types/UI/dynamic-rendering.interfaces";
import { settingConfigT } from "../utils/constants/settingConfig3D";

// установка активных значений стейта з обновлених парметров конфигуратора
export const setItemActiveSetting =
  (
    listOptionsObj: listActiveOption,
    oldListOptionsObj: listActiveOption,
  ) =>
    (SectionID: any) =>
      (settingUI: IComponent) => {
        if (
          !!settingUI["data"]["embeddedView"] &&
          settingUI["data"]["embeddedView"].length > 0
        ) {
          let activeOption: any = checkCreateActiveOption({
            SectionID,
            settingUI,
            oldListOptionsObj,
          });

          if (!!activeOption) {
            listOptionsObj[activeOption["optionId"]] = activeOption;
          }

          settingUI["data"]["embeddedView"].forEach((settingUI: IComponent) => {
            setItemActiveSetting(
              listOptionsObj,
              oldListOptionsObj,
            )(SectionID)(settingUI);
          });
        } else {
          let activeOption = checkCreateActiveOption({
            SectionID,
            settingUI,
            oldListOptionsObj,
          });

          if (!!activeOption) {
            listOptionsObj[activeOption["optionId"]] = activeOption;
          }
        }

        function checkCreateActiveOption({
          settingUI,
        }: {
          SectionID: any;
          settingUI: IComponent;
          oldListOptionsObj: listActiveOption;
        }): any {
          if (!!settingUI["data"]["isVisible"]) {
          } else if (!!settingUI["data"]["switchingOptions"]) {
            return createActiveOption(settingUI, oldListOptionsObj);
          } else if (settingUI["data"]["typeOptions"] === "configurationOptions") {
            return createActiveOption(settingUI, oldListOptionsObj);
          }
        }

        function createActiveOption(
          settingUI: IComponent,
          oldListOptionsObj: listActiveOption
        ): activeOptionT {

          // if (settingUI["type"] === "Tabs") {
          //   return {
          //     sectionId: SectionID,
          //     optionId: settingUI["data"]["id"],
          //     settingUI: settingUI["data"]["typeOptions"],
          //     value: settingUI["data"]["value"],
          //     isError: isError,
          //     visibilityState: settingUI["data"]["visibilityState"],
          //   };
          // }

          return {
            sectionId: SectionID,
            optionId: settingUI["data"]["id"],
            settingUI: settingUI["data"]["typeOptions"],
            value: settingUI["data"]["defaultValue"],
          };
        }
      };

type createArrayActiveSettingT = {
  settingUIState: settingConfigT[];
  oldActiveSetting?: listActiveSettingT[];
};

// написати тест + комент для двух ф-цій в цьому файлі !#!
export const createArrayActiveSetting = ({
  settingUIState,
  oldActiveSetting,
}: createArrayActiveSettingT): listActiveSettingT[] => {
  let arrayUISettingTemp: listActiveSettingT[] = [];

  settingUIState.forEach((section: settingConfigT) => {
    let listOptionsObj: listActiveOption = {};

    section["setting"].forEach((settingUI: IComponent) => {
      let oldListOptionsObj = {};
      if (oldActiveSetting) {
        const oldSettingSection: listActiveSettingT = oldActiveSetting.filter(
          (sectionActive) => sectionActive["sectionId"] === section["key"]
        )[0];
        oldListOptionsObj = oldSettingSection["listOptionsObj"];
      }

      setItemActiveSetting(
        listOptionsObj,
        oldListOptionsObj,
      )(section["key"])(settingUI);
    });

    arrayUISettingTemp.push({
      sectionId: section["key"],
      listOptionsObj: listOptionsObj,
    });
  });

  return arrayUISettingTemp;
};