import { getAttributesThreekit, getFullConfiguration, getSceneAssetId } from "../../utils/threekit/general/getFunctions";

const CONFIGURATIONS_API_ROUTE = `api/configurations`;

function dataURItoBlob(dataURI: string) {
  var byteString = atob(dataURI.split(",")[1]);
  // var byteString = new Buffer(dataURI, 'base64');

  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  var ab = new ArrayBuffer(byteString.length);

  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export const generateSnapshot = async (): Promise<{
  file_small: Blob;
  blob_small: Blob;
  base64_small: string;
}> => {
  return new Promise(function (resolve) {
    setTimeout(async () => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      let canvas = <HTMLCanvasElement>document.getElementById("player_wrapper");

      const width =
        (await Number(canvas.offsetWidth)) > 500
          ? Number(canvas.offsetWidth) * 0.75
          : Number(canvas.offsetWidth);
      const height =
        (await Number(canvas.offsetWidth)) > 500
          ? Number(canvas.offsetHeight) * 0.75
          : Number(canvas.offsetHeight);

      const base64_small: string = await window.threekit.player.snapshotAsync({
        size: { width, height },
      });

      const blob_small = await dataURItoBlob(base64_small);
      const file_small = await new File([blob_small], "snapshot.png");

      resolve({ file_small, blob_small, base64_small });
    }, 5);
  });
};

type savedConfigurationT = {
  metadata?: any;
  authToken: string;
  threekitUrl: string;
  orgId: string;
};
export const SavedConfiguration = async (props: savedConfigurationT) => {
  const { metadata, authToken, orgId, threekitUrl } = props;

  let message;
  if (!getSceneAssetId()) message = "Requires Asset Id";
  if (!getAttributesThreekit()) message = "Requires a configuration";
  if (!document.getElementById("player_wrapper"))
    message = "Not canvas configuration";
  if (message) return [undefined, { message }];

  const { file_small, base64_small } = await generateSnapshot();

  const configuration = getFullConfiguration();

  const fd = new FormData();
  fd.append("files", file_small);
  fd.append("productId", getSceneAssetId());
  fd.append("productVersion", "v1");
  fd.append("orgId", orgId);
  fd.append("variant", JSON.stringify(configuration));
  if (metadata && Object.keys(metadata))
    fd.append("metadata", JSON.stringify(metadata));

  const fetchConf = {
    method: "post",
    body: fd,
  };

  let response = await fetch(
    `${threekitUrl}/${CONFIGURATIONS_API_ROUTE}?bearer_token=${authToken}`,
    fetchConf
  );
  // setAttributes("ActiveCamera", prevCamera);

  const data = await response.json();

  return { ...data, file_small: base64_small };
};