import s from "./ImgDragBorder.module.scss";
import { useSpaces, useThreekitInitStatus } from "@threekit-tools/treble";
import { Wall } from "../../../assets/images/svg/Drag/Wall";
import { Boundary } from "../../../assets/images/svg/Drag/Boundary";
import { WallModes } from "@threekit-tools/treble/dist/hooks/useSpaces/constants";

type drawingType = {
  icon: () => JSX.Element;
  label: string;
  subName: string;
};

type objDrawingType = Record<WallModes, drawingType>;

const SpacesComponent = () => {
  //@ts-ignore
  const { wallMode } = useSpaces({});

  const listDrawingType: objDrawingType = {
    true: {
      icon: Wall,
      label: "Wall",
      subName: "(solid line)",
    },
    false: {
      icon: Boundary,
      label: "Boundary",
      subName: "(dotted line)",
    },
  };

  if (wallMode["value"] === undefined) {
    wallMode.options[0].handleSelect();
  }

  return (
    <div className={s.imgOptionsWrap}>
      <div className={s.imgOptionsList}>
        {
          //@ts-ignore
          wallMode.options.map((el) => {
            const isActive = el.selected;
            const value = el.value;
            //@ts-ignore
            const drawingType = listDrawingType[value];

            return (
              <div
                key={drawingType["label"]}
                className={`${s.imgOption} ${isActive ? s.active : ""}`}
                onClick={() => el.handleSelect()}
              >
                <div className={s.img}>{drawingType.icon()}</div>
                <div className={s.wrap}>
                  <div className={s.name}>{drawingType.label}</div>
                  <div className={s.subName}>{drawingType.subName}</div>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export const ImgDragBorder = () => {
  const hasLoadedThreekit = useThreekitInitStatus();

  return <>{hasLoadedThreekit && <SpacesComponent />}</>;
};
