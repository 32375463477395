import { ModelCabinetWallT } from "../../../utils/constants/nodesNamesThreekit";
import {
  ATTRIBUTES_MOULDING,
  MouldingConfigurationT,
} from "../configuration/moulding";
import {
  isConnectingLeft,
  isConnectingLeftPartialSmall,
  isConnectingRight,
  isConnectingRightPartialSmall,
  isLeftPartialBigRightPartialSmall,
  isLeftPartialSmallRightPartialBig,
  isLeftRightPartialSmall,
} from "./getMouldingTopConfiguration";
import {
  ArrTypeConnectingTopMouldingT,
  getTypeConnectingBottomCabinetsWall,
} from "./getTypeConnectingCabinetsWall";

export const getMouldingBottomConfigurationCabinet = (
  typeConnecting: ArrTypeConnectingTopMouldingT,
  isLowerMolding: boolean,
): MouldingConfigurationT => {

  if (!isLowerMolding || typeConnecting.includes("front")) {
    return {
      [ATTRIBUTES_MOULDING.MOULDING_BOTTOM]: "no",
      [ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE]: "Center",
    };
  }

  let objMouldingTopConfiguration: MouldingConfigurationT = {
    [ATTRIBUTES_MOULDING.MOULDING_BOTTOM]: "yes",
    [ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE]: "Center",
  };

  if (typeConnecting.length === 0) {
    // в тумби немає сусідів
    // "Decorative Left, Right or Center" = alone
    objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE] =
      "Alone";
  }

  if (typeConnecting.length === 1) {
    // в тумби є сусід з однієї сторони

    // перевіряємо чи з'єднання ліве
    if (isConnectingLeft(typeConnecting)) {
      // значить справа точно потрібен молдинг
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE] =
        "Right";

      // молдинг може бути повний, якщо обрана модель глибиною 25"
      // сусідня зліва модель глибиною 13"
      if (isConnectingLeftPartialSmall(typeConnecting)) {
        objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE] =
          "Alone";
      }
    }

    // перевіряємо чи з'єднання праве
    if (isConnectingRight(typeConnecting)) {
      // значить зліва точно потрібен молдинг
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE] =
        "Left";

      // молдинг може бути повний, якщо обрана модель глибиною 25"
      // сусідня зправа модель глибиною 13"
      if (isConnectingRightPartialSmall(typeConnecting)) {
        objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE] =
          "Alone";
      }
    }
  }

  // в тумби є сусіди з обох сторін
  if (typeConnecting.length === 2) {
    // в цьому випадку молдінг може бути по центру
    objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE] =
      "Center";

    // молдінг може бути з усіх сторін, якщо обрана модель глибиною 25"
    // а сусідні тумби глибиною 13"
    if (isLeftRightPartialSmall(typeConnecting)) {
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE] =
        "Alone";
    }

    // молдінг може бути лівий, якщо обрана модель глибиною 25"
    // сусідня зліва модель глибиною 13"
    // сусідня зправа модель глибиною 25"
    if (isLeftPartialSmallRightPartialBig(typeConnecting)) {
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE] =
        "Left";
    }

    // молдінг може бути правий, якщо обрана модель глибиною 25"
    // сусідня зліва модель глибиною 25"
    // сусідня зправа модель глибиною 13"
    if (isLeftPartialBigRightPartialSmall(typeConnecting)) {
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE] =
        "Right";
    }
  }

  return objMouldingTopConfiguration;
};

export type ObjTopMouldingConfigurationT = {
  [key in ModelCabinetWallT]: MouldingConfigurationT;
};
export const getMouldingBottomConfigurationAllCabinets = (isLowerMolding: boolean) => {
  // отримуємо дані по типах коннектингу по низу настінних шкафів (для всіх настінних шкафів)
  const objTypeConnectingBottomCabinetsWall =
    getTypeConnectingBottomCabinetsWall();

  const arrNullNamesCabinetsWall = Object.keys(
    objTypeConnectingBottomCabinetsWall
  ) as Array<keyof typeof objTypeConnectingBottomCabinetsWall>;

  const objBottomMouldingConfiguration = arrNullNamesCabinetsWall.reduce(
    (objBottomMouldingConfigurationAcc: ObjTopMouldingConfigurationT, modelNullName) => {
      const typeConnecting = objTypeConnectingBottomCabinetsWall[modelNullName];
      const bottomMouldingConfiguration =
        getMouldingBottomConfigurationCabinet(typeConnecting, isLowerMolding);
      return {
        ...objBottomMouldingConfigurationAcc,
        [modelNullName]: bottomMouldingConfiguration,
      };
    },
    {}
  );

  return objBottomMouldingConfiguration;
};
