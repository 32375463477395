import react, { useRef, useEffect, useState, useLayoutEffect } from "react";
// scss
import s from "./SwitchFooterSmall.module.scss";
import { SwitchAntdSmallGreenStandart } from "../../../BaseComponent/SwitchAntdSmallGreenStandart/SwitchAntdSmallGreenStandart";

type SwitchSizePropsT = {
  label: string;
  onChange: (value: boolean) => void;
  defaultValue: boolean;
}

export const SwitchFooterSmall = ({...props}: SwitchSizePropsT) => {
  const { label, onChange, defaultValue } = props;
  const [ checkedValue, setCheckedValue ] = useState<boolean>(defaultValue)

  const handleChangeSwitch = (value: boolean) => {
    onChange(value);
  }

  useLayoutEffect(() => {
    setCheckedValue(defaultValue);
  }, [defaultValue])

  return (
    <div className={s.switchSizeWrap}>
      <div className={s.switchSizeLabel}>{label}</div>
      <div className={s.switchSize}>
        <SwitchAntdSmallGreenStandart
          checked={checkedValue}
          onChange={(value: boolean) => handleChangeSwitch(value)}
        />
      </div>
    </div>
  );
};
