import { PRODUCT_POSITIONS_KEYS } from "../../utils/constants/cabinets";
import { ModelsName_NodesT } from "../../utils/constants/nodesNamesThreekit";

export const getProductPositionFromNullName = (
  nullName: ModelsName_NodesT
): PRODUCT_POSITIONS_KEYS => {
  if (nullName.includes("Base")) return PRODUCT_POSITIONS_KEYS.BASE_CABINET;
  if (nullName.includes("Island")) return PRODUCT_POSITIONS_KEYS.ISLAND_CABINET;
  if (nullName.includes("Wall")) return PRODUCT_POSITIONS_KEYS.WALL_CABINET;
  if (nullName.includes("Appliances")) return PRODUCT_POSITIONS_KEYS.APPLIANCES;
  return PRODUCT_POSITIONS_KEYS.BASE_CABINET;
};
