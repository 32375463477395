import React, { useState } from "react";

import s from "./SwitchSpaceBetween.module.scss";
import { InfoCircleSvg } from "../../../assets/images/svg/Info";
import { SwitchStandardBase } from "../../BaseComponent/SwitchStandardBase/SwitchStandardBase";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getValueOption } from "../../../store/selectors/settingsUISelectors";
import { updateSettings } from "../../../store/slices/settingsUI/settingsUISlice";
import { updateSetingsUIAndThreekit } from "../../../store/actions/player.action";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";

export const SwitchSpaceBetween = ({ ...props }) => {
  const { id, label, defaultValue, values } = props;
  const dispatch = useAppDispatch();
  const selectValue = useAppSelector(getValueOption({idOption: id,}));

  const changeSwitchSpaceBetween = (value: boolean) => {
    dispatch(updateSetingsUIAndThreekit([{optionId: id, value: value}]))
  };

  const handleClickInfo = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.DECORATIVE_END, value: true}))
  }

  // const isDecorativeEnd = id.includes("DecorativeEnd")
  const isDecorativeEnd = false;

  return (
    <div className={s.switch}>
      {label && (
        <label className={s.label}>{label} {isDecorativeEnd && <span onClick={handleClickInfo}><InfoCircleSvg /></span>}</label>
      )}
      <SwitchStandardBase
        checked={!!selectValue}
        onChange={(value: any) => changeSwitchSpaceBetween(value)}
      />
    </div>
  );
};
