import { createContext } from "react";

export type initializationThreeKitDataT = {
  threekitUrl: string,
  publicToken: string,
  assetId: string,
  orgId: string,
}

export interface IInitializationProvider {
  initializationThreekit: initializationThreeKitDataT;
}

const defaultObj = {
  initializationThreekit: {
    threekitUrl: "",
    publicToken: "",
    assetId: "",
    orgId: "",
  },
};

const InitializationProvider =
  createContext<IInitializationProvider>(defaultObj);

export default InitializationProvider;
