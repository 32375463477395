export const once = (fn: any, context?: any) => {
  let result: any;

  return function() {
    if (fn) {
      result = fn.apply(context, arguments);
      fn = null;
    }
    return result;
  };
}