import React from "react";
import { DistributeComponents } from "../DistributeComponents/DistributeComponents";
import s from "./SectionsParams.module.scss";
import { IComponent } from "../../types/UI/dynamic-rendering.interfaces";
import { useAppSelector } from "../../hooks/useStoreHooks";
import { getActiveSection, getKeySectionActive } from "../../store/selectors/settingsUISelectors";

export const SectionsParams = ({ ...props }) => {

  const activeMenu = useAppSelector(getKeySectionActive);
  const settings: IComponent[] = useAppSelector(getActiveSection);

  if (!activeMenu) return <></>

  return (
    <>
      <div className={s.desktopSectionsParams}>
        {settings &&
          settings.map((setting: IComponent) => {
            return (
              <DistributeComponents key={setting["data"]["id"]} {...setting} />
            );
          })}
      </div>
    </>
  )
};
