import * as THREE from "three";

// Функція, яка приймає дві точки (p1 і p2), і повертає перпендикулярну проекцію точки (p) на пряму, визначену цими двома точками
export const calculatePerpendicularProjectionPointToLine = (
  p: THREE.Vector3,
  p1: THREE.Vector3,
  p2: THREE.Vector3
): THREE.Vector3 => {
  var lineVector = new THREE.Vector3().subVectors(p2, p1); // Вектор, що визначає пряму
  var pointVector = new THREE.Vector3().subVectors(p, p1); // Вектор, що визначає відстань від p1 до точки p
  
  lineVector.normalize(); // Нормалізуємо вектор прямої
  
  var projectionLength = pointVector.dot(lineVector); // Знаходимо довжину проекції вектора pointVector на lineVector
  
  var projectionVector = lineVector.clone().multiplyScalar(projectionLength); // Вектор проекції
  
  var projectionPoint = new THREE.Vector3().addVectors(p1, projectionVector); // Кінцева точка проекції
  
  return projectionPoint;
}