import { WallTemplateI } from "./../constants/presetsRoom";
import { ArrWallTemplateT } from "../constants/presetsRoom";
type itemT = {
  assetId: string;
  configuration: ArrWallTemplateT;
};
export type extractConfigurationT = itemT[];

export const extractConfiguration = (
  arr: extractConfigurationT[]
): ArrWallTemplateT => {
  const result: ArrWallTemplateT = [];
  arr.forEach((item: any) => {
    let configuration: WallTemplateI = item.configuration;
    result.push(configuration);
  });

  return result;
};
