import { CabinetsAndFeatures_NodesT, ModelCabinetWallT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { CABINETS_WALL_DEPTH_24 } from "../constatns";
import { getSizeModelBoxFromAssetCabinetWall } from "./size";

// Type guard для перевірки, чи змінна має тип ModelCabinetWallT
export const isNullNameModelCabinetWallT = (
  value: CabinetsAndFeatures_NodesT
): value is ModelCabinetWallT => {
  return (value as string).startsWith(NODES_THREEKIT.MODEL_CABINET_WALL);
}

export const check24DepthCabinetWall = (
  nullNameCabinetWall: ModelCabinetWallT
): boolean => {
  const sizeCabinetWall = getSizeModelBoxFromAssetCabinetWall(nullNameCabinetWall);
  return sizeCabinetWall["z"] + 0.1 > CABINETS_WALL_DEPTH_24 && sizeCabinetWall["z"] - 0.1 < CABINETS_WALL_DEPTH_24
}