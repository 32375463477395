export const ZoomIn = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 3.25C19.8315 3.25 20.1495 3.3817 20.3839 3.61612C20.6183 3.85054 20.75 4.16848 20.75 4.5V19.5C20.75 19.8315 20.6183 20.1495 20.3839 20.3839C20.1495 20.6183 19.8315 20.75 19.5 20.75H4.5C4.16848 20.75 3.85054 20.6183 3.61612 20.3839C3.3817 20.1495 3.25 19.8315 3.25 19.5V4.5C3.25 4.16848 3.3817 3.85054 3.61612 3.61612C3.85054 3.3817 4.16848 3.25 4.5 3.25H19.5ZM4.5 2C3.83696 2 3.20107 2.26339 2.73223 2.73223C2.26339 3.20107 2 3.83696 2 4.5V19.5C2 20.163 2.26339 20.7989 2.73223 21.2678C3.20107 21.7366 3.83696 22 4.5 22H19.5C20.163 22 20.7989 21.7366 21.2678 21.2678C21.7366 20.7989 22 20.163 22 19.5V4.5C22 3.83696 21.7366 3.20107 21.2678 2.73223C20.7989 2.26339 20.163 2 19.5 2H4.5Z"
        fill="black"
      />
      <path
        d="M12 7C12.1658 7 12.3247 7.06585 12.4419 7.18306C12.5592 7.30027 12.625 7.45924 12.625 7.625V11.375H16.375C16.5408 11.375 16.6997 11.4408 16.8169 11.5581C16.9342 11.6753 17 11.8342 17 12C17 12.1658 16.9342 12.3247 16.8169 12.4419C16.6997 12.5592 16.5408 12.625 16.375 12.625H12.625V16.375C12.625 16.5408 12.5592 16.6997 12.4419 16.8169C12.3247 16.9342 12.1658 17 12 17C11.8342 17 11.6753 16.9342 11.5581 16.8169C11.4408 16.6997 11.375 16.5408 11.375 16.375V12.625H7.625C7.45924 12.625 7.30027 12.5592 7.18306 12.4419C7.06585 12.3247 7 12.1658 7 12C7 11.8342 7.06585 11.6753 7.18306 11.5581C7.30027 11.4408 7.45924 11.375 7.625 11.375H11.375V7.625C11.375 7.45924 11.4408 7.30027 11.5581 7.18306C11.6753 7.06585 11.8342 7 12 7Z"
        fill="black"
      />
    </svg>
  );
};
