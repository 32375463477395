import React from 'react'
import { Global, css } from '@emotion/react'

//@ts-ignore
import ProximaNovaLight_eot from "./../../../assets/fonts/ProximaNova/ProximaNova-Light.eot";
//@ts-ignore
import ProximaNovaLight_ttf from "./../../../assets/fonts/ProximaNova/ProximaNova-Light.ttf";
//@ts-ignore
import ProximaNovaLight_woff from "./../../../assets/fonts/ProximaNova/ProximaNova-Light.woff";
//@ts-ignore
import ProximaNovaRegular_eot from "./../../../assets/fonts/ProximaNova/ProximaNova-Regular.eot";
//@ts-ignore
import ProximaNovaRegular_ttf from "./../../../assets/fonts/ProximaNova/ProximaNova-Regular.ttf";
//@ts-ignore
import ProximaNovaRegular_woff from "./../../../assets/fonts/ProximaNova/ProximaNova-Regular.woff";
//@ts-ignore
// import ProximaNovaSemibold_eot from "./../../../assets/fonts/ProximaNova/ProximaNova-Semibold.eot";
//@ts-ignore
// import ProximaNovaSemibold_ttf from "./../../../assets/fonts/ProximaNova/ProximaNova-Semibold.ttf";
//@ts-ignore
// import ProximaNovaSemibold_woff from "./../../../assets/fonts/ProximaNova/ProximaNova-Semibold.woff";
//@ts-ignore
import ProximaNovaBold_eot from "./../../../assets/fonts/ProximaNova/ProximaNova-Bold.eot";
//@ts-ignore
import ProximaNovaBold_ttf from "./../../../assets/fonts/ProximaNova/ProximaNova-Bold.ttf";
//@ts-ignore
import ProximaNovaBold_woff from "./../../../assets/fonts/ProximaNova/ProximaNova-Bold.woff";

//@ts-ignore
import OpenSansRegular_eot from "./../../../assets/fonts/OpenSans/OpenSans-Regular.eot";
//@ts-ignore
import OpenSansRegular_ttf from "./../../../assets/fonts/OpenSans/OpenSans-Regular.ttf";
//@ts-ignore
import OpenSansRegular_woff from "./../../../assets/fonts/OpenSans/OpenSans-Regular.woff";
//@ts-ignore
import OpenSansSemiBold_eot from "./../../../assets/fonts/OpenSans/OpenSans-SemiBold.eot";
//@ts-ignore
import OpenSansSemiBold_ttf from "./../../../assets/fonts/OpenSans/OpenSans-SemiBold.ttf";
//@ts-ignore
import OpenSansSemiBold_woff from "./../../../assets/fonts/OpenSans/OpenSans-SemiBold.woff";

export const GlobalStyle = () => {
    return (
        <Global
            styles={css`
            @font-face {
              font-family: "ProximaNova";
              src: url('${ProximaNovaLight_eot}');
              src: url('${ProximaNovaLight_eot}?#iefix') format('embedded-opentype'),
                   url('${ProximaNovaLight_woff}') format('woff'),
                   url('${ProximaNovaLight_ttf}')  format('truetype');
              font-weight: 250;
              font-style: normal;
            }
            @font-face {
              font-family: 'ProximaNova';
              src: url('${ProximaNovaRegular_eot}');
              src: url('${ProximaNovaRegular_eot}?#iefix') format('embedded-opentype'),
                   url('${ProximaNovaRegular_woff}') format('woff'),
                   url('${ProximaNovaRegular_ttf}')  format('truetype');
              font-weight: 400;
              font-style: normal;
            }
            @font-face {
              font-family: "ProximaNova";
              src: url('${ProximaNovaBold_eot}');
              src: url('${ProximaNovaBold_eot}?#iefix') format('embedded-opentype'),
                   url('${ProximaNovaBold_woff}') format('woff'),
                   url('${ProximaNovaBold_ttf}')  format('truetype');
              font-weight: 700;
              font-style: normal;
            }
            @font-face {
              font-family: "OpenSans";
              src: url('${OpenSansRegular_eot}');
              src: url('${OpenSansRegular_eot}?#iefix') format('embedded-opentype'),
                   url('${OpenSansRegular_woff}') format('woff'),
                   url('${OpenSansRegular_ttf}')  format('truetype');
              font-weight: 400;
              font-style: normal;
            }
            @font-face {
              font-family: "OpenSans";
              src: url('${OpenSansSemiBold_eot}');
              src: url('${OpenSansSemiBold_eot}?#iefix') format('embedded-opentype'),
                   url('${OpenSansSemiBold_woff}') format('woff'),
                   url('${OpenSansSemiBold_ttf}')  format('truetype');
              font-weight: 600;
              font-style: normal;
            }
          `}
        />
    )
}
