import {
  THREEKIT_ASSET_ID,
  THREEKIT_PREVIEW_ORG_ID,
  THREEKIT_PREVIEW_PUBLIC_TOKEN,
  THREEKIT_ADMIN_FTS_ORG_ID,
  THREEKIT_ADMIN_FTS_PUBLIC_TOKEN,
} from "./src/configuration/utils/threekit/threekitConfig/threekitConfig";

export default {
  credentials: {
    preview: {
      orgId: THREEKIT_PREVIEW_ORG_ID,
      publicToken: THREEKIT_PREVIEW_PUBLIC_TOKEN,
    },
    "admin-fts": {
      orgId: THREEKIT_ADMIN_FTS_ORG_ID,
      publicToken: THREEKIT_ADMIN_FTS_PUBLIC_TOKEN,
    },
  },
  products: {
    preview: {
      assetId: THREEKIT_ASSET_ID,
      configurationId: undefined,
      stageId: undefined,
    },
    "admin-fts": {
      assetId: THREEKIT_ASSET_ID,
      configurationId: undefined,
      stageId: undefined,
    },
  },
};
