import { CabinetsAndFeatures_NodesT, WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../utils/other/getObjKeysFromType";
import { AllWallsIntervalsFloorT } from "./getIntervalsInfoOnWallBase";

export const checkCornerCabinetInCornerFromIntervals = (
  intervalsCabinetsBase: AllWallsIntervalsFloorT,
  nullNameCabinetBase: CabinetsAndFeatures_NodesT
): boolean => {
  let wallsFromModel: WallItemT[] = [];
  const keysIntervalsCabinetsBase = getKeys(intervalsCabinetsBase);
  keysIntervalsCabinetsBase.forEach((wallName) => {
    const arrIntervalsOnWall = intervalsCabinetsBase[wallName];
    const intervalCurrentModel = arrIntervalsOnWall.find((objInterval) => objInterval["name"] === nullNameCabinetBase);
    if (intervalCurrentModel !== undefined)
      wallsFromModel.push(wallName);
  })
  return wallsFromModel.length > 1 ? true : false;
}