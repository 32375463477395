import React from "react";

// scss
import s from "./CategoriesGroup.module.scss";

export const CategoriesGroup = ({...props}) => {
  const { children } = props;
  return (
    <div className={s.categoriesGroup}>
      { children }
    </div>
  );
};
