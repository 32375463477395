import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { NODES_THREEKIT, PlaneCabinetsWallT, WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getTHREE } from "../../utils/three/general/getFunctionsTHREE";
import { getBoxHeightThreekit, getNodeMetadataValue, getWorldTransformEvalNode } from "../../utils/threekit/general/getFunctions";
import { getNumberNodeThreekitFromName } from "../general";
import { HEIGHT_HIDDEN_WALL, getAllWallsNode } from "./buildWallFromData";

/**
 * Функція для отримання масиву name для всіх стін з Threekit.
 *
 * @return {WallItemT} Масив name для всіх стін з Threekit.
 */
export const getArrWallsNames = (): WallItemT[] => {
  const allWalls = getAllWallsNode();
  const arrWallsNames = Object.values(allWalls).map(
    (nodeWall) => nodeWall["name"] as WallItemT
  );
  return arrWallsNames;
};

/**
 * Повертає вектор, який показує напрям від Front-сторони стіни (перпендикулярно до площини стіни).
 *
 * @param {WallItemT} wallName Name стіни з Threekit.
 * @return {ICoordinates} Вектор wallDir,
 * який показує напрям від Front-сторони стіни (перпендикулярно до площини стіни)..
 */
export const getWallDir = (wallName: WallItemT): ICoordinates => {
  const THREE = getTHREE();
  const worldTransformWall = getWorldTransformEvalNode(wallName);
  const wallDir = new THREE.Vector3(0, 0, 1).transformDirection(worldTransformWall);
  return wallDir;
}

type LeftRigthDirectionsWallT = {
  leftDirectionWall: ICoordinates,
  rightDirectionWall: ICoordinates,
}
/**
 * Повертає два вектори { leftDirectionWall, rightDirectionWall }.
 *
 * @param {WallItemT} wallName Name стіни з Threekit.
 * @return {LeftRigthDarectionsPlane} Вектори { leftDirectionWall, rightDirectionWall },
 * які показують відповідно напрями вліво та вправо по площині стіни від центру стіни.
 */
export const getLeftRigthDirectionsWall = (wallName: WallItemT): LeftRigthDirectionsWallT => {
  const THREE = getTHREE();
  const wallDir = getWallDir(wallName);
  const topVectorY = new THREE.Vector3(0, 1, 0);
  const bottomVectorY = new THREE.Vector3(0, -1, 0);
  // скалярний добуток векторів
  // отримуємо вектори, направлені вліво і вправо від центру стіни(Wall Translation)
  let leftCrossVectorNorm = new THREE.Vector3()
    .crossVectors(wallDir, topVectorY)
    .normalize();
  let rightCrossVectorNorm = new THREE.Vector3()
    .crossVectors(wallDir, bottomVectorY)
    .normalize();

  return {
    leftDirectionWall: leftCrossVectorNorm,
    rightDirectionWall: rightCrossVectorNorm
  }
}

/**
 * Повертає name для стіни, на якій розташований плейн з ім'ям planeName.
 *
 * @param {PlaneCabinetsWallT} planeName Name плейну з Threekit.
 * @return {WallItemT} Name стіни з Threekit.
 */
export const getWallNameFromPlaneName = (
  planeName: PlaneCabinetsWallT
): WallItemT => {
  return `${
    NODES_THREEKIT.WALL_ITEM
  }${getNumberNodeThreekitFromName(planeName)}` as WallItemT;
}

/**
 * Перевіряє чи є стіна видимою. (Перевіряє видимість стіни, встановлену на єтапі побудови 2Д плану кімнати)
 * При стіворенні 3Д ноди стіни ми додавали MetaData, що описувала видимість стіни.
 * Тепер тут перевіряєтьтся значення MetaData: "true" або "false";
 *
 * @param {WallItemT} wallName Name стіни з Threekit.
 * @return {boolean} true - стіна видима. false - стіна не видима.
 */
export const checkWallVisibility = (wallName: WallItemT): boolean => {
  const metaDataVisibility = getNodeMetadataValue({name: wallName, key: "WallVisibility"});
  return metaDataVisibility === "true" ? true : false;
}

/**
 * Перевіряє чи є стіна видимою. (Перевіряє видимість стіни в залежності від повороту камери)
 * При стіворенні 3Д ноди стіни ми додавали MetaData, що описувала видимість стіни.
 * Тепер тут перевіряєтьтся значення MetaData: "true" або "false" та висота стіни,
 * яка є HEIGHT_HIDDEN_WALL при приховуванні стіни в залежності від повороту камери;
 *
 * @param {WallItemT} wallName Name стіни з Threekit.
 * @return {boolean} true - стіна видима. false - стіна не видима.
 */
export const checkWallVisibilityFromCamera = (wallName: WallItemT): boolean => {
  const wallHeight = getBoxHeightThreekit({name: wallName});
  const isWallHeightVisibility = wallHeight > HEIGHT_HIDDEN_WALL;
  return isWallHeightVisibility;
}

/**
 * Шукає наступну за обраною стіну та повертає її Name.
 *
 * @param {WallItemT} currentWallName Name стіни, яка не пройшла перевірку на видимість та наявність вільного місця
 * @return {WallItemT} Name наступної стіни
 */
export const getNextWall = (currentWallName: WallItemT): WallItemT => {

  // 1 Знайти стіну справа та перевіряти її
  // або
  // 2 Йти черепз плейни на стінах, тут є функція яка повертає сусідні плейни для обраного плейну

  // по суті нам потрібен плейн, так як інтервали на стінах перевіряються по плейнах та моделі встановлюються на плейни

  // const currentPlaneName = getPlaneNameFromWallName(currentWallName)

  const currentWallNumber = getNumberNodeThreekitFromName(currentWallName);
  const allWallsLength = Object.keys(getAllWallsNode()).length;
  const newWallNumber =
    currentWallNumber === allWallsLength - 1 ? 0 : currentWallNumber + 1;

  return `${NODES_THREEKIT.WALL_ITEM}${newWallNumber}`;
};
