export interface InperialUnitsI {
  feet: number | string;
  inches: number | string;
}

export const convertMetersToFeetAndInches = (
  meters: number
): InperialUnitsI => {

  if (meters === 0) {
    return {
      feet: "",
      inches: "",
    };
  }

  const totalInches = meters * 39.3701;
  let feet = Math.floor(totalInches / 12);
  let inches = Math.round(totalInches - feet * 12);
  let inchesRoundToTenths = Number((totalInches - feet * 12).toFixed(1));

  if (inchesRoundToTenths === 12) {
    feet++;
    inchesRoundToTenths = 0;
  }

  return {
    feet,
    inches: inchesRoundToTenths,
  };
};

export const convertFeetAndInchesToMeters = (
  inperialUnits: InperialUnitsI
): number => {
  const { feet, inches } = inperialUnits;
  const feetNum = typeof feet === "string" ? parseFloat(feet) : feet;
  const inchesNum = typeof inches === "string" ? parseFloat(inches) : inches;
  const totalInches = feetNum * 12 + inchesNum;
  const meters = totalInches / 39.37;
  return meters;
};

export const convertImperialStringToImperialNumber = (
  inperialUnits: InperialUnitsI
) => {
  return {
    feet: Number(inperialUnits["feet"]),
    inches: Number(inperialUnits["inches"]),
  };
};
