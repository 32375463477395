import React from "react";

// scss
import s from "./ProductsGroup.module.scss";

export const ProductsGroup = ({...props}) => {
  const { children } = props;
  return (
    <div className={s.productsGroup}>
      { children }
    </div>
  );
};
