// scss
import s from "./Buttons.module.scss";

interface ButtonIconI {
  onClick: any;
  [key: string]: any;
}

export const ButtonIcon = ({ ...props }: ButtonIconI) => {
  const { children, onClick, active, disabled } = props;
  return (
    <button
      className={`
        ${s.btn}
        ${s.btn_icon}
        ${active && s.active}
        ${disabled && s.disabled}
      `}
      onClick={() => onClick()}
    >
      {children}
    </button>
  );
};
