import { IConfigurationArray } from "@threekit-tools/treble/dist/types";
import { getNodeIdFromName, getRotationThreekit, getTranslationThreekit } from "../../utils/threekit/general/getFunctions";

export const getNewAllObjectsLocationForRemoved = async (
  arrNewValueAttributeThreekit: IConfigurationArray,
  positionKeyDelete: number[],
  regexpNullPoint: string
) => {

  //@ts-ignore
  // const instanceIdSelectedModel: string = window.threekit.player.selectionSet.ids[0];
  const instanceIdSelectedModel: string = getNodeIdFromName(`${regexpNullPoint}0`);
  //@ts-ignore
  const instanceIdLayoutContainer = window.threekit.player.scene.get({ from: instanceIdSelectedModel })['parent'];
  //@ts-ignore
  const maxCountModels = window.threekit.player.scene.get({ from: instanceIdLayoutContainer })['children'].length;

  const countObject = arrNewValueAttributeThreekit.length;
  let listArray: any[] = [];

  for (let index = 0; index < countObject; index++) listArray.push(index);

  let objectNullModels = {};

  let objEmptyPositions: {[key in number]: number} = {}

  const getPositionReconciliationIndex = (
    positionKeyDelete: number[],
    index: number
  ): any => {
    if (positionKeyDelete.length < 1) return index;

    let positionReconciliationIndex = 0;
    let newIndex = index;
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex === newIndex) {
        if (keyIndex === arrNewValueAttributeThreekit.length) {
          objEmptyPositions[keyIndex] = keyIndex;
        } else {
          objEmptyPositions[maxCountModels - positionReconciliationIndex] = newIndex;
        }
      }
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });

    newIndex = index + positionReconciliationIndex;

    if (Object.keys(objEmptyPositions).includes(String(index))) {
      return objEmptyPositions[index]
    }

    return newIndex;
  };

  listArray.forEach((indexNum: number, index: number) => {
    let pointIndexNumberForUpdateCoordinate = getPositionReconciliationIndex(
      positionKeyDelete,
      indexNum
    );
    let namePointMesh = `${regexpNullPoint}${indexNum}`;
    let pointForUpdateCoordinate = `${regexpNullPoint}${pointIndexNumberForUpdateCoordinate}`;
    objectNullModels = {
      ...objectNullModels,
      [namePointMesh]: {
        sequenceNumber: indexNum,
        pointForUpdateCoordinate: pointForUpdateCoordinate,
        rotation: getRotationThreekit({name: pointForUpdateCoordinate}),
        transition: getTranslationThreekit({name: pointForUpdateCoordinate}),
      },
    };
  });

  return objectNullModels;
};