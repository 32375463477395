import { ModelCabinetWallT } from "../../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import {
  ATTRIBUTES_MOULDING,
  MouldingConfigurationT,
  MouldingTopValuesT,
} from "../configuration/moulding";
import { check24DepthCabinetWall } from "./checkCabinetsWall";
import {
  ArrTypeConnectingTopMouldingT,
  getTypeConnectingTopCabinetsWall,
} from "./getTypeConnectingCabinetsWall";

export const isConnectingLeft = (
  typeConnecting: ArrTypeConnectingTopMouldingT
): boolean => {
  const typeConnectingLower = typeConnecting[0].toLowerCase();
  return typeConnectingLower.includes("left");
};
export const isConnectingRight = (
  typeConnecting: ArrTypeConnectingTopMouldingT
): boolean => {
  const typeConnectingLower = typeConnecting[0].toLowerCase();
  return typeConnectingLower.includes("right");
};
export const isConnectingLeftPartialSmall = (
  typeConnecting: ArrTypeConnectingTopMouldingT
): boolean => {
  return typeConnecting.includes("partialSmallLeft");
};
export const isConnectingRightPartialSmall = (
  typeConnecting: ArrTypeConnectingTopMouldingT
): boolean => {
  return typeConnecting.includes("partialSmallRight");
};
export const isLeftRightPartialSmall = (
  typeConnecting: ArrTypeConnectingTopMouldingT
): boolean => {
  return (
    typeConnecting.includes("partialSmallLeft") &&
    typeConnecting.includes("partialSmallRight")
  );
};
export const isLeftPartialSmallRightPartialBig = (
  typeConnecting: ArrTypeConnectingTopMouldingT
): boolean => {
  return (
    typeConnecting.includes("partialSmallLeft") &&
    typeConnecting.includes("partialBigRight")
  );
};
export const isLeftPartialBigRightPartialSmall = (
  typeConnecting: ArrTypeConnectingTopMouldingT
): boolean => {
  return (
    typeConnecting.includes("partialBigLeft") &&
    typeConnecting.includes("partialSmallRight")
  );
};
export const isRightAndLeftPartialSmall = (
  typeConnecting: ArrTypeConnectingTopMouldingT
): boolean => {
  return (
    typeConnecting.includes("right") &&
    typeConnecting.includes("partialSmallLeft")
  );
};
export const isLeftAndRightPartialSmall = (
  typeConnecting: ArrTypeConnectingTopMouldingT
): boolean => {
  return (
    typeConnecting.includes("left") &&
    typeConnecting.includes("partialSmallRight")
  );
};

export const getMouldingTopConfigurationCabinet = (
  nullNameCabinetWall: ModelCabinetWallT,
  typeConnecting: ArrTypeConnectingTopMouldingT,
  isUpperMolding: boolean,
  sizeUpperMolding: MouldingTopValuesT
): MouldingConfigurationT => {

  if (!isUpperMolding || typeConnecting.includes("front")) {
    return {
      [ATTRIBUTES_MOULDING.MOULDING_TOP]: "no",
      [ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE]: "Center",
    };
  }

  const is24DepthCabinetWall = check24DepthCabinetWall(nullNameCabinetWall);

  let objMouldingTopConfiguration: MouldingConfigurationT = {
    [ATTRIBUTES_MOULDING.MOULDING_TOP]: sizeUpperMolding,
    [ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE]: "Center",
  };

  // по замовчуванню вимикаємо вирізання молдингу з обох сторін для шкафів глибиною 24"
  if (is24DepthCabinetWall) {
    objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_CONNECTION_LEFT] = "no";
    objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_CONNECTION_RIGHT] = "no";
  }

  if (typeConnecting.length === 0) {
    // в тумби немає сусідів
    // "Decorative Left, Right or Center" = alone
    objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE] =
      "Alone";
  }

  if (typeConnecting.length === 1) {
    // в тумби є сусід з однієї сторони

    // перевіряємо чи з'єднання ліве
    if (isConnectingLeft(typeConnecting)) {
      // значить справа точно потрібен молдинг
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE] =
        "Right";

      // молдинг може бути повний, якщо обрана модель глибиною 25"
      // сусідня зліва модель глибиною 13"
      // в цьому випадку маємо додати виріз для молдингу зліва
      if (isConnectingLeftPartialSmall(typeConnecting)) {
        objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE] =
          "Alone";
        objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_CONNECTION_LEFT] = "yes";
      }
    }

    // перевіряємо чи з'єднання праве
    if (isConnectingRight(typeConnecting)) {
      // значить зліва точно потрібен молдинг
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE] =
        "Left";

      // молдинг може бути повний, якщо обрана модель глибиною 25"
      // сусідня зправа модель глибиною 13"
      // в цьому випадку маємо додати виріз для молдингу зправа
      if (isConnectingRightPartialSmall(typeConnecting)) {
        objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE] =
          "Alone";
        objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_CONNECTION_RIGHT] = "yes";
      }
    }
  }

  // в тумби є сусіди з обох сторін
  if (typeConnecting.length === 2) {
    // в цьому випадку молдінг може бути по центру
    objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE] =
      "Center";

    // молдінг може бути з усіх сторін, якщо обрана модель глибиною 24"
    // а сусідні тумби глибиною 12"
    // в цьому випадку маємо додати виріз для молдингу зліва і зправа
    if (isLeftRightPartialSmall(typeConnecting)) {
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE] =
        "Alone";
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_CONNECTION_LEFT] = "yes";
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_CONNECTION_RIGHT] = "yes";
    }

    // молдінг може бути лівий, якщо обрана модель глибиною 24"
    // сусідня зліва модель глибиною 12"
    // сусідня зправа модель глибиною 24"
    // в цьому випадку маємо додати виріз для молдингу зліва
    if (isRightAndLeftPartialSmall(typeConnecting)) {
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE] =
        "Left";
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_CONNECTION_LEFT] = "yes";
    }

    // молдінг може бути правий, якщо обрана модель глибиною 24"
    // сусідня зліва модель глибиною 24"
    // сусідня зправа модель глибиною 12"
    // в цьому випадку маємо додати виріз для молдингу зправа
    if (isLeftAndRightPartialSmall(typeConnecting)) {
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE] =
        "Right";
      objMouldingTopConfiguration[ATTRIBUTES_MOULDING.MOULDING_CONNECTION_RIGHT] = "yes";
    }
  }

  return objMouldingTopConfiguration;
};

export type ObjTopMouldingConfigurationT = {
  [key in ModelCabinetWallT]: MouldingConfigurationT;
};
export const getMouldingTopConfigurationAllCabinets = (
  isUpperMolding: boolean,
  sizeUpperMolding: MouldingTopValuesT
) => {
  // отримуємо дані по типах коннектингу по верху настінних шкафів (для всіх настінних шкафів)
  const objTypeConnectingTopCabinetsWall = getTypeConnectingTopCabinetsWall();

  const arrNullNamesCabinetsWall = getKeys(objTypeConnectingTopCabinetsWall);

  const objTopMouldingConfiguration = arrNullNamesCabinetsWall.reduce(
    (objExtremeModelsInfoAcc: ObjTopMouldingConfigurationT, modelNullName) => {
      const typeConnecting = objTypeConnectingTopCabinetsWall[modelNullName];
      const topMouldingConfiguration = getMouldingTopConfigurationCabinet(
        modelNullName,
        typeConnecting,
        isUpperMolding,
        sizeUpperMolding
      );
      return {
        ...objExtremeModelsInfoAcc,
        [modelNullName]: topMouldingConfiguration,
      };
    },
    {}
  );

  return objTopMouldingConfiguration;
};
