import { ICoordinates, ISceneResult } from "@threekit-tools/treble/dist/types";
import { NODES_THREEKIT, PlaneCabinetsWallT, WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getTHREE } from "../../utils/three/general/getFunctionsTHREE";
import { getAllEvalNodeThreekit, getBoxHeightThreekit, getBoxWidthThreekit, getSceneInstanceId, getTranslationThreekit, getWorldTransformEvalNode } from "../../utils/threekit/general/getFunctions";
import { moveCoordsByVector } from "./buildWallFromData";
import { getNumberNodeThreekitFromName } from "../general";

export const getAllPlanesNode = (): {[key in string]: ISceneResult} => {
  //@ts-ignore
  return window.threekit.player.scene.getAll({name: `${NODES_THREEKIT.PLANE_CABINETS_WALL}*`})
}
export const getAllWallsEvalNode = () => {
  //@ts-ignore
  return window.threekit.player.scene.getAll({name: `${NODES_THREEKIT.PLANE_CABINETS_WALL}*`, evalNode: true});
}

type LeftRigthDarectionsPlane = {
  leftDirectionPlane: ICoordinates,
  rightDirectionPlane: ICoordinates,
}
/**
 * Повертає два вектори { leftDirectionPlane, rightDirectionPlane }.
 *
 * @param {string} namePlane Name для Plane на стіні. По цьому Plane ми рухаємо об'єкти.
 * @return {LeftRigthDarectionsPlane} Вектори { leftDirectionPlane, rightDirectionPlane },
 * які показують відповідно напрями вліво та вправо по площині Plane від центру Plane.
 */
export const getLeftRigthDirectionsPlane = (namePlane: string): LeftRigthDarectionsPlane => {
  const THREE = getTHREE();
  const currentPlaneWorldTransform =
    getWorldTransformEvalNode(namePlane);
  const planeDir = new THREE.Vector3(0, 1, 0).transformDirection(
    currentPlaneWorldTransform
  );
  const topVectorY = new THREE.Vector3(0, 1, 0);
  const bottomVectorY = new THREE.Vector3(0, -1, 0);
  // скалярний добуток векторів
  // отримуємо вектори, направлені вліво і вправо від центру Plane(currentPlaneCabinetsTranslation)
  let leftCrossVectorNorm = new THREE.Vector3()
    .crossVectors(planeDir, topVectorY)
    .normalize();
  let rightCrossVectorNorm = new THREE.Vector3()
    .crossVectors(planeDir, bottomVectorY)
    .normalize();

  return {
    leftDirectionPlane: leftCrossVectorNorm,
    rightDirectionPlane: rightCrossVectorNorm
  }
}

/**
 * Повертає координати початку і кінця для Plane що знаходиться на обраній стіні.
 *
 * @param {string} namePlane Name для Plane на стіні. По цьому Plane ми рухаємо об'єкти.
 * @return {[ICoordinates, ICoordinates]} Координати початку і кінця для Plane.
 */
export const getStartEndCoordsPlane = (namePlane: PlaneCabinetsWallT): [ICoordinates, ICoordinates] => {
  let currentPlaneCabinetsTranslation = getTranslationThreekit({
    from: getSceneInstanceId(),
    name: namePlane,
  });
  const currentPlaneWidth = getBoxWidthThreekit({ name: namePlane });
  const currentPlaneHeight = getBoxHeightThreekit({ name: namePlane });
  const { leftDirectionPlane, rightDirectionPlane} = getLeftRigthDirectionsPlane(namePlane);

  // трансформуємо координати, щоб отримати верхню точку Plane
  currentPlaneCabinetsTranslation = { ...currentPlaneCabinetsTranslation, y: currentPlaneCabinetsTranslation["y"] + currentPlaneHeight/2}

  const currentPlaneCoordsLeft = moveCoordsByVector(
    currentPlaneCabinetsTranslation,
    leftDirectionPlane,
    currentPlaneWidth/2
  );
  const currentPlaneCoordsRight = moveCoordsByVector(
    currentPlaneCabinetsTranslation,
    rightDirectionPlane,
    currentPlaneWidth/2
  );

  return [currentPlaneCoordsLeft, currentPlaneCoordsRight]
}

type NeighborPlanesT = {
  planeLeftName?: PlaneCabinetsWallT,
  planeRightName?: PlaneCabinetsWallT
}
/**
 * Шукає сусідні Plane Nodes Names для обраного Plane.
 *
 * @param {string} currentPlaneName Name для обраного Plane на стіні. По цьому Plane ми рухаємо об'єкти.
 * @return {NeighborPlanesT} Name для лівого(planeLeftName) та правого(planeRightName) сусідніх Plane.
 */
export const getNeighborPlanes = (currentPlaneName: PlaneCabinetsWallT): NeighborPlanesT => {
  const allPlanes = getAllEvalNodeThreekit(NODES_THREEKIT.PLANE_CABINETS_WALL);
  const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
    getStartEndCoordsPlane(currentPlaneName);

  let resultObj: NeighborPlanesT = {}
  
  Object.values(allPlanes).forEach((evalNodePlane: any) => {
    const evalNodeName = evalNodePlane["name"];
    if (evalNodeName === currentPlaneName) return;
    const [planeCoordsLeft, planeCoordsRight] =
      getStartEndCoordsPlane(evalNodePlane["name"]);

    //@ts-ignore
    const distanceCurrentPlaneLeftToPlaneLeft = currentPlaneCoordsLeft.distanceTo(planeCoordsLeft);
    //@ts-ignore
    const distanceCurrentPlaneLeftToPlaneRight = currentPlaneCoordsLeft.distanceTo(planeCoordsRight);
    if (distanceCurrentPlaneLeftToPlaneLeft <= 0.1 || distanceCurrentPlaneLeftToPlaneRight <= 0.1)
      resultObj["planeLeftName"] = evalNodeName;

    //@ts-ignore
    const distanceCurrentPlaneRightToPlaneLeft = currentPlaneCoordsRight.distanceTo(planeCoordsLeft);
    //@ts-ignore
    const distanceCurrentPlaneRightToPlaneRight = currentPlaneCoordsRight.distanceTo(planeCoordsRight);
    if (distanceCurrentPlaneRightToPlaneLeft <= 0.1 || distanceCurrentPlaneRightToPlaneRight <= 0.1)
      resultObj["planeRightName"] = evalNodeName;

  });

  return resultObj;

}

/**
 * Повертає name для плейну, який розташований на стіні wallName.
 *
 * @param {WallItemT} wallName Name стіни з Threekit.
 * @return {PlaneCabinetsWallT} Name плейну з Threekit.
 */
export const getPlaneNameFromWallName = (
  wallName: WallItemT
): PlaneCabinetsWallT => {
  return `${
    NODES_THREEKIT.PLANE_CABINETS_WALL
  }${getNumberNodeThreekitFromName(wallName)}` as PlaneCabinetsWallT;
}