export const ArrowAccordionSvg = () => {
  return (
    <svg
      width="17"
      height="10"
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.5 9L8.5 2L1.5 9" stroke="#8C8C8C" strokeWidth="2" />
    </svg>
  );
};
