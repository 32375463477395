import { NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getNodeIdFromName } from "../../../utils/threekit/general/getFunctions";
import { ASSET_ID_OPACITY_MATERIAL, WIDTH_BOUNDING_PLANES, getAssetIdCurrentMaterial } from "../buildWallFromData";

export const addWallInRoom = async (objParams: any) => {

  const {
    index,
    wallHeight,
    wallWidthOriginal,
    wallWidthFromDepth,
    wallDepth,
    coordsPosition,
    wallAngle,
    isVisible
  } = objParams;

  const wallName = `${NODES_THREEKIT.WALL_ITEM}${index}`;
  const assetIdCurrentMaterial = getAssetIdCurrentMaterial();
  const wallsLayoutContainerId = getNodeIdFromName(
    NODES_THREEKIT.LAYOUT_CONTAINER_WALLS_WRAP
  );

  const newNodeParamsWall = {
    name: wallName,
    type: "PolyMesh",
    plugs: {
      PolyMesh: [
        {
          type: "Box",
          height: wallHeight,
          width: wallWidthFromDepth,
          depth: wallDepth,
        },
        // для вирізу під windows, doors, openings
        {
          type: "BooleanModifier",
          source: ['#_wallSubtract'],
          active: true,
          name: "Boolean",
          operation: 1
        },
      ],
      Transform: [
        {
          type: "Transform",
          translation: coordsPosition,
          rotation: { x: 0, y: wallAngle, z: 0 },
        },
      ],
      Material: [
        {
          type: "Reference",
          asset: { assetId: isVisible ? assetIdCurrentMaterial : ASSET_ID_OPACITY_MATERIAL },
        },
      ],
      Physics: [
        {
          type: "Collider",
          response: 2,
          shape: 0,
        },
      ],
      Properties: [
        {
          type: "PolyMeshProperties"
        },
        {
          name: "WallVisibility",
          type: "MetaData",
          key: "WallVisibility",
          value: isVisible ? "true" : "false",
        }
      ]
    },
  };

  //@ts-ignore
  return await window.threekit.player.scene.addNode(
    newNodeParamsWall,
    wallsLayoutContainerId
  );

}