import { SelectedModelNameT } from "../../../store/slices/settingsUI/typesSettingsUISlice";
import { getItemNodeFromNullModel } from "../../../utils/threekit/general/getFunctions";
import { getConfiguratorModelFromNullName } from "./decorativePanel";

export const enum ATTRIBUTES_DOOR {
  DOOR_DIRECTION = "Door Knob Location",
}

export type DoorDirectionValuesT = "Left" | "Right";
export type DoorDirectionConfigurationT = {
  [ATTRIBUTES_DOOR.DOOR_DIRECTION]?: DoorDirectionValuesT,
}

/**
 * Функція перевіряє чи є шкаф з одною дверкою.
 *
 * @param {SelectedModelNameT} modelNullName Null name моделеі.
 * @returns {boolean} true - якщо шкаф має одну дверку.
 */
export const checkSingleDoorModel = (
  modelNullName: SelectedModelNameT
): boolean => {
  if (modelNullName === undefined) return false;
  const modelItem = getItemNodeFromNullModel({ name: modelNullName });
  if (modelItem === undefined) return false;
  return (
    modelItem["name"].includes("Single Door") ||
    modelItem["name"].includes("Single_Door")
  );
};

/**
 * Функція шукає активне значення атрибуту для сторони відкривання дверей для моделі.
 *
 * @param {SelectedModelNameT} modelNullName Null name моделеі.
 * @returns {DoorDirectionValuesT | undefined} Значення атрибуту або undefined - якщо модель не проходить умову на одну дверку.
 */
export const getActiveDoorDirectionForModel = (modelNullName: SelectedModelNameT): DoorDirectionValuesT | undefined => {

  if (!!!modelNullName) return undefined;

  const isSingleDoorModel = checkSingleDoorModel(modelNullName);

  if (!isSingleDoorModel) return undefined;

  const configuratorModel = getConfiguratorModelFromNullName(modelNullName);
  const configurationModel = configuratorModel.getConfiguration();
  const activeDoorDirection = configurationModel[ATTRIBUTES_DOOR.DOOR_DIRECTION] as DoorDirectionValuesT;
  return activeDoorDirection;
}

/**
 * Функція встановлює в конфігурацію моделі інформацію про сторону відкривання дверей.
 *
 * @param {SelectedModelNameT} modelNullName Null name моделеі.
 * @param {SelectedModelNameT} value Значення для атрибута моделі.
 */
export const updateDoorDirectionForModel = async (modelNullName: SelectedModelNameT, value: DoorDirectionValuesT) => {
  if (!!!modelNullName) return;
  const newConfiguration = {
    [ATTRIBUTES_DOOR.DOOR_DIRECTION]: value,
  }
  const configuratorModel = getConfiguratorModelFromNullName(modelNullName);
  await configuratorModel.setConfiguration(newConfiguration);
}