import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { ModelCabinetWallT, NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { getRotationThreekit } from "../../utils/threekit/general/getFunctions";
import { getСompletedModelsNullNames } from "./getNodesCabinets";
import { getModelPositionFromName } from "../../utils/threekit/tools/toolsDragCabinetsIsland/generalFunc";
import {
  setRotationThreekit,
  setTranslationThreekit,
} from "../../utils/threekit/general/setFunctions";

export interface PositionAndRotationI {
  translation: ICoordinates;
  rotation: ICoordinates;
}
export type ObjPositionCabinetsWallT = {
  [key in ModelCabinetWallT]: PositionAndRotationI;
};
/**
 * Функція для визначення позицій моделей.
 *
 * @param {NODES_THREEKIT} regexpModel Регулятний вираз типу "Model_Cabinets_Base_" по якому шукаємо нулі моделей.
 * @returns {ObjPositionCabinetsWallT} Об'ект з інформацією про позиції моделей.
 */
export const getPositionAndRotationForAllCabinets = (regexpModel: NODES_THREEKIT): ObjPositionCabinetsWallT => {
  const allNullForСabinets =
    getСompletedModelsNullNames(regexpModel);
  return allNullForСabinets.reduce(
    (objExtremePointsAcc: ObjPositionCabinetsWallT, modelName) => {
      const modelPos = getModelPositionFromName(modelName);
      const modelRotate = getRotationThreekit({name: modelName});
      return {
        ...objExtremePointsAcc,
        [modelName]: {
          translation: modelPos,
          rotation: modelRotate,
        }
      };
    },
    {}
  );
}

/**
 * Функція встановлює всі моделі в позиції, які прийшли в параметрі.
 *
 * @param {ObjPositionCabinetsWallT} objTransformNullModels Об'єкт нових позицій для моделей.
 */
export const setPositionForAllCabinets = (objTransformNullModels: ObjPositionCabinetsWallT) => {
  console.log('objTransformNullModels --- ==== ',objTransformNullModels);
  const arrNamesNullModel = Object.keys(
    objTransformNullModels
  ) as Array<keyof typeof objTransformNullModels>;
  arrNamesNullModel.forEach((nameNullModel) => {
    const positionCoords = objTransformNullModels[nameNullModel]["translation"];
    const ratationCoords = objTransformNullModels[nameNullModel]["rotation"];
    setTranslationThreekit({
      name: nameNullModel,
      value: positionCoords,
    });
    setRotationThreekit({
      name: nameNullModel,
      value: ratationCoords,
    });
  })
}