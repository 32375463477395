import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { GlobalHistoryI, HistoryInterfaceI } from './historySliceT'
import { isEqual, pickBy } from "lodash";

const initialState: GlobalHistoryI = {
  history2D: [],
  history3D: [],
}

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setHistory2D: (state, action: PayloadAction<HistoryInterfaceI>) => {

      const currentState = current(state);
      const latestHistory2D = currentState["history2D"][state["history2D"].length - 1];

      if (latestHistory2D !== undefined) {
        const latestConfiguration2D = latestHistory2D["configuration"];

        const lengthWallsPayload = action["payload"]["configuration"]["Walls"].length;
        const lengthWallsLatest = latestConfiguration2D["Walls"].length;

        const lengthWindowsPayload = action["payload"]["configuration"]["Windows"].length;
        const lengthWindowsLatest = latestConfiguration2D["Windows"].length;

        const lengthDoorsPayload = action["payload"]["configuration"]["Doors"].length;
        const lengthDoorsLatest = latestConfiguration2D["Doors"].length;

        const lengthOpeningsPayload = action["payload"]["configuration"]["Openings"].length;
        const lengthOpeningsLatest = latestConfiguration2D["Openings"].length;

        // if (isEqual(action["payload"]["configuration"], latestConfiguration2D)) return;
        if (
          lengthWallsPayload === lengthWallsLatest &&
          lengthWindowsPayload === lengthWindowsLatest &&
          lengthDoorsPayload === lengthDoorsLatest &&
          lengthOpeningsPayload === lengthOpeningsLatest
        ) return;
      }
      
      state["history2D"].push(action["payload"]);
    },
    undoHistory: (state, action: PayloadAction<any>) => {
      console.log('action --- ==== ',action);
     
      const currentState = current(state);
      const prevState = currentState["history2D"][state["history2D"].length - 2];
      console.log('prevState --- ==== ',prevState);
      //@ts-ignore
      window.threekit.player.player.configurator.setFullConfiguration(prevState);
      state["history2D"].pop();
    },
  },
})

export const { setHistory2D, undoHistory } = historySlice.actions;

export default historySlice.reducer;