import { Vector3 } from "three";
import * as THREE from "three";
import {
  FEATURES_INNER_NODES_THREEKIT,
  FeaturesName_NodesT,
} from "../../utils/constants/nodesNamesThreekit";
import {
  getInstanceIdAssetFromNullModel,
  getTranslationThreekit,
} from "../../utils/threekit/general/getFunctions";
import { checkFeatureWindow } from "./general";
import { CABINETS_BASE_HEIGHT_FROM_COUTERTOP } from "../cabinets/constatns";

export const getFeaturesPosition = (
  nullNameFeature: FeaturesName_NodesT
): Vector3 => {
  const instanceIdAssetFeature = getInstanceIdAssetFromNullModel({
    name: nullNameFeature,
  });
  if (instanceIdAssetFeature === undefined) {
    console.error("getFeaturesPosition error from instanceIdAssetFeature");
    return new THREE.Vector3(0, 0, 0);
  }
  return getTranslationThreekit({
    from: instanceIdAssetFeature,
    name: FEATURES_INNER_NODES_THREEKIT.CONTAINER_POS_NULL,
  }) as Vector3;
};

export const checkWindowPositionForCabinetsBase = (
  nullNameFeature: FeaturesName_NodesT
): boolean => {
  const isFeatureWindow = checkFeatureWindow(nullNameFeature);
  if (!isFeatureWindow) return false;
  const positionWindow = getFeaturesPosition(nullNameFeature);
  return positionWindow["y"] <= CABINETS_BASE_HEIGHT_FROM_COUTERTOP;
};
