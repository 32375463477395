export const BasketCabinetSvg = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18 19"
    >
      <path
        d="M5.6,1.8l6.8,0 M12.4,1.8l0,1.6l1.6,0l0-1.8c0-0.8-0.6-1.4-1.4-1.4l-7.1,0C4.7,0.2,4,0.8,4,1.6l0,1.8l1.6,0l0-1.6 M16.9,3.4
	l-15.7,0c-0.4,0-0.7,0.3-0.7,0.7l0,0.7C0.5,4.9,0.6,5,0.7,5L2,5l0.6,11.7C2.6,17.4,3.3,18,4,18l10.1,0c0.8,0,1.4-0.6,1.4-1.4L16.1,5
	l1.3,0c0.1,0,0.2-0.1,0.2-0.2l0-0.7C17.6,3.7,17.3,3.4,16.9,3.4z M14,16.4l-9.8,0L3.6,5l10.9,0L14,16.4z"
      />
    </svg>
  );
};
