export const selectClearThreekit = () => {
  // @ts-ignore
  window.threekit.player.selectionSet.clear();
};
export const selectAddThreekit = (id: string) => {
  // @ts-ignore
  window.threekit.player.selectionSet.add(id);
};
export const selectSetThreekit = (id: string) => {
  // @ts-ignore
  window.threekit.player.selectionSet.set(id);
};