import { Store } from 'antd/es/form/interface';
import { ActionTypes } from 'redux-undo';
import { getHistoryLatest2D } from '../selectors/historySelectors';
import { setConfigurationFullAll } from '../../utils/threekit/general/setFunctions';

export const historyMiddleware = (store: Store) => (next: any) => (action: any) => {

  switch (action.type) {

    case ActionTypes.UNDO: {
      // const state = store.getState();
      // console.log('UNDO action', action);
      // console.log('UNDO state', state);
      break;
    }

  }

  let result = next(action);

  switch (action.type) {

    case ActionTypes.UNDO: {
      const state = store.getState();

      const historyLastPresent = getHistoryLatest2D(state);
      if (historyLastPresent !== undefined) {
        setConfigurationFullAll(historyLastPresent["configuration"]);
      }

      break;

    }

  }

  return result;

};