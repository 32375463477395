export const DimentionsSvg = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.798 6.79802C24.5489 7.5489 24.5489 8.76633 23.798 9.51721L8.84241 24.4728C8.09153 25.2237 6.8741 25.2237 6.12322 24.4728L0.684826 19.0344C-0.0660596 18.2835 -0.0660598 17.0661 0.684826 16.3152L15.6404 1.35963C16.3913 0.608743 17.6087 0.608743 18.3596 1.35963L23.798 6.79802ZM2.04442 17.6748L7.48281 23.1132L22.4384 8.15762L17 2.71923L15.6404 4.07882L18.3596 6.79802C18.735 7.17346 18.735 7.78218 18.3596 8.15762C17.9842 8.53306 17.3754 8.53306 17 8.15762L14.2808 5.43842L12.9212 6.79802L14.2808 8.15762C14.6562 8.53306 14.6562 9.14177 14.2808 9.51722C13.9054 9.89266 13.2966 9.89266 12.9212 9.51722L11.5616 8.15762L10.202 9.51722L12.9212 12.2364C13.2966 12.6119 13.2966 13.2206 12.9212 13.596C12.5458 13.9715 11.937 13.9715 11.5616 13.596L8.84241 10.8768L7.48281 12.2364L8.84241 13.596C9.21785 13.9715 9.21786 14.5802 8.84241 14.9556C8.46697 15.331 7.85826 15.331 7.48282 14.9556L6.12322 13.596L4.76362 14.9556L7.48281 17.6748C7.85826 18.0502 7.85826 18.659 7.48281 19.0344C7.10737 19.4098 6.49866 19.4098 6.12322 19.0344L3.40402 16.3152L2.04442 17.6748Z"
        fill="#31344B"
      />
    </svg>
  );
};
