import React, { useRef, useEffect } from "react";
import { CloseModalSvg } from "../../assets/images/svg/CloseModal";
import { ImgOptions } from "../StandartComponents/ImgOptions/ImgOptions";
import s from "./SubMenu2DWrap.module.scss";

export const SubMenu2DWrap = ({ ...props }) => {
  const { children, onCloseMenu2D,style } = props;

  const handleCloseSubMenu2D = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    onCloseMenu2D();
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onCloseMenu2D();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onCloseMenu2D]);

  return (
    <div className={s.subMenu2DWrap} style={style} ref={wrapperRef}>
      <div className={s.close} onClick={handleCloseSubMenu2D}>
        <CloseModalSvg />
      </div>
      {children}
      {/* <ImgOptions /> */}
    </div>
  );
};
