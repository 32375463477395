import { NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getNodeIdFromName } from "../../../utils/threekit/general/getFunctions";
import { WALL_PLANE_BOTTOM_POSITION } from "../../cabinets/constatns";

export const addPlaneForCabinetsWall = (objParams: any) => {

  const {
    index,
    wallHeight,
    wallWidthOriginal,
    wallWidthFromDepth,
    wallDepth,
    coordsPosition,
    wallAngle,
  } = objParams;

  const planeCabinetsWallName = `${NODES_THREEKIT.PLANE_CABINETS_WALL}${index}`;
  const wallsLayoutContainerId = getNodeIdFromName(
    NODES_THREEKIT.LAYOUT_CONTAINER_WALLS_WRAP
  );

  const newNodeParamsPlaneCabinetsWall = {
    name: planeCabinetsWallName,
    type: "PolyMesh",
    plugs: {
      PolyMesh: [
        {
          type: "Plane",
          height: wallHeight - WALL_PLANE_BOTTOM_POSITION,
          // width: wallWidthOriginal - (wallWidthFromDepth - wallWidthOriginal) - 0.0005/**2*/, // 0.0005 - відступ від стіни в півміліметра, щоб тумби не стояли в стіні
          width: wallWidthOriginal/* - 0.0005*//**2*/, // 0.0005 - відступ від стіни в півміліметра, щоб тумби не стояли в стіні
        },
      ],
      Transform: [
        {
          type: "Transform",
          translation: {
            ...coordsPosition,
            y: wallHeight / 2 + WALL_PLANE_BOTTOM_POSITION / 2,
          },
          rotation: { x: 90, y: wallAngle, z: 0 },
        },
      ],
      Properties: [
        {
          type: "PolyMeshProperties",
          // receiveShadow: false,
          // castShadow: false,
          visible: false,
        },
      ],
    },
  };

  //@ts-ignore
  return window.threekit.player.scene.addNode(
    newNodeParamsPlaneCabinetsWall,
    wallsLayoutContainerId
  );

}