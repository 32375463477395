import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/useStoreHooks";
import { getStatusDependencySettings } from "../../store/selectors/settingsUISelectors";
import { RadioOptions } from "../StandartComponents/RadioOptions/RadioOptions";
import { SwitchSpaceBetween } from "../StandartComponents/SwitchSpaceBetween/SwitchSpaceBetween";

// scss
import s from "./CabinetsBuildGroup.module.scss";
import { setModalVisible } from "../../store/slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../../store/slices/modalsSlice/modalsSliceT";
import { InfoCircleSvg } from "../../assets/images/svg/Info";

export const CabinetsBuildGroup = ({ ...props }) => {
  const { children, id, label, dependencySettings } = props;

  const dispatch = useAppDispatch();

  const isVisibleGroup = useAppSelector(getStatusDependencySettings(dependencySettings));

  const handleClickInfo = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.DECORATIVE_END, value: true}))
  }

  if (!isVisibleGroup) return <></>;

  return (
    <div className={`${s.styleGroup}`}>
      {label && <p className={s.styleGroupTitle}>{label} {id.includes("DecorativeEnd") && <span onClick={handleClickInfo}><InfoCircleSvg /></span>}</p>}
      {children}
    </div>
  );
};
