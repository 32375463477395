export const CloseModalSvg = () => {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.8095 5.99951L9.91106 1.11045C9.97981 1.0292 9.922 0.905762 9.81575 0.905762H8.56888C8.49544 0.905762 8.42512 0.938574 8.37669 0.994824L4.99388 5.02764L1.61106 0.994824C1.56419 0.938574 1.49388 0.905762 1.41888 0.905762H0.172C0.0657503 0.905762 0.00793765 1.0292 0.0766877 1.11045L4.17825 5.99951L0.0766877 10.8886C0.061287 10.9067 0.0514069 10.9288 0.04822 10.9524C0.0450331 10.976 0.0486732 10.9999 0.0587085 11.0215C0.0687439 11.043 0.0847529 11.0613 0.104835 11.074C0.124916 11.0867 0.148227 11.0934 0.172 11.0933H1.41888C1.49231 11.0933 1.56263 11.0605 1.61106 11.0042L4.99388 6.97139L8.37669 11.0042C8.42356 11.0605 8.49388 11.0933 8.56888 11.0933H9.81575C9.922 11.0933 9.97981 10.9698 9.91106 10.8886L5.8095 5.99951Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
};
