import { ArrowBackMenuSvg } from "../../../assets/images/svg/BackMenu";

// scss
import s from "./Buttons.module.scss";

export const BtnBackMenu = ({ ...props }) => {
  const { text, onClick } = props;
  return (
    <button className={`${s.btn} ${s.btn_backMenu}`} onClick={() => onClick()}>
      <ArrowBackMenuSvg />
      {text}
    </button>
  );
};