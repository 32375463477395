import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { DataTablesI, KeysDataTablesT } from './dataTablesSliceT'

const initialState: DataTablesI = {
  priceTable: [],
}

export const dataTablesSlice = createSlice({
  name: 'dataTables',
  initialState,
  reducers: {
    setPriceTable: (state, action: PayloadAction<any>) => {
      const objDataTableRows = action.payload;
      const arrValuesPriceTable = objDataTableRows["rows"].map((rowDataTable: any) => {
        return { ...rowDataTable["value"] }
      })
      state["priceTable"] = arrValuesPriceTable;
    },
    // closeAllModals: (state) => {
    //   const allModalsKeys = Object.keys(state) as KeysModalsT[];
    //   allModalsKeys.forEach((keyModal: KeysModalsT) => {
    //     state[keyModal] = false;
    //   });
    // },
  },
})

export const { setPriceTable } = dataTablesSlice.actions;

export default dataTablesSlice.reducer;