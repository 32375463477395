export const DoorOpeningRight = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.15625 1.12198C0.15625 0.674511 0.529346 0.311768 0.989583 0.311768L19.3229 0.311768C19.7832 0.311768 20.1562 0.674511 20.1562 1.12198C20.1563 1.50756 19.8792 1.83024 19.5081 1.91211L10.9078 19.8437C10.773 20.1247 10.4935 20.2953 10.1957 20.3105C10.1775 20.3117 10.1592 20.312 10.1409 20.3116C10.0266 20.3101 9.91074 20.2856 9.80039 20.2355C9.71707 20.1978 9.6432 20.1483 9.57986 20.0901C9.26799 19.9243 8.96711 19.756 8.67682 19.5852C8.3793 19.4102 8.27999 19.0272 8.45501 18.7297C8.63002 18.4321 9.01309 18.3328 9.31061 18.5078C9.41323 18.5682 9.51737 18.6283 9.62306 18.6882L17.6595 1.93219H2.22772C2.22791 1.93858 2.22801 1.94499 2.22801 1.95143C2.22803 2.28401 2.2267 2.61725 2.22538 2.94897L2.22412 3.27637C2.22289 3.62154 1.94208 3.90037 1.5969 3.89914C1.25173 3.89791 0.9729 3.6171 0.974125 3.27193L0.975394 2.94202C0.976716 2.61097 0.978032 2.28129 0.978011 1.95151C0.978011 1.94502 0.978109 1.93855 0.978304 1.93211C0.523266 1.92624 0.15625 1.56578 0.15625 1.12198Z"
        fill="#332E20"
      />
      <path
        d="M2.24944 5.8989C2.23813 5.55391 1.94929 5.2834 1.6043 5.29471C1.25931 5.30602 0.9888 5.59485 1.00011 5.93984C1.0296 6.83967 1.08909 7.74163 1.20659 8.63715C1.2515 8.9794 1.56535 9.22043 1.90759 9.17553C2.24983 9.13062 2.49088 8.81678 2.44597 8.47454C2.33533 7.63134 2.27806 6.77218 2.24944 5.8989Z"
        fill="#332E20"
      />
      <path
        d="M2.96557 10.9623C2.86622 10.6317 2.51771 10.4443 2.18713 10.5436C1.85656 10.643 1.66912 10.9915 1.76846 11.3221C2.02931 12.19 2.37503 13.0451 2.83281 13.8802C2.99874 14.1829 3.37862 14.2937 3.6813 14.1278C3.98398 13.9619 4.09484 13.582 3.92892 13.2793C3.51702 12.528 3.20371 11.7547 2.96557 10.9623Z"
        fill="#332E20"
      />
      <path
        d="M5.36576 15.3316C5.13894 15.0715 4.74414 15.0444 4.48395 15.2712C4.22376 15.498 4.1967 15.8928 4.42352 16.153C4.99406 16.8075 5.65556 17.4401 6.42138 18.0483C6.69168 18.263 7.08483 18.2179 7.29951 17.9476C7.51418 17.6773 7.46909 17.2842 7.19879 17.0695C6.48935 16.5061 5.88362 15.9257 5.36576 15.3316Z"
        fill="#332E20"
      />
    </svg>
  );
};
