import { TYPE_REDUCER } from "../../utils/constants/actionTypes";
import { ArrUpdateSettingsT } from "../slices/settingsUI/typesSettingsUISlice";

export const updateSetingsUIAndThreekit = (payload: ArrUpdateSettingsT) => ({
  type: TYPE_REDUCER.UPDATE_SETTINGS_UI_AND_THREEKIT,
  payload,
});

export const addModels = (payload: ArrUpdateSettingsT) => ({
  type: TYPE_REDUCER.ADD_MODELS,
  payload,
});

export const removeModels = (payload: string[]) => ({
  type: TYPE_REDUCER.REMOVE_MODELS,
  payload,
});

export const removeAllModels = () => ({
  type: TYPE_REDUCER.REMOVE_ALL_MODELS,
});