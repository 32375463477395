import { SavedConfiguration } from "../../api/apiThreekit/savedConfiguration";
import { THREEKIT_ORG_ID, THREEKIT_PUBLIC_TOKEN, THREEKIT_URL } from "../../utils/threekit/threekitConfig/threekitConfig";

const PUBLIC_TOKEN = THREEKIT_PUBLIC_TOKEN;
const ORG_ID = THREEKIT_ORG_ID;

export const getShortId = async ({
  objActiveParamsUI,
  transformAllCabinets,
}: any) => {
  if (!!!PUBLIC_TOKEN || !!!ORG_ID || !!!THREEKIT_URL) return;
  let configThreekit = {
    authToken: PUBLIC_TOKEN,
    orgId: ORG_ID,
    threekitUrl: THREEKIT_URL,
    metadata: {
      objectActiveParams: objActiveParamsUI,
      transformAllCabinets,
      // allError: allError,
      // objectsPosition: objectsPosition,
      // positionObjectSide: await getObjectLocations()
    },
  };

  return (
    Promise.all([SavedConfiguration(configThreekit)])
      .then((threekitConfig: any) => {
        const shortId = threekitConfig[0]["shortId"];
        return Promise.all([shortId]);
      })
      // .then((threekitConfig: any) => {
      //   const linkText = `${window.location.origin}${pathname}?shortId=${threekitConfig[0]["shortId"]}`;

      //   return Promise.all([linkText]);
      // })
      .catch((error) => {
        console.log("error --- ==== ", error);
      })
  );
};