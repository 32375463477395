import { IConfigurationArray } from "@threekit-tools/treble/dist/types";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { ATTRIBUTE_NAMES } from "../../utils/constants/attributesThreekitRoomBuilder";
import { ArrWallTemplateT } from "../../utils/constants/presetsRoom";
import {
  getAppliedConfigurationThreekitAttribute,
  getAttributeThreekit,
  getEvalNode,
} from "../../utils/threekit/general/getFunctions";

export const buildWalls2DFromTemplate = async (template: ArrWallTemplateT) => {
  const resultPromices: Promise<any>[] = [];

  const wallAttributeThreekit = getAttributeThreekit(
    ATTRIBUTES_NAMES_THREEKIT.WALLS
  );
  const configClone = await window.threekit.configurator.clone();
  const attrWallClone = configClone
    .getDisplayAttributes()
    .filter((att) => att["name"].includes(ATTRIBUTES_NAMES_THREEKIT.WALLS))[0];

  if (!attrWallClone) return Promise.all(resultPromices);
 
  //@ts-ignore
  const assetIdAttributeNodeClone = attrWallClone["values"][0]["assetId"];

  await configClone.setConfiguration({
    [ATTRIBUTES_NAMES_THREEKIT.WALLS]: { assetId: assetIdAttributeNodeClone },
  });

  const valueWallNested = await configClone.getNestedConfigurator([
    ATTRIBUTES_NAMES_THREEKIT.WALLS,
    0,
  ]);
  if (!valueWallNested) return Promise.all(resultPromices);

  const attrWall = valueWallNested.getDisplayAttributes();

  const tkSpaceVisibility = attrWall.filter((att) =>
    att["name"].includes(ATTRIBUTE_NAMES.isVisible)
  )[0];

  if (!tkSpaceVisibility) return Promise.all(resultPromices);

  //@ts-ignore
  const assetIdAttributeNode = wallAttributeThreekit["values"][0]["assetId"];
  const newValueForWalls = template.map((wallTemplateConf, indx) => {
    //@ts-ignore
    const visibleValue = tkSpaceVisibility["values"].find((value) =>
      wallTemplateConf["_tkSpace_isVisibleWall"] === true
        ? value["name"] === "Visible"
        : value["name"] === "Invisible"
    );
    return {
      assetId: assetIdAttributeNode,
      configuration: {
        [ATTRIBUTE_NAMES.wallConnections]:
          wallTemplateConf["_tkSpace_connections"],
        [ATTRIBUTE_NAMES.wallStartX]: wallTemplateConf["_tkSpace_startX"],
        [ATTRIBUTE_NAMES.wallStartZ]: wallTemplateConf["_tkSpace_startZ"],
        [ATTRIBUTE_NAMES.wallEndX]: wallTemplateConf["_tkSpace_endX"],
        [ATTRIBUTE_NAMES.wallEndZ]: wallTemplateConf["_tkSpace_endZ"],
        [ATTRIBUTE_NAMES.wallHeight]: wallTemplateConf["_tkSpace_wallHeight"],
        [ATTRIBUTE_NAMES.wallHeightLocal]:
          wallTemplateConf["_tkSpace_wallHeightLocal"],
        [ATTRIBUTE_NAMES.wallLockHeight]:
          wallTemplateConf["_tkSpace_lockHeight"],
        [ATTRIBUTE_NAMES.wallThickness]:
          wallTemplateConf["_tkSpace_wallThickness"],
        [ATTRIBUTE_NAMES.wallThicknessLocal]:
          wallTemplateConf["_tkSpace_wallThicknessLocal"],
        [ATTRIBUTE_NAMES.wallLockThickness]:
          wallTemplateConf["_tkSpace_lockThickness"],
        [ATTRIBUTE_NAMES.isVisible]: visibleValue,
      },
    };
  });

  // @ts-ignore
  await window.threekit.configurator.setFullConfiguration({
    [ATTRIBUTES_NAMES_THREEKIT.WALLS]: newValueForWalls,
  });

  const appliedConfigurationWall = getAppliedConfigurationThreekitAttribute(
    ATTRIBUTES_NAMES_THREEKIT.WALLS
  );

  template.forEach((wallConf, indx) => {
    const evalNodeWall = getEvalNode({ id: appliedConfigurationWall[indx] });
    if (evalNodeWall) {
      const wallConfigurator = evalNodeWall["configurator"];
      // @ts-ignore
      const currentConfiguration = wallConfigurator["configuration"];
      const updatedConfiguration = {
        ...currentConfiguration,
        ...template[indx],
      };
      resultPromices.push(
        wallConfigurator.setFullConfiguration(updatedConfiguration)
      );
    }
  });
  return Promise.all(resultPromices);
};
