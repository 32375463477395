import { IConfigurationAttribute, ICoordinates } from "@threekit-tools/treble/dist/types";
import { ATTRIBUTE_NAMES } from "../../utils/constants/attributesThreekitRoomBuilder";
import {
  getInstanceIdAsset,
  getNodeIdFromName,
} from "../../utils/threekit/general/getFunctions";
import { getCrossVector, getDepthWallFromConfiguration } from "./buildWallFromData";
import {
  setThreekitOperatorProperties,
  setVisible,
} from "../../utils/threekit/general/setFunctions";
import { NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { getEndPointFromConfigurationWall, getIndexWallAdjacentAtEnd, getIndexWallAdjacentAtStart, getStartPointFromConfigurationWall } from "../configurator2D/wallsGeneral";
import * as THREE from "three";
import { getInvertedVector } from "../../utils/three/general/getFunctionsTHREE";
import { movePointInDirection } from "../../utils/three/movePointInDirection";

const INDENT_FLOOR_WITHIN_WALLS = 0.027;

const checkCoordEquality = (
  coordA: number,
  coordB: number,
  comparisonDistance: number
): boolean => {
  return (
    coordA <= coordB + comparisonDistance &&
    coordA >= coordB - comparisonDistance
  );
};

/**
 * Перевіряє, чи знаходиться точка(А) в радіусі(R) від точки(В).
 *
 * @param {Object} pointA Координати точки А (x, y, z).
 * @param {Object} pointB Координати точки B (x, y, z).
 * @param {Number} comparisonDistance Радіус в якому можуть знаходитися точки.
 * @return {Boolean} x, Точки однакові(знаходяться близько одна до одної) - true. Точки не однакові - false.
 */
export const checkPointEquality = (
  pointA: ICoordinates,
  pointB: ICoordinates,
  comparisonDistance: number
): boolean => {
  const isEqualityX = checkCoordEquality(
    pointA["x"],
    pointB["x"],
    comparisonDistance
  );
  const isEqualityY = checkCoordEquality(
    pointA["y"],
    pointB["y"],
    comparisonDistance
  );
  const isEqualityZ = checkCoordEquality(
    pointA["z"],
    pointB["z"],
    comparisonDistance
  );
  return isEqualityX && isEqualityY && isEqualityZ;
};

/**
 * Зміщує точку підлоги до зовнішнього краю стіни.
 *
 * @param {Array} arrPoints Массив точок, які потрібно зсунути.
 * @param {Number} offset Відстань, на яку потрібно зсунути точку, щоб заповнити простір під стіною до довнішнього краю стіни.
 * @return {Array} x, Массив точок, зміщених до зовнішніх границь стін.
 */
export const movePointsPosition = (
  arrPoints: ICoordinates[],
  offset: number
): ICoordinates[] => {
  // Відступ, для того щоб об'єкт на сцені, який рухаеться по підлозі, не міг проскочити наскрізь стіни.
  // Якщо NULL об'єкта знаходиться на краю самого об'єкта (не по ценру об'єкта / не всередині об'єкта)
  // const INDENT = 0.01;
  const INDENT = INDENT_FLOOR_WITHIN_WALLS;
  const updatedArrPoints = arrPoints.map((point: ICoordinates) => {
    return {
      x: point["x"] > 0 ? point["x"] + offset - INDENT : point["x"] - offset + INDENT,
      y: point["y"],
      z: point["z"] > 0 ? point["z"] + offset - INDENT : point["z"] - offset + INDENT,
    };
  });
  return updatedArrPoints;
};

type CoordsStartEndOutsideT = {
  startOutside: ICoordinates;
  endOutside: ICoordinates;
};
/**
 * Функція повертає координати крайніх точок стіни, зміщені до зовнішнього краю стіни.
 *
 * @param {any} wallObj Об'єкт стіни отриманий з конфігурації стіни Threekit.
 * @param {any} arrValueAttributeWalls Массив об'єктів для всіх стін, отриманий з атрибуту "Walls" Threekit.
 * @param {Number} indexCurrentWall Індекс обраної стіни в массиві об'єктів для всіх стін.
 * @return {CoordsStartEndOutsideT} Об'єкт з координатами крайніх точок стіни, зміщених до зовнішнього краю стіни.
 */
export const getOutsideExpremePointsForWall = (wallObj: any, arrValueAttributeWalls: any, indexCurrentWall: number): CoordsStartEndOutsideT => {
  // const INDENT = 0.028;
  const INDENT = INDENT_FLOOR_WITHIN_WALLS;
  const wallConfiguration = wallObj["configuration"];
  const depth = getDepthWallFromConfiguration(wallConfiguration);
  const startPointWall = getStartPointFromConfigurationWall(wallObj);
  const endPointWall = getEndPointFromConfigurationWall(wallObj);

  const indexWallAdjacentAtStart = getIndexWallAdjacentAtStart(
    arrValueAttributeWalls,
    wallObj,
    indexCurrentWall,
  );

  const indexWallAdjacentAtEnd = getIndexWallAdjacentAtEnd(
    arrValueAttributeWalls,
    wallObj,
    indexCurrentWall,
  );

  // const depthWallLeft = getDepthWallAdjacentAtStart(
  //   arrValueAttributeWalls,
  //   wallObj,
  //   indexCurrentWall,
  // )
  // const depthWallRight = getDepthWallAdjacentAtEnd(
  //   arrValueAttributeWalls,
  //   wallObj,
  //   indexCurrentWall,
  // )

  let startPoint = new THREE.Vector3(
    startPointWall["x"],
    0,
    startPointWall["y"]
  );
  let endPoint = new THREE.Vector3(
    endPointWall["x"],
    0,
    endPointWall["y"]
  )

  
  const directionFrontVector = getCrossVector(startPoint, endPoint);
  const directionBackVector = getInvertedVector(directionFrontVector);
  // const middleCoordsVector = getVector3FromCoordinates(getMiddleCoords(startPoint, endPoint));
  // const directionStartVector = startPoint.clone().sub(middleCoordsVector);
  // const directionEndVector = endPoint.clone().sub(middleCoordsVector);

  startPoint = movePointInDirection(
    startPoint,
    directionBackVector,
    depth/2 - INDENT
  );
  endPoint = movePointInDirection(
    endPoint,
    directionBackVector,
    depth/2 - INDENT
  );

  if (indexWallAdjacentAtStart !== -1) {
    const wallStartConfiguration = arrValueAttributeWalls[indexWallAdjacentAtStart]["configuration"];
    const depthWallStart = getDepthWallFromConfiguration(wallStartConfiguration);
    const startPointWallStart = getStartPointFromConfigurationWall(arrValueAttributeWalls[indexWallAdjacentAtStart]);
    const endPointWallStart = getEndPointFromConfigurationWall(arrValueAttributeWalls[indexWallAdjacentAtStart]);
    let startPointWallStartVector3 = new THREE.Vector3(
      startPointWallStart["x"],
      0,
      startPointWallStart["y"]
    );
    let endPointWallStartVector3 = new THREE.Vector3(
      endPointWallStart["x"],
      0,
      endPointWallStart["y"]
    )
    const directionFrontVectorWallStart = getCrossVector(startPointWallStartVector3, endPointWallStartVector3);
    const directionBackVectorWallStart = getInvertedVector(directionFrontVectorWallStart);

    startPoint = movePointInDirection(
      startPoint,
      directionBackVectorWallStart,
      depthWallStart/2 - INDENT
    );
    
  }


  if (indexWallAdjacentAtEnd !== -1) {
    const wallEndConfiguration = arrValueAttributeWalls[indexWallAdjacentAtEnd]["configuration"];
    const depthWallEnd = getDepthWallFromConfiguration(wallEndConfiguration);
    const startPointWallEnd = getStartPointFromConfigurationWall(arrValueAttributeWalls[indexWallAdjacentAtEnd]);
    const endPointWallEnd = getEndPointFromConfigurationWall(arrValueAttributeWalls[indexWallAdjacentAtEnd]);
    let startPointWallEndVector3 = new THREE.Vector3(
      startPointWallEnd["x"],
      0,
      startPointWallEnd["y"]
    );
    let endPointWallEndVector3 = new THREE.Vector3(
      endPointWallEnd["x"],
      0,
      endPointWallEnd["y"]
    )
    const directionFrontVectorWallEnd = getCrossVector(startPointWallEndVector3, endPointWallEndVector3);
    const directionBackVectorWallEnd = getInvertedVector(directionFrontVectorWallEnd);

    endPoint = movePointInDirection(
      endPoint,
      directionBackVectorWallEnd,
      depthWallEnd/2 - INDENT
    );
  }

  // startPoint = movePointInDirection(
  //   startPoint,
  //   directionStartVector,
  //   depthWallLeft/2 - INDENT
  // );
  // startPoint = movePointInDirection(
  //   startPoint,
  //   directionBackVector,
  //   depth/2 - INDENT
  // );

  // endPoint = movePointInDirection(
  //   endPoint,
  //   directionEndVector,
  //   depthWallRight/2 - INDENT
  // );
  // endPoint = movePointInDirection(
  //   endPoint,
  //   directionBackVector,
  //   depth/2 - INDENT
  // );

  return {
    startOutside: startPoint,
    endOutside: endPoint,
  }
}

/**
 * Вираховує точки, для побудови підлоги по площі кімнати.
 *
 * @param {IConfigurationAttribute} arrValueAttributeWalls Массив об'єктів, які описують стіни для кімнати.
 * @return {ICoordinates[]} x, Массив точок.
 */
const getWallConnectionPoints = (arrValueAttributeWalls: IConfigurationAttribute): ICoordinates[] => {
  if (!!!arrValueAttributeWalls || !Array.isArray(arrValueAttributeWalls)) return [];
  // формуємо массив всіх можливих точок на стінах для кімнати (start і end)
  // точки зміщені до зовнішнього краю стіни
  let arrAllPoints: ICoordinates[] = [];
  arrValueAttributeWalls.forEach((objWall, index) => {
    const { startOutside, endOutside } = getOutsideExpremePointsForWall(objWall, arrValueAttributeWalls, index);
    arrAllPoints = [...arrAllPoints, startOutside, endOutside];
  });

  // нам треба з массива всіх точок виділити тільки ті точки, які повторюються
  // тобто точки, в яких стіни з'эднуються
  //@ts-ignore
  const wallDepth = arrValueAttributeWalls[0]["configuration"][ATTRIBUTE_NAMES.wallThickness];
  const uniqSet = new Set();
  for (let i = 0; i < arrAllPoints.length; i++) {
    for (let j = 0; j < i; j++) {
      if (
        checkPointEquality(
          arrAllPoints[j],
          arrAllPoints[i],
          wallDepth / 2
        )
      ) {
        uniqSet.add(arrAllPoints[i]);
      }
    }
  }
  const arrUniqPoints = Array.from(uniqSet) as ICoordinates[];

  // сортуємо точки, щоб вони розташовувались по годинниковій стрілці
  arrUniqPoints.sort((coordsA: ICoordinates, coordsB: ICoordinates) => {
    const angleA = Math.atan2(coordsA["x"], -coordsA["z"]);
    const angleB = Math.atan2(coordsB["x"], -coordsB["z"]);
    return angleA - angleB;
  });

  // Зміщуємо позиції точок до зовнішніх границь стін
  // const arrOffsetUniqPoints = movePointsPosition(
  //   arrUniqPoints,
  //   wallDepth / 2
  // );

  // return arrOffsetUniqPoints;
  return arrUniqPoints;
};

/**
 * Розтягує полощу підлоги в Threekit моделі "Floor" по кімнаті з замкнутим контуром стін.
 *
 * @param {Array} arrValueAttributeWalls Массив об'єктів, які описують стіни для кімнати.
 */
export const buildFloorForRoom = async (arrValueAttributeWalls: IConfigurationAttribute) => {

  if (!!!arrValueAttributeWalls || !Array.isArray(arrValueAttributeWalls)) return;

  // id моделі з ассетом підлоги на головній сцені
  const idFloorModelInScene = getNodeIdFromName(NODES_THREEKIT.FLOOR_MODEL_IN_SCENE);

  // instanceId ассета підлоги
  const instanceIdFloor = await getInstanceIdAsset(idFloorModelInScene);
  if (instanceIdFloor === undefined) {
    const error = new Error("instanceIdFloor === undefined");
    console.error(error);
    return;
  }

  // отримуємо плейн "Floor Plane" для підлоги в ассеті підлоги
  const nodeFloorPlane = window.threekit.player.scene.get({
    from: instanceIdFloor,
    name: NODES_THREEKIT.FLOOR_PLANE,
  });

  // отримуємо список всіх точок floor_point_* з Thrrekit плейну "Floor Plane"
  const namesFloorPoints: string[] = [];
  //@ts-ignore
  nodeFloorPlane["plugs"]["PolyMesh"].forEach(
    (objectPolyMesh: any) => {
      if (objectPolyMesh["name"]?.includes("floor_point_"))
        namesFloorPoints.push(objectPolyMesh["name"]);
    }
  );

  const arrAnglePoints = getWallConnectionPoints(arrValueAttributeWalls);

  // розставляємо точки підлоги по унікальних/відсортованих/зміщених точках стін
  // перебираємо всі точки підлоги
  // якщо існує координа стіни по індексу, то ставимо точку підлоги в точку стіни
  // якщо точок на стінах вже немає, то всі точки підлоги що залишилися ставимо в початкову точку з массива arrUniqPoints
  namesFloorPoints.forEach((floorPointName: string, index: number) => {
    let wallCoords = arrAnglePoints[index];
    if (!!!wallCoords) {
      wallCoords = arrAnglePoints[0];
    }
    setThreekitOperatorProperties(
      instanceIdFloor,
      NODES_THREEKIT.FLOOR_PLANE,
      floorPointName,
      wallCoords
    );
  });

  // встановлюємо видимість для підлоги
  setVisible({
    from: instanceIdFloor,
    name: NODES_THREEKIT.FLOOR_PLANE,
    value: true,
  });
};
