import { getModalInfo } from "../../../store/selectors/modalsSelectors";
import { ModalsWrap } from "../ModalsWrap/ModalsWrap";
import s from "./Loader.module.scss";
import { useAppSelector } from "../../../hooks/useStoreHooks";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";

export const Loader = ({ ...props }) => {
  const modalInfo = useAppSelector((state) =>
    getModalInfo(state)(MODAL_KEYS.LOADER)
  );
  if (!modalInfo["isVisible"]) return null;

  return (
    <ModalsWrap modalKey={MODAL_KEYS.HELP_2D}>
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.loader}>
            <div className={s.rotater}>
              <div className={`${s.elem} ${s.elem_1}`}></div>
              <div className={`${s.elem} ${s.elem_2}`}></div>
              <div className={`${s.elem} ${s.elem_3}`}></div>
              <div className={`${s.elem} ${s.elem_4}`}></div>
            </div>
          </div>
          <div className={s.text}>This may take a few moments</div>
        </div>
      </div>
    </ModalsWrap>
  );
};
