import { CloseModalSvg } from "../../../assets/images/svg/CloseModal";
import { getModalInfo } from "../../../store/selectors/modalsSelectors";
import { ModalsWrap } from "../ModalsWrap/ModalsWrap";
import s from "./ModalDecorativeEnd.module.scss";
import DecorativeEnd from "./../../../assets/images/Modals/DecorativeEnd.png";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";

export const ModalDecorativeEnd = ({ ...props }) => {
  const modalInfo = useAppSelector((state) => getModalInfo(state)(MODAL_KEYS.DECORATIVE_END));
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.DECORATIVE_END, value: false}))
  }

  if (!modalInfo["isVisible"]) return null;

  return (
    <ModalsWrap modalKey={MODAL_KEYS.DECORATIVE_END}>
      <div className={s.modalDecorativeEnd}>
        <div className={s.close} onClick={handleCloseModal}><CloseModalSvg /></div>
        <h3 className={s.title}>Decorative End</h3>
        <p className={s.descr}>A decorative end is an applied panel, mimicking your door style, that is attached to the exposed end of a run of cabinetry.</p>
        <div className={s.imgWrap}>
          <img src={DecorativeEnd} alt="Decorative End" />
        </div>
      </div>
    </ModalsWrap>
  )
}