import * as THREE from "three";
import { AllName_NodesT, ModelsName_NodesT, NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { getСompletedEvalNodeModels } from "./getNodesCabinets";
import { getBoundingBoxEvalNode } from "../../utils/threekit/general/getFunctions";

export const checkIntersectsModelsBox = (fistModelBoundingBox: THREE.Box3, lastModelBoundingBox: THREE.Box3): boolean => {
  return fistModelBoundingBox.intersectsBox(lastModelBoundingBox)
}

export const checkIntersectModelFromModel = (nullNameModelFirst: AllName_NodesT, nullNameModelLast: AllName_NodesT): boolean => {
  const boundingBoxModelFirst = getBoundingBoxEvalNode(nullNameModelFirst);
  const boundingBoxModelLast = getBoundingBoxEvalNode(nullNameModelLast);
  return checkIntersectsModelsBox(boundingBoxModelFirst, boundingBoxModelLast);
}

export const checkIntersectModelFromModelsList = (nullNameModel: AllName_NodesT, modelsCabinetsRegExp: NODES_THREEKIT): boolean => {

  const boundingBoxCurrentModel = getBoundingBoxEvalNode(nullNameModel);
  const allEvalNodeCabinetsRegExp = getСompletedEvalNodeModels(modelsCabinetsRegExp);

  let isIntersectCurrentModelBox = false;

  allEvalNodeCabinetsRegExp.forEach((evalNode) => {
    if (evalNode["name"] !== nullNameModel && !isIntersectCurrentModelBox) {
      //@ts-ignore
      const evalNodeBoundingBox = evalNode.getBoundingBox();
      isIntersectCurrentModelBox = checkIntersectsModelsBox(boundingBoxCurrentModel, evalNodeBoundingBox);
    }
  })

  return isIntersectCurrentModelBox;

}

export const getIntersectModelsForModel = (nullNameModel: AllName_NodesT, modelsCabinetsRegExp: NODES_THREEKIT): ModelsName_NodesT[] => {

  const boundingBoxCurrentModel = getBoundingBoxEvalNode(nullNameModel);
  // console.log('boundingBoxCurrentModel --- ==== ',boundingBoxCurrentModel);
  const allEvalNodeCabinetsRegExp = getСompletedEvalNodeModels(modelsCabinetsRegExp);

  let arrIntersectModels: ModelsName_NodesT[] = [];

  allEvalNodeCabinetsRegExp.forEach((evalNode) => {
    const name = evalNode["name"] as ModelsName_NodesT;
    if (name !== nullNameModel) {
      //@ts-ignore
      const evalNodeBoundingBox = evalNode.getBoundingBox();
      const isIntersectCurrentModelBox = checkIntersectsModelsBox(boundingBoxCurrentModel, evalNodeBoundingBox);








      // const testInterSectBox = boundingBoxCurrentModel.intersect(evalNodeBoundingBox)
      // console.log('testInterSectBox --- ==== ',testInterSectBox);
      // //@ts-ignore
      // const intersectionSize = testInterSectBox.getSize()
      
      // console.log('intersectionSize --- ==== ',intersectionSize);
      // var intersectionAreaXZ = intersectionSize.x * intersectionSize.z; // площа перетину
      // var intersectionAreaXY = intersectionSize.x * intersectionSize.y; // площа перетину
      // var intersectionAreaZY = intersectionSize.y * intersectionSize.z; // площа перетину
      // var intersectionVolume = intersectionSize.x * intersectionSize.y * intersectionSize.z; // об'єм перетину
      // console.log('intersectionAreaXZ --- ==== ',intersectionAreaXZ);
      // console.log('intersectionAreaXY --- ==== ',intersectionAreaXY);
      // console.log('intersectionAreaZY --- ==== ',intersectionAreaZY);
      // console.log('intersectionVolume --- ==== ',intersectionVolume);








      

      if (isIntersectCurrentModelBox)
        arrIntersectModels.push(name)
    }
  })

  return arrIntersectModels;

}