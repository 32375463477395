import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useStoreHooks";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { ViewControl } from "../ViewControl/ViewControl";
// scss
import s from "./SceneControlsTop.module.scss";
import { deleteAllWallsFrom3D } from "../../../functionsConfigurator/wallsAndFloor/deleteWalls3D";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";

export const SceneControlsTop = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickHelp = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.HELP_3D, value: true, isFirstShow: false}))
  }

  const handleClickRoomLayout = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.CONTINUE_2D, value: true}))
    // deleteAllWallsFrom3D();
    // navigate('/build_room');
  }

  const handleClickSummary = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.PRICE, value: true}))
  }

  return (
    <div className={s.sceneControlsTop} onMouseUp={(e) => e.stopPropagation()}>
      <ButtonMain text="Help" onClick={handleClickHelp} color="white" size="big" />
      {/* <ViewControl /> */}
      <ButtonMain text="Edit Room Layout" onClick={handleClickRoomLayout} color="white" size="big" />
      <ButtonMain text="Shopping List" onClick={handleClickSummary} color="blueDark" size="big" />
    </div>
  );
};
