import { IConfiguration, IConfigurationAsset } from "@threekit-tools/treble/dist/types";
import {
  getInstanceIdFromProxyNode,
  getSceneInstanceId,
} from "../../utils/threekit/general/getFunctions";
import { getAllWallsNode } from "./buildWallFromData";
import { setMaterialThreekit } from "../../utils/threekit/general/setFunctions";

/**
 * Встановлює матеріал для динамічно сформованих стін, при зміні атрибуту на сцені.
 *
 * @param {Object} configuration Об'єкт актуальної конфігурації для сцени Threekit.
 * @param {Object} prevConfiguration Об'єкт попередньої конфігурації для сцени Threekit.
 */
export const updateWallsMaterial = (configuration: IConfiguration, prevConfiguration: IConfiguration) => {

  const materialConfiguration = configuration["Wall material"] as IConfigurationAsset;
  const materialId = materialConfiguration["assetId"];
  const assetIdMaterial = getInstanceIdFromProxyNode(materialId);
  const wallsList = getAllWallsNode();

  if (assetIdMaterial !== undefined) {
    Object.keys(wallsList).forEach((wallId: string) => {
      setMaterialThreekit({
        from: getSceneInstanceId(),
        id: wallId,
        value: {assetId: assetIdMaterial}
      });
    });
  }
  
}