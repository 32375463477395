import { setCabinetControls } from "../../../store/slices/settingsUI/settingsUISlice";
import { AppDispatch } from "../../../store/store";
import { NODES_THREEKIT } from "../../constants/nodesNamesThreekit";
import { getTranslationThreekit } from "../general/getFunctions";
import { TOOLS_WHALEN } from "./toolsGeneral";

let checkMouseDown = false;

export const selectObjectThreeKit = (dispatch: AppDispatch) => {
  return {
    active: true,
    enabled: true,
    key: TOOLS_WHALEN.SELECT_MODELS,
    handlers: {
      click: (ev: any) => {

        if (!ev) return false;
        if (!ev["hitNodes"] || ev["hitNodes"] < 1) return false;

        const nodes = ev.hitNodes[0].hierarchy;
        // console.log('nodes --- ==== ',nodes);

        const selectModel = nodes.find((node: any) => {
          return (
            node["name"].includes("Model_Cabinets_") ||
            node["name"].includes(NODES_THREEKIT.MODEL_APPLIANCES)
          );
        });

        //@ts-ignore
        const selectionModel = window.threekit.player.selectionSet.ids;
        // console.log('selectionModel --- ==== ',selectionModel);

        if (!!selectModel) {
          // for added annotation
          // addNewNodePoint("test name", modelPosition);
          dispatch(setCabinetControls({
            visible: true,
            selectedModelName: selectModel["name"],
          }));

          if (selectionModel.includes(selectModel["nodeId"])) {
            dispatch(setCabinetControls({
              visible: false,
              selectedModelName: '',
            }));
          }
        } else {
          dispatch(setCabinetControls({
            visible: false,
            selectedModelName: '',
          }));
        }

        return false;
      },
      mousedown: (ev: any) => {

        return false;

      },
      mouseup: (ev: any) => {


        if (checkMouseDown) {
          
        }

        checkMouseDown = false;
        
        return false;
      },
    },
  };
};
