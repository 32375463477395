import React, { useEffect, useLayoutEffect, useState } from "react";
import s from "./Accordion.module.scss";
import useMobileWidth from "../../hooks/useMobileWidth";
import { AccordionItem } from "./AccordionItem/AccordionItem";
import { AccordionHelp2DI, helpData2D } from "../../utils/constants/helpData2D";

export const Accordion = ({...props}) => {

  const {accordionData} = props;

  const [activeAccordion, setActiveAcordion] = useState<any>(helpData2D[0]["id"]);

  // START Show/Hide one accordions and one accordion height size
  const initVal: any[] = [];
  const elRefs = React.useRef(initVal);
  // const isMobileSize = useMobileWidth(991);
  // const handleShowHideAccordion = (idAccordion: any) => {
  //   if (activeAccordion !== idAccordion) {
  //     setActiveAcordion(idAccordion);
  //   } else {
  //     setActiveAcordion("");
  //   }
  // };
  // const setHeightActiveAccordion = (ref: any) => {
  //   if (ref) {
  //     const groupList = ref.current;

  //     let heightDisableGroups: number = 0;
  //     let activeGroup = groupList.filter(
  //       (item: any) => !!item && item.className.includes("active")
  //     );
  //     let noGroup = groupList.filter(
  //       (item: any) => !!item && !item.className.includes("active")
  //     );
  //     if (noGroup.length > 0 && activeGroup.length > 0) {
  //       const heightDisableGroup = noGroup[0].offsetHeight;
  //       heightDisableGroups = noGroup.length * heightDisableGroup;
  //       let parentNodeHeight = activeGroup[0].parentNode
  //         ? activeGroup[0].parentNode.offsetHeight
  //         : 0;
  //       groupList.forEach((section: any) => {
  //         if (!!section) {
  //           section.style.maxHeight = `${
  //             parentNodeHeight - heightDisableGroups
  //           }px`;
  //         }
  //       });
  //     }
  //   }
  // };
  // useLayoutEffect(() => {
  //   window.addEventListener("resize", () => {
  //     // if (!isMobileSize) {
  //       setHeightActiveAccordion(elRefs);
  //     // }
  //   });
  //   // if (!isMobileSize) {
  //     setHeightActiveAccordion(elRefs);
  //   // }
  // }, []);
  // END Show/Hide one accordions and one accordion height size

  return (
    <>
      <div className={s.accordionWrap}>
          {accordionData.map((setting: AccordionHelp2DI, index: number) => {
              // const isActive = activeAccordion === setting["id"];
              const activeClass = /*isActive ? s.active : */'';

              return (
                <React.Fragment key={setting["id"]}>
                    <div
                      ref={(el) => {
                        if (el !== null) {
                          elRefs.current[index] = el;
                        }
                      }}
                      className={`${s.acordionItemWrap} ${activeClass}`}
                    >
                      <AccordionItem
                        { ...setting }
                        // isActive={isActive}
                        // handleShowHideAccordion={(idAccordion: string) =>
                        //   handleShowHideAccordion(idAccordion)
                        // }
                      />
                    </div>
                </React.Fragment>
              );
            })}
        </div>
    </>
  );
};
