import * as THREE from "three";
import { IntervalWallI, getIntersectPointForWalls } from "./wallsGeneral";

/**
 * Функція визначає кут між стінами по годинниковій стрілці.
 *
 * @param {IntervalWallI} wall1 Координати початкової точки лінії
 * @param {IntervalWallI} wall2 Координати кінцевої точки лінії
 * @return {number} Кут в градусах
 */
export const calculateClockwiseAngle = (
  wall1: IntervalWallI,
  wall2: IntervalWallI
): number => {
  // Знаходимо точку, в якій перетинаються відрізки
  const intersectPointForWalls = getIntersectPointForWalls(wall1, wall2);

  if (intersectPointForWalls === undefined) return 0;

  const vector1 = new THREE.Vector2().subVectors(
    intersectPointForWalls,
    intersectPointForWalls.equals(wall1["start"])
      ? wall1["end"]
      : wall1["start"]
  );
  const vector2 = new THREE.Vector2().subVectors(
    intersectPointForWalls,
    intersectPointForWalls.equals(wall2["start"])
      ? wall2["end"]
      : wall2["start"]
  );

  // const vector1 = new THREE.Vector2().subVectors(wall1.end, wall1.start);
  // const vector2 = new THREE.Vector2().subVectors(wall2.end, wall2.start);

  const dot = vector1.x * vector2.x + vector1.y * vector2.y;
  const det = vector1.x * vector2.y - vector1.y * vector2.x;
  let angleRad = Math.atan2(det, dot);

  // Перетворюємо радіани в градуси
  let angleDeg = THREE.MathUtils.radToDeg(angleRad);

  // Виправляємо від'ємні значення кутів
  if (angleDeg < 0) {
    angleDeg += 360;
  }

  return angleDeg;
};
