import React, { useEffect, useRef } from "react";
import { initializationThreeKitDataT } from "../../utils/initializationProvider/initializationProvider";

//@ts-ignore
import s from "./PlayerThreeKit.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/useStoreHooks";
import {
  Player,
  useAttribute,
  useThreekitInitStatus,
} from "@threekit-tools/treble";
import { buildWallFromData, getAllWallsNode } from "../../functionsConfigurator/wallsAndFloor/buildWallFromData";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { runTrackingConfigurationChanges } from "../../utils/threekit/trackingConfigurationChanges";
import { addCustomTool } from "../../utils/threekit/tools/toolsGeneral";
import { selectObjectThreeKit } from "../../utils/threekit/tools/toolsSelectObjectInInRoom";
import { dragCabinetsBase } from "../../utils/threekit/tools/toolsDragCabinetsBase/toolsDragCabinetsBase";
import { dragCabinetsWall } from "../../utils/threekit/tools/toolsDragCabinetsWall/toolsDragCabinetsWall";
import { setInitialRoomCameraPosition } from "../../functionsConfigurator/camera/setInitialRoomCameraPosition";
import { dragCabinetsIsland } from "../../utils/threekit/tools/toolsDragCabinetsIsland/toolsDragCabinetsIsland";

// type PlayerThreeKitT = {
//   initializationThreekitData: initializationThreeKitDataT,
// }
export const PlayerThreeKit =
  (/*{ initializationThreekitData }: PlayerThreeKitT*/) => {
    // const hasLoadedThreekit = useThreekitInitStatus();
    // const [attributeWalls, setAttributeWalls] = useAttribute(
    //   ATTRIBUTES_NAMES_THREEKIT.WALLS
    // );
    // const [attributeWindows, setAttributeWindows] = useAttribute(
    //   ATTRIBUTES_NAMES_THREEKIT.WINDOWS
    // );
    // const [attributeDoors, setAttributeDoors] = useAttribute(
    //   ATTRIBUTES_NAMES_THREEKIT.DOORS
    // );
    // const [attributeOpenings, setAttributeOpenings] = useAttribute(
    //   ATTRIBUTES_NAMES_THREEKIT.OPENINGS
    // );
    // const dispatch = useAppDispatch();

    // const playerEl = useRef<HTMLDivElement>(null);
    // const dispatch = useAppDispatch();

    // useEffect(() => {
    //   console.log("PlayerThreeKit 3D MOUNT --- ==== ");
    //   if (hasLoadedThreekit) {
    //     // run initialization data
    //     console.log("RUN BUILD WALL --- ==== ");
    //     if (
    //       !!attributeWalls &&
    //       !!attributeWindows &&
    //       !!attributeDoors &&
    //       !!attributeOpenings &&
    //       Object.keys(getAllWallsNode()).length < 1
    //     ) {
    //       setTimeout(() => {
    //         buildWallFromData(
    //           attributeWalls["value"],
    //           attributeWindows["value"],
    //           attributeDoors["value"],
    //           attributeOpenings["value"]
    //         );
    //       }, 500)
    //     }
          
    //     runTrackingConfigurationChanges();
    //     addCustomTool(dragCabinetsBase(dispatch));
    //     addCustomTool(dragCabinetsIsland);
    //     addCustomTool(dragCabinetsWall(dispatch));
    //     addCustomTool(selectObjectThreeKit(dispatch));
    //   }
    // }, []);

    useEffect(() => {
      setInitialRoomCameraPosition()
    }, [])

    // useEffect(() => {

    // if (playerEl["current"] && !playerEl["current"]?.firstChild) {
    //   load3kit(null, initializationThreekitData['threekitUrl'], () => {
    //     init3kit({
    //       playerEl: playerEl["current"],
    //       initializationThreekitData,
    //       dispatch,
    //       roomData: initialWalls
    //     });
    //   });
    // }

    // load3kit(null, initializationThreekitData['threekitUrl'], () => {

    //   if (!!reconfigurationConfig) {
    //     getSavedConfiguration({
    //       configurationId: reconfigurationConfig.threeKitId,
    //       authToken: initializationThreekitData.authToken,
    //       threekitUrl: initializationThreekitData.threekitUrl
    //     }).then((res: any) => {

    //       init3kit(res.variant, res.metadata);
    //     })
    //   } else { init3kit(); }

    // });
    // });

    return (
      <div className={s.player_wrapper} id='player_wrapper'>
        {/* <div id="player" className={s.player} ref={playerEl} /> */}
        <Player></Player>
      </div>
    );
  };

export const MemoizedPlayerThreeKit = React.memo(PlayerThreeKit);
