export const InputNumberPlusSvg = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.5 0V16M16.5 8H0.5" stroke="black" strokeWidth="2" />
    </svg>
  );
};
