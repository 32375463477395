import { getAttributeThreekit } from "./general/getFunctions"
import { setConfiguration } from "./general/setFunctions";

export const revertBooleanAttributeThreekit = async (nameAttribute: string): Promise<boolean | undefined> => {
  const attribute = getAttributeThreekit(nameAttribute);

  if (attribute["type"] !== "Boolean") return undefined;

  const newValue = !attribute["value"];
  await setConfiguration(nameAttribute, newValue);

  return newValue;
}