import react, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { SwitchStandardBase } from "./../../../BaseComponent/SwitchStandardBase/SwitchStandardBase";
// scss
import s from "./SwitchSize.module.scss";
import { SwitchAntdSmallGreen } from "../../../BaseComponent/SwitchAntdSmallGreen/SwitchAntdSmallGreen";

type SwitchSizePropsT = {
  label: string;
  onChange: (value: string) => void;
  value: string;
  values: [
    {
      label: string;
      value: string;
    },
    {
      label: string;
      value: string;
    },
  ]
}

export const SwitchSize = ({...props}: SwitchSizePropsT) => {
  const { label, onChange, value, values } = props;
  const [ checkedValue, setCheckedValue ] = useState<boolean>(value === values[0]["value"] ? false : true)

  const handleChangeSwitch = (value: boolean) => {
    setCheckedValue(value);
  }

  useEffect(() => {
    if (checkedValue) {
      onChange(values[1]["value"]);
    } else {
      onChange(values[0]["value"]);
    }
  }, [checkedValue])

  return (
    <div className={s.switchSizeWrap}>
      <div className={s.switchSizeLabel}>{label}</div>
      <div className={s.switchSize}>
        <div className={s.switchSizeValueLeft}>{values[0]["label"]}</div>
        <SwitchAntdSmallGreen
          checked={checkedValue}
          onChange={(value: boolean) => handleChangeSwitch(value)}
        />
        <div className={s.switchSizeValueRight}>{values[1]["label"]}</div>
      </div>
    </div>
  );
};
