import { getPlaneNameFromWallName, getStartEndCoordsPlane } from "../wallsAndFloor/getWallPlanesInfo";
import { checkWallVisibility, getArrWallsNames } from "../wallsAndFloor/getGeneralWallInfo";
import { getNumberNodeThreekitFromName } from "../general";
import { NODES_DIMENSIONS } from "./nodesNamesDimensions";
import { setTranslationThreekit, setVisible } from "../../utils/threekit/general/setFunctions";
import { getBoxHeightThreekit } from "../../utils/threekit/general/getFunctions";

export const updateDimensionsWalls = async () => {
  const arrWallNames = getArrWallsNames();

  // @ts-ignore
  await window.threekit.player.evaluate();

  arrWallNames.forEach((wallName) => {

    const wallIndex = getNumberNodeThreekitFromName(wallName);
    const planeName = getPlaneNameFromWallName(wallName);
    const [planeCoordsLeft, planeCoordsRight] = getStartEndCoordsPlane(planeName);
    const wallLineName = `${NODES_DIMENSIONS.WALL_LINE}${wallIndex}`;
    const wallLineStartName = `${NODES_DIMENSIONS.WALL_LINE_START}${wallIndex}`;
    const wallLineEndName = `${NODES_DIMENSIONS.WALL_LINE_END}${wallIndex}`;

    setTranslationThreekit({
      name: wallLineStartName,
      value: {
        ...planeCoordsLeft,
        y: planeCoordsLeft["y"] + 0.2
      }
    });
    setTranslationThreekit({
      name: wallLineEndName,
      value: {
        ...planeCoordsRight,
        y: planeCoordsRight["y"] + 0.2
      }
    });

    const isWallNotBounding = checkWallVisibility(wallName);
    const wallHeight = getBoxHeightThreekit({name: wallName});

    if (isWallNotBounding && wallHeight > 1) {

      setVisible({
        name: wallLineName,
        value: true
      });

    } else {

      setVisible({
        name: wallLineName,
        value: false
      });

    }

    

  })

}