import { ICoordinates, IQuaternion } from "@threekit-tools/treble/dist/types";
import { getObjAttributeThreekitValues } from "../cabinets/getObjActiveAndNewValuesAttributeThreekit";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { getModelPositionFromName } from "../../utils/threekit/tools/toolsDragCabinetsIsland/generalFunc";
import {
  getConfigurationThreekitAttribute,
  getIdNodeThreekit,
  getNodeIdFromName,
  getTranslationThreekit,
} from "../../utils/threekit/general/getFunctions";
import {
  setActiveCamera,
  setConfiguration,
  setTranslationThreekit,
} from "../../utils/threekit/general/setFunctions";
import { generateSnapshot } from "../../api/apiThreekit/savedConfiguration";
import { getAllWallsNode } from "../wallsAndFloor/buildWallFromData";
import {
  getModelsBaseNullOnWall,
  getModelsWallNullOnWall,
} from "../cabinets/getNodesCabinets";
import {
  NODES_THREEKIT,
  WallItemT,
} from "../../utils/constants/nodesNamesThreekit";
import { getСompletedModelsNullNames } from "../cabinets/getNodesCabinets";
import { getInternalCornerCoordsForWalls } from "../cabinets/addCornerModelBase";

export const getPositionCamera = (): ICoordinates =>
  window["threekit"]["player"]["camera"].getPosition();
export const setPositionCamera = (camTrans: ICoordinates) =>
  window["threekit"]["player"]["camera"].setPosition(camTrans);
export const getPositionQuatCamera = (): IQuaternion =>
  window["threekit"]["player"]["camera"].getQuaternion();
export const setPositionQuatCamera = (camQuat: IQuaternion) =>
  window["threekit"]["player"]["camera"].setQuaternion(camQuat);

/**
 * Повертає координати моделі моделі від якої буде рухатися камера.
 */
export const getCornerPosition = () => {
  const { values, value } = getObjAttributeThreekitValues(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE
  );
  let translation = { x: 0, y: 0, z: 0 };

  const cornerEmpty = value.find((cabinet) =>
    values.find(
      (c) => c.assetId === cabinet.assetId && c.name === "Corner empty"
    )
  );

  if (cornerEmpty) {
    const indexCornerEmpty = value.findIndex(
      (v) => v.assetId === cornerEmpty.assetId
    );

    translation = getModelPositionFromName(
      `Model_Cabinets_Base_${indexCornerEmpty}`
    );

    console.log({ modelPositon: translation });

    return translation;
  }

  if (value.length > 0) {
    console.log({ modelPositon: translation, vi: value.length - 1 });

    let index = value.length - 1;

    translation = getModelPositionFromName(`Model_Cabinets_Base_${index}`);
  }

  return translation;
};

/**
 * Повертає ім'я стіни де найбільше моделів
 */
export const getWallForCamera = () => {
  const walls = getAllWallsNode();
  const arrayWalls: any[] = Object.values(walls);

  let modelCount = 0;
  let wallName = "wall_item_0";

  arrayWalls.forEach((wall) => {
    const modelsBase = getModelsBaseNullOnWall(wall.name);
    const modelsWall = getModelsWallNullOnWall(wall.name);

    const newModelsCount = modelsBase.length + modelsWall.length;

    if (newModelsCount > modelCount) {
      modelCount = newModelsCount;
      wallName = wall.name;
    }
  });

  return wallName;
};

interface WallsFromLenghtCabinetsResultI {
  name: WallItemT;
  length: number;
}
/**
 * Формує масив об'єктів для стін з кількістю моделей на кожній стіні
 *
 * @return {WallsFromLenghtCabinetsResultI[]} Snapshot Top
 */
export const getWallsFromLenghtCabinets = () => {
  const walls = getAllWallsNode();
  const arrayWallsObj = Object.values(walls);
  let objWallsCabinetsLengths: WallsFromLenghtCabinetsResultI[] = [];

  arrayWallsObj.forEach((wallObj: any) => {
    const wallName = wallObj["name"] as WallItemT;
    const modelsBase = getModelsBaseNullOnWall(wallName);
    const modelsWall = getModelsWallNullOnWall(wallName);

    const modelsCount = modelsBase.length + modelsWall.length;

    objWallsCabinetsLengths.push({
      name: wallName,
      length: modelsCount,
    });
  });

  return objWallsCabinetsLengths;
};

/**
 * Сортує стіни по зростанню кількості моделей на стіні
 *
 * @param {WallsFromLenghtCabinetsResultI[]} wallsFromLenght Масив об'єктів для стін з кількістю моделей на кожній стіні
 * @return {WallItemT[]} Масив імен відсортованих стін
 */
export const getSortedWallsByLenght = (
  wallsFromLenght: WallsFromLenghtCabinetsResultI[]
): WallItemT[] => {
  const sortedWalls = wallsFromLenght.sort((a, b) => b["length"] - a["length"]);
  return sortedWalls.map((item) => item["name"]);
};

/**
 * Шукає стіни, на яких є шкафи
 *
 * @param {WallsFromLenghtCabinetsResultI[]} wallsFromLenght Масив об'єктів для стін з кількістю моделей на кожній стіні
 * @return {WallItemT[]} Масив імен стін, на яких є шкафи
 */
export const getWallsFromCabinets = (
  wallsFromLenght: WallsFromLenghtCabinetsResultI[]
): WallItemT[] => {
  const filteredWalls = wallsFromLenght.filter((item) => item["length"] > 0);
  return filteredWalls.map((item) => item["name"]);
};

/**
 * Повертає id камер на сцені.
 * @return об'єкт з списком id для початкової, верхньої та бокової камери
 */
const getCameras = () => {
  const topNameCamera = "Snapshot Top Camera";
  const snapshotSideCamera = "Snapshot Side Camera";
  const defaultCamera = "Camera";

  const topNameCameraId = getNodeIdFromName(topNameCamera);
  const snapshotSideCameraId = getNodeIdFromName(snapshotSideCamera);
  const dafaultCameraId = getNodeIdFromName(defaultCamera);

  return {
    top: topNameCameraId,
    side: snapshotSideCameraId,
    defaultCamera: dafaultCameraId,
  };
};

/**
 *
 * @param modelPositon - поточна позиція камери
 * @return нова позиція камери
 */
export const rotateCamera = (modelPositon: {
  x: number;
  y: number;
  z: number;
}) => {
  if (modelPositon.x > 0 && modelPositon.z < 0) {
    window.threekit.player.scene.set(
      {
        name: "Snapshot Top Camera",
        //@ts-ignore
        plug: "Transform",
        //@ts-ignore
        property: "rotation",
      },
      { x: -50, y: -45, z: 0 }
    );
  }

  if (modelPositon.x < 0 && modelPositon.z < 0) {
    window.threekit.player.scene.set(
      {
        name: "Snapshot Top Camera",
        //@ts-ignore
        plug: "Transform",
        //@ts-ignore
        property: "rotation",
      },
      { x: -50, y: 45, z: 0 }
    );
  }
  if (modelPositon.x < 0 && modelPositon.z > 0) {
    window.threekit.player.scene.set(
      {
        name: "Snapshot Top Camera",
        //@ts-ignore
        plug: "Transform",
        //@ts-ignore
        property: "rotation",
      },
      { x: -50, y: -235, z: 0 }
    );
  }
  if (modelPositon.x > 0 && modelPositon.z > 0) {
    window.threekit.player.scene.set(
      {
        name: "Snapshot Top Camera",
        //@ts-ignore
        plug: "Transform",
        //@ts-ignore
        property: "rotation",
      },
      { x: -50, y: 235, z: 0 }
    );
  }
};

interface ParamsTranslationTopCameraI {
  idCamera: string;
  cornerCoordsTarget: ICoordinates;
  arrWallsFromCabinets: string[];
}
/**
 * Позиціонування верхньої камери на всі шкафи та на стіни, біля яких є шкафи
 *
 * @param {ParamsTranslationTopCameraI} params
 * @return {Promise} Snapshot Top
 */
const translationTopCamera = async ({
  idCamera,
  cornerCoordsTarget,
  arrWallsFromCabinets,
}: ParamsTranslationTopCameraI): Promise<string> => {
  await setActiveCamera(idCamera);

  const targetCameraIdsWalls = arrWallsFromCabinets.map((wallName: any) =>
    getIdNodeThreekit({ name: wallName })
  );
  const allCabinetsBase = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_BASE
  );
  const allCabinetsWall = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_WALL
  );
  const allCabinetsIsland = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_ISLAND
  );
  const idsAllCabinets = [
    ...allCabinetsBase,
    ...allCabinetsWall,
    ...allCabinetsIsland,
  ].map((cabinetName: any) => getIdNodeThreekit({ name: cabinetName }));

  // @ts-ignore
  await window.threekit.player.player.cameraController.frameBoundingSphere(
    [...targetCameraIdsWalls, ...idsAllCabinets],
    { ...cornerCoordsTarget, y: -3 }
  );
  // @ts-ignore
  await window.threekit.player.player.cameraController.controls.setDistanceToTarget(
    8
  );
  // window.threekit.player.camera.zoom(3)
  const { base64_small } = await generateSnapshot();

  return base64_small;
};

/**
 * Переміщення бокової камери на нову позицію
 */
const translationSideCamera = async ({ id }: any) => {
  await setActiveCamera(id);

  const wallName = getWallForCamera();
  const wallNode = window.threekit.player.scene.get({ name: wallName });

  let translationWall = getTranslationThreekit({ name: wallName });

  setTranslationThreekit({
    id,
    value: {
      ...translationWall,
      y: translationWall.y,
      z: translationWall.z,
    },
  });

  window.threekit.player.scene.set(
    //@ts-ignore
    { name: "Snapshot Side Camera", plug: "Camera", property: "targetNode" },
    wallNode.id
  );

  window.threekit.player.camera.frameBoundingSphere();

  const { base64_small } = await generateSnapshot();

  return base64_small;
};

export interface SnapshotsI {
  snapshotSide: string;
  snapshotTop: string;
}
export type SnapshotsResT = SnapshotsI | null;
/**
 * Початкова функція для переміщення усіх камер
 * @return об'єкт зі base64 снепшотами з камер
 */
export const getSnapshotsCabinetsConfiguration =
  async (): Promise<SnapshotsResT> => {
    const { top, defaultCamera, side } = getCameras();
    const defaultPosition = getPositionCamera();
    const defaultQuartation = getPositionQuatCamera();
    const isDimensions = getConfigurationThreekitAttribute(ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS);
    await setConfiguration(ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS, false);

    try {
      let snapshotSide: string = "";
      let snapshotTop: string = "";

      if (top) {
        const wallsFromLenghtCabinetsBase = getWallsFromLenghtCabinets();
        const sortedWallsByLenghtCabinetsBase = getSortedWallsByLenght(
          wallsFromLenghtCabinetsBase
        );
        const wallsFromCabinets = getWallsFromCabinets(
          wallsFromLenghtCabinetsBase
        );
        const cornerCoordsTarget = getInternalCornerCoordsForWalls([
          sortedWallsByLenghtCabinetsBase[0],
          sortedWallsByLenghtCabinetsBase[1],
        ]);
        const arrTargetWallsFromCabinets =
          wallsFromCabinets.length > 1
            ? wallsFromCabinets
            : [
                sortedWallsByLenghtCabinetsBase[0],
                sortedWallsByLenghtCabinetsBase[1],
              ];

        snapshotTop = await translationTopCamera({
          idCamera: top,
          cornerCoordsTarget,
          arrWallsFromCabinets: arrTargetWallsFromCabinets,
        });
      }

      if (side) {
        snapshotSide = await translationSideCamera({ id: side });
      }

      await setActiveCamera(defaultCamera);
      setPositionCamera(defaultPosition);
      setPositionQuatCamera(defaultQuartation);
      await setConfiguration(ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS, isDimensions);

      return {
        snapshotSide,
        snapshotTop,
      };
    } catch (error) {
      await setActiveCamera(defaultCamera);
      setPositionCamera(defaultPosition);
      setPositionQuatCamera(defaultQuartation);
      await setConfiguration(ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS, isDimensions);

      return null;
    }
  };
