import { IConfiguration, IThreekitConfigurator } from "@threekit-tools/treble/dist/types";
import { CabinetsAndFeatures_NodesT } from "../../constants/nodesNamesThreekit";
import { getNumberNodeThreekitFromName } from "../../../functionsConfigurator/general";

export interface NestedConfiguratorPathI {
  attributeName: string;
  indexAttributeValue: number;
}
export const getNestedConfigurator = (
  objPathGet: NestedConfiguratorPathI
): IThreekitConfigurator | undefined => {
  return window.threekit.configurator.getNestedConfigurator([
    objPathGet["attributeName"],
    objPathGet["indexAttributeValue"],
  ]);
};

export interface SetNestedConfiguratorI extends NestedConfiguratorPathI {
  configuration: IConfiguration;
}
export const setNestedConfiguration = async (
  objPathSet: SetNestedConfiguratorI,
) => {
  const { attributeName, indexAttributeValue, configuration } = objPathSet;
  const configurator = getNestedConfigurator({attributeName, indexAttributeValue});
  if (configurator !== undefined) {
    await configurator.setConfiguration(configuration);
  } else {
    const error = new Error(`Not Found configurator for path ["${attributeName}", ${indexAttributeValue}]`);
    console.error(error);
  }
};

export interface GetNestedConfiguratorFromNullNameI {
  nullName: CabinetsAndFeatures_NodesT;
  attributeName: string;
}
export const getNestedConfiguratorFromNullName = (
  objGetParams: GetNestedConfiguratorFromNullNameI
): IThreekitConfigurator | undefined => {
  const { nullName, attributeName } = objGetParams;
  const indexModel = getNumberNodeThreekitFromName(nullName);
  return window.threekit.configurator.getNestedConfigurator([
    attributeName,
    indexModel,
  ]);
};

export const getNestedConfigurationFromNullName = (
  objGetParams: GetNestedConfiguratorFromNullNameI
): IConfiguration | undefined => {
  const nestedConfigurator = getNestedConfiguratorFromNullName(objGetParams);
  return nestedConfigurator?.getConfiguration();
}

export interface SetNestedConfigurationFromNullNameI extends GetNestedConfiguratorFromNullNameI {
  configuration: IConfiguration;
}
export const setNestedConfigurationFromNullName = async (
  objSetParams: SetNestedConfigurationFromNullNameI
): Promise<any> => {
  const { nullName, attributeName, configuration } = objSetParams;
  const indexModel = getNumberNodeThreekitFromName(nullName);
  return setNestedConfiguration({
    attributeName,
    indexAttributeValue: indexModel,
    configuration,
  });
};