// scss
import s from "./Buttons.module.scss";

type ButtonColorT = "white" | "blueDark" | "redLight";
type ButtonSizeT = "small" | "middle" | "big" | "bigSuper";

interface ButtonMainI {
  text: string;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  fullWidth?: boolean;
  color: ButtonColorT;
  size: ButtonSizeT;
  bold?: boolean;
  disabled?: boolean;
  [key: string]: any;
}

export const ButtonMain = ({ ...props }: ButtonMainI) => {
  const { text, onClick, fullWidth, color, size, bold, disabled } = props;
  return (
    <button
      className={`
        ${s.btn}
        ${s[`btn_${color}`]}
        ${s[`btn_${size}`]}
        ${fullWidth && s.btn_fullWidth}
        ${bold && s.btn_bold}
        ${disabled && s.disabled}
      `}
      onClick={(e) => onClick(e)}
    >
      {text}
    </button>
  );
};
