import React, { useEffect, useState } from 'react'
//@ts-ignore
import s from './SelectBase.module.scss'


import { selectBaseT, getIconT } from './SelectBaseT'
import Select, { components } from "react-select";
import { activeOptionT } from '../../../store/slices/settingsUI/typesSettingsUISlice';


export const SelectBase = ({ ...props }) => {

    const { values, onChange, value, errorComponent, isDisabled = false } = props;


    let selectValue = values.find((option: activeOptionT) => option['value'] === value)

    let classInput = `${s.selectStandart}`

    const customStyles: any = {
        control: (provided: any, state: any) => {
            let menuIsOpen = state['menuIsOpen'];
            const isDisabled = state["isDisabled"];

            return ({
                ...provided,

                border: '1px solid #77777B',
                boxSizing: 'border-box',
                borderRadius: '2px',
                borderBottomLeftRadius: menuIsOpen ? '0' : '2px',
                borderBottomRightRadius: menuIsOpen ? '0' : '2px',
                boxShadow: '0 0 1px #DEDEDE ',
                borderColor: errorComponent && '#DE5555 !important',
                padding: "0px 16px",
                minHeight: "auto",
                backgroundColor: 'hsl(0, 0%, 100%) !important',
                opacity: isDisabled ? ".5" : "1",
                "&:hover": {
                    borderColor: "#77777B"
                },
                "@media screen and (max-width: 991px)": {
                    paddingTop: "5px",
                    paddingBottom: "5px",
                }
            })
        },
        singleValue: (provided: any, state: any) => {
            return {
                ...provided,

                fontSize: '12px',
                lineHeight: '15px',
                color: '#000000',
                margin: "0",
            }
        },
        valueContainer: (provided: any, state: any) => {
            return {
                ...provided,

                padding: "0",
            }
        },
        input: (provided: any, state: any) => {
            return {
                ...provided,

                margin: "0",
                paddingBottom: "0",
                paddingTop: "0",
                fontSize: '12px',
                lineHeight: '15px',
                color: '#000000',
            }
        },
        dropdownIndicator: (provided: any, state: any) => {
            return {
                ...provided,

                padding: "0",
                color: "hsl(0, 0%, 40%)",
            }
        },
        option: (provided: any, { isSelected }: any) => {
            provided = {
                ...provided,

                lineHeight: '1.35',
                padding: '6px 16px',
                backgroundColor: isSelected ? '#e6f7ff' : '#fff',
                color: '#000000d9',
                // pointerEvents: isSelected ? 'none' : 'auto',
                transition: '0.3s',
                fontSize: '12px',
                fontWeight: isSelected ? 600 : 400,

                "&:hover": {
                    background: isSelected ? '#e6f7ff' : ' #f5f5f5 !important',
                    cursor: 'default'
                }
            }
            return { ...provided };
        },
        menu: (provided: any, state: any) => {
            return ({
                ...provided,

                marginBottom: '0px',
                marginTop: '0px',
                borderTopLeftRadius: '0',
                borderTopRightRadius: '0',
                borderTop: 'none',
                border: '1px solid #77777B',
                boxShadow: '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)'
            })
        },

    }


    const CustomOption: any = ({ children, ...props }: any): any => {

        let isDisable: boolean = false;
        if (props.data && props.data['state'] === "disabled") isDisable = true;

        return (
            <div style={isDisable ? { opacity: 0.5, pointerEvents: 'none' } : {}}>

                <components.Option   {...props}>
                    {children}
                </components.Option>
            </div>
        );
    };



    const [selectValueCurrent, setSelectValueCurrent] = useState(selectValue)

    useEffect(() => {
        let selectValue = values.find((option: activeOptionT) => option['value'] === value)
        setSelectValueCurrent(selectValue)
    }, [value])


    return (
        <Select
            className={classInput}
            placeholder="Select value"
            onChange={(prop: any) => {

                setSelectValueCurrent(prop);
                onChange(prop['value'])
            }}
            value={selectValueCurrent}
            // menuIsOpen={true}
            options={values}
            styles={customStyles}
            components={{
                IndicatorSeparator: () => null,
                Option: CustomOption
            }}
            isSearchable={false}
            isDisabled={isDisabled}
        />
    )
}


