import { combineReducers, configureStore } from '@reduxjs/toolkit';
import modalsSlice from './slices/modalsSlice/modalsSlice';
import settingsUISlice from "./slices/settingsUI/settingsUISlice";
import { threekitMiddleware } from './middlewares/player.middleware';
import dataTablesSlice from './slices/dataTablesSlice/dataTablesSlice';
import historySlice from './slices/history/historySlice';
import undoable from 'redux-undo';
import { historyMiddleware } from './middlewares/history.middleware';

const store = configureStore({
  reducer: {
    modals: modalsSlice,
    paramsConfiguration: settingsUISlice,
    dataTables: dataTablesSlice,
    history: undoable(historySlice),
  },
  middleware: [threekitMiddleware, historyMiddleware],
})

export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


// import { applyMiddleware, createStore } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import rootReducer from "./reducers";
// import thunk from "redux-thunk";
// import { middleware } from "./middleware";

// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, middleware)));

// export default store;

// export type Store = typeof store;
// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;