export const ArrowLeftSmallSvg = () => {
  return (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70726 0.964463C4.09778 0.573939 4.73095 0.573939 5.12147 0.964463C5.512 1.35499 5.512 1.98815 5.12147 2.37868L3.00018 4.49997L5.1215 6.62129C5.51202 7.01182 5.51202 7.64498 5.1215 8.03551C4.73097 8.42603 4.09781 8.42603 3.70728 8.03551L0.884966 5.21319C0.882914 5.21117 0.880869 5.20914 0.878831 5.2071C0.494409 4.82268 0.488402 4.20314 0.860811 3.81137C0.866722 3.80515 0.872729 3.79899 0.878831 3.79289C0.878839 3.79288 0.878848 3.79287 0.878857 3.79286C0.878926 3.79279 0.878995 3.79273 0.879064 3.79266L3.70726 0.964463Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
};
