export const CheckSvg = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.1875C3.65117 0.1875 0.125 3.90957 0.125 8.5C0.125 13.0904 3.65117 16.8125 8 16.8125C12.3488 16.8125 15.875 13.0904 15.875 8.5C15.875 3.90957 12.3488 0.1875 8 0.1875ZM11.4014 5.78545L7.69941 11.2034C7.64767 11.2796 7.57946 11.3418 7.50044 11.3846C7.42142 11.4275 7.33386 11.4498 7.24502 11.4498C7.15618 11.4498 7.06862 11.4275 6.9896 11.3846C6.91057 11.3418 6.84237 11.2796 6.79063 11.2034L4.59863 7.99717C4.53184 7.89883 4.59863 7.76152 4.71289 7.76152H5.5373C5.7166 7.76152 5.88711 7.85244 5.99258 8.0083L7.24414 9.8415L10.0074 5.79658C10.1129 5.64258 10.2816 5.5498 10.4627 5.5498H11.2871C11.4014 5.5498 11.4682 5.68711 11.4014 5.78545Z"
        fill="#1F2944"
      />
    </svg>
  );
};
