export const FilterSvg = () => {
  return (
    <svg
      width="34"
      height="30"
      viewBox="0 0 34 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.7902 0.620972H2.21074C1.22637 0.620972 0.611635 1.69374 1.10583 2.54954L10.4514 18.4362V28.1031C10.4514 28.8143 11.0219 29.3888 11.729 29.3888H22.2719C22.979 29.3888 23.5496 28.8143 23.5496 28.1031V18.4362L32.8991 2.54954C33.3893 1.69374 32.7746 0.620972 31.7902 0.620972ZM20.6728 26.496H13.3282V20.2281H20.6768V26.496H20.6728ZM21.0585 16.9897L20.6768 17.6567H13.3241L12.9424 16.9897L4.97503 3.51383H29.0259L21.0585 16.9897Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
};
