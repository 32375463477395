// scss
import { useAppSelector } from "../../../hooks/useStoreHooks";
import { getActiveSection } from "../../../store/selectors/settingsUISelectors";
import { IComponent } from "../../../types/UI/dynamic-rendering.interfaces";
import s from "./SceneInfoText.module.scss";

export const SceneInfoText = () => {
  const settings: IComponent[] = useAppSelector(getActiveSection);
  const classNames = `${s.sceneInfoText} ${settings && s.active}`
  return (
    <div className={classNames} onMouseUp={(e) => e.stopPropagation()}>
      <p>Countertops, Appliances, Sinks and Hardware are used for appearance purposes only. This Bill of Material is for Cabinets only.</p>
    </div>
  );
};
