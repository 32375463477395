import React, {useEffect, useState} from "react";
import { FilterSvg } from "../../assets/images/svg/Filter";
import useMobileWidth from "../../hooks/useMobileWidth";
import { ButtonMain } from "../StandartComponents/Buttons/ButtonMain";

// scss
import s from "./FiltersGroup.module.scss"

export const FiltersGroup = ({ ...props }) => {
  const { children, id } = props;
  // const isMobileSize = useMobileWidth(991);
  // console.log('id --- ==== ',id);
  // console.log('isMobileSize --- ==== ',isMobileSize);
  const [isVisibleMob, setVisibleMob] = useState<boolean>(false);

  // const handleVisibleFiltersMob = () => {
  //   setVisibleMob(!isVisibleMob)
  // }

  // const handleHideFiltersMob = () => {
  //   setVisibleMob(false)
  // }

  return (
    <div className={s.filtersWrap}>
      <div className={s.btnVisibleFilterMob} onClick={() => setVisibleMob(!isVisibleMob)}>
        <FilterSvg />
      </div>
      <div className={`${s.filtersContent} ${isVisibleMob && s.show}`}>
        { children }
        <div className={s.mobileBtns}>
          <ButtonMain text="Cancel" onClick={() => setVisibleMob(false)} color="white" size="middle" bold={true} />
          <ButtonMain text="Apply" onClick={() => setVisibleMob(false)} color="blueDark" size="middle" bold={true} />
        </div>
      </div>
    </div>
  );
};
