export const ArrowBackMenuSvg = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0357 8.84856H3.97548L11.7924 15.6343C11.9174 15.7437 11.8415 15.9468 11.6764 15.9468H9.70092C9.61387 15.9468 9.53128 15.9155 9.46655 15.8597L1.03128 8.54053C0.954022 8.47356 0.892062 8.39077 0.849598 8.29776C0.807133 8.20475 0.785156 8.10371 0.785156 8.00146C0.785156 7.89922 0.807133 7.79817 0.849598 7.70517C0.892062 7.61216 0.954022 7.52937 1.03128 7.4624L9.51565 0.0985613C9.54914 0.0695438 9.58931 0.0539188 9.63173 0.0539188H11.6741C11.8393 0.0539188 11.9152 0.259275 11.7902 0.366419L3.97548 7.15213H17.0357C17.134 7.15213 17.2143 7.23249 17.2143 7.3307V8.66999C17.2143 8.76821 17.134 8.84856 17.0357 8.84856Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
};
