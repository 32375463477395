import { IUnits } from "@threekit-tools/treble/dist/hooks/useSpaces/types";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getMeasurement } from "../../../store/selectors/settingsUISelectors";
import { setMeasurement } from "../../../store/slices/settingsUI/settingsUISlice";
import { RadioButtomsSmallValuesI } from "../../../utils/constants/settingsConfig2D";
import s from "./RadioButtonsSmall.module.scss";
import { useDispatch } from "react-redux";

export const RadioButtonsSmall = ({ ...props }) => {
  const { id, label, values, defaultValue, onChange } = props;

  const dispatch = useDispatch();
  const measurementValue = useAppSelector(getMeasurement);

  const handleChange = (value: IUnits) => {
    if (id === "MeasurementUnit") {
      if (onChange !== undefined) {
        onChange(value);
      }
      dispatch(setMeasurement(value));
    }
  };

  const getDefaultValue = () => {
    if (id === "MeasurementUnit") {
      return measurementValue;
    }
    return !!defaultValue ? defaultValue : values[0]["value"];
  };

  return (
    <div className={s.wrap}>
      {!!label && <p className={s.title}>{label}</p>}
      <div className={s.buttonsWrap}>
        {!!values &&
          values.map((value: RadioButtomsSmallValuesI) => {
            const isCheked = getDefaultValue() === value["value"];
            return (
              <React.Fragment key={value["id"]}>
                <input
                  type="radio"
                  defaultChecked={isCheked}
                  id={value["id"]}
                  name={id}
                  onChange={() => handleChange(value["value"])}
                />
                <label htmlFor={value["id"]}>{value["label"]}</label>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};