import React from 'react'
import { Switch } from "antd";
import styled from "styled-components";
 
type SwitchStandardT = {
    onChange: any
    checked?: boolean
}

let StyledSelect = styled(Switch)` 
    &.ant-switch{
        width: 28px;
        min-width: 28px;
        height: 16px;
        background: #BFBFBF !important;
    }
    &.ant-switch .ant-switch-handle {
        width: 12px;
        height: 12px;
    }
    &.ant-switch.ant-switch-checked .ant-switch-handle {
        inset-inline-start: calc(100% - 14px);
    }
    &.ant-switch-checked{
        background: #1BA17B !important;
    } 
    &.ant-switch:focus {
        box-shadow: none !important;
    } 
    &.ant-switch-checked:focus{
        box-shadow: none !important;
    }
     &.ant-switch .ant-click-animating-node{
        box-shadow: none !important;
        -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1)  !important;
    }
    @keyframes waveEffect{
        100% {box-shadow: 0 0 0 6px #425d76; !important; }
    }
`;

export const SwitchAntdSmallGreenStandart = ({ ...props }: SwitchStandardT) => { 
    const { onChange, checked } = props; 
    return (
        <StyledSelect
            checked={checked}
            onChange={(value: boolean) => onChange(value)}
        />
    )
}

