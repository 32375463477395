import React, { useEffect, useRef } from "react";

// scss
import s from "./RadioOptionsSmall.module.scss";

// images
import Rectangle from "./../../../assets/images/OtherParamsImages/FramedOpeningType/Rectangle.svg"
import Arch from "./../../../assets/images/OtherParamsImages/FramedOpeningType/Arch.svg"
import { useAttribute } from "@threekit-tools/treble/dist";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { ATTRIBUTE_NAMES } from "../../../utils/constants/attributesThreekitRoomBuilder";
import { IConfiguration } from "@threekit-tools/treble/dist/types";
import { IConfigurationAsset } from "@threekit-tools/treble/dist/types";
// const options = [
//   {
//     id: Math.floor(100000 + Math.random() * 900000),
//     value: '6729cf71-97fd-4d1d-afe8-6eefe76c3636',
//     name: "Squared",
//     image: Rectangle,
//   },
//   {
//     id: Math.floor(100000 + Math.random() * 900000),
//     value: 'cb239054-c0be-4b17-a79c-6ca483fd9c02',
//     name: "Arch",
//     image: Arch,
//   },
// ]

export const RadioOptionsSmall = ({...props}) => {
  const { label, path, attributeNameNode, openings, attribute } = props;
  // const [attributeOpenings, setAttributeOpenings] = useAttribute(
  //   ATTRIBUTES_NAMES_THREEKIT.OPENINGS
  // );

  // const handleClickOption = (value: string) => {
  //   // value === id item for type opening
  //   if (!!path) {
  //     switch (path[0]) {
  //       case "Openings":
  //         if (!!attributeOpenings && attributeNameNode === ATTRIBUTE_NAMES.featureOpeningsModel) {
  //           const newValueAttributeOpenings = JSON.parse(
  //             JSON.stringify(attributeOpenings["value"])
  //           ).map((attributeValue: any, indx: number) => {
  //             if (indx === path[1]) {
  //               return {
  //                 ...attributeValue,
  //                 configuration: {
  //                   ...attributeValue["configuration"],
  //                   [attributeNameNode]: {
  //                     // ...attributeValue["configuration"][attributeNameNode],
  //                     assetId: value,
  //                   },
  //                 },
  //               };
  //             }
  //             return attributeValue;
  //           });
  //           setAttributeOpenings(newValueAttributeOpenings)
  //         };
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }

  // const getActiveValueTypeOpening = () => {
  //   if (!!attributeOpenings && Array.isArray(attributeOpenings["value"])) {
  //     const configurationSelectedOpening = attributeOpenings["value"][path[1]]["configuration"] as IConfiguration;
  //     const attributeFeatureOpeningsModel = configurationSelectedOpening[ATTRIBUTE_NAMES.featureOpeningsModel] as IConfigurationAsset;
  //     if (!!attributeFeatureOpeningsModel) return attributeFeatureOpeningsModel["assetId"]
  //   };
  //   return '';
  // }

  // const interval = useRef<any>(null);
  // const getValuesTypeOpening = () => {
  //   if (!!attributeOpenings && Array.isArray(attributeOpenings["value"])) {
  //     const itemId = attributeOpenings["value"][path[1]]["assetId"];
  //     const node = window.threekit.player.scene.get({id: itemId})
  //     let evalNode = window.threekit.player.scene.get({id: itemId, evalNode: true})
  //     console.log('node --- ==== ',node);
  //     console.log('evalNode --- ==== ',evalNode);
  //     interval["current"] = setInterval(() => {
  //       evalNode = window.threekit.player.scene.get({id: itemId, evalNode: true});
  //       console.log('evalNode --- ==== ',evalNode);
  //       if (evalNode !== undefined) clearInterval(interval["current"]);
  //     }, 1000)
  //   };
  // }
  // getValuesTypeOpening()

  // useEffect(() => {
  //   return () => {
  //     clearInterval(interval["current"])
  //   }
  // }, []);

  return (
    <div className={s.radioOptionsSmallWrap}>
      {label && <p className={s.label}>{label}</p>}
      <div className={s.radioOptionsSmallList}>
        {/* {options.map((option: any) => (
          <div key={option["id"]} className={`${s.imgOption} ${getActiveValueTypeOpening() === option["value"] ? s.active : ""}`} onClick={() => handleClickOption(option["value"])}>
            <img src={option["image"]} alt="" />
          </div>
        ))} */}
        {attribute?.values.map((item: any, i: number) => {
          return (
            <div key={item["name"]} className={`${s.imgOption} ${item["selected"] ? s.active : ""}`} onClick={item.handleSelect}>
              <img src={item["name"] === "Squared" ? Rectangle : Arch} alt={item["name"]} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
