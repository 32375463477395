import { SelectedModelNameT } from "../../../store/slices/settingsUI/typesSettingsUISlice";

export const checkLockTranslationY = (nullName: SelectedModelNameT): boolean => {
  if (nullName.length === 0) return false;
  const lockTY = window.threekit.player.scene.get({
    name: nullName,
    //@ts-ignore
    plug: "Physics",
    properties: {
        name: "Rigid Body"
    },
    //@ts-ignore
    property: 'lockTY'
  });
  //@ts-ignore
  return lockTY;
}

export const changeLockTranslationY = (nullName: SelectedModelNameT, value: boolean) => {
  window.threekit.player.scene.set({
    name: nullName,
    //@ts-ignore
    plug: "Physics",
    properties: {
        name: "Rigid Body"
    },
    //@ts-ignore
    property: 'lockTY'
  }, value);
}